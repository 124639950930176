import { Icon, Tooltip } from "@mui/material";
import { GridActionsCellItem, GridColumns, GridRowParams } from "@mui/x-data-grid";
import { IUserGroup } from "../../../../../interfaces/user/models";
import { ICreateColumnsPros } from "./interfaces";

export const createColumns = ({ onEdit, onRemove }: ICreateColumnsPros): GridColumns<IUserGroup> => [
	{
		field: 'actions',
		type: 'actions',
		hideable: false,
		getActions: (params: GridRowParams<IUserGroup>) => [
			<GridActionsCellItem
				icon={<Tooltip title="Modifica"><Icon className="fa-light fa-pen" sx={{ overflow: "visible" }} /></Tooltip>}
				label="Edit"
				onClick={() => onEdit(params.id as number)}
			/>,
			<GridActionsCellItem
				icon={<Tooltip title="Elimina"><Icon className="fa-light fa-trash-can" sx={{ overflow: "visible" }} /></Tooltip>}
				label="Delete"
				onClick={() => onRemove(params.id as number)}
			/>

		],
	},
	{
		field: 'nome',
		headerName: 'Nome',
		width: 320
	},
	{
		field: 'descrizione',
		headerName: 'Descrizione',
		width: 320
	},
]