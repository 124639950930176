import { ThemeProvider } from '@emotion/react';
import { enableMapSet } from "immer";
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Router from './routes';
import { store } from './store';
import theme from './theme';
import './intl/i18n';


enableMapSet();

const container = document.getElementById('root') as Element
const root = createRoot(container);

root.render(
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<React.StrictMode>
				<Router />
			</React.StrictMode>
		</ThemeProvider>
	</Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
