export enum EProspectsViews {
	"all" = "all",
	"mine" = "mine",
	"none" = "none"
}

export enum EClientsViews {
	"all" = "all",
	"mine" = "mine",
	"none" = "none"
}
