import { createAction } from '@reduxjs/toolkit';
import { ICliente } from '../../interfaces/cliente/models';
import { EMPTY, GET, IClienteState, LIST } from './types';

export const listAction = createAction<IClienteState>(LIST);

export const getAction = createAction<ICliente>(GET);

export const emptyAction = createAction(EMPTY);

