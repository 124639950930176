import { Container } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import { useAppSelector } from "../store/hooks";
import App from "./app";
import Clienti from "./cliente";
import ExpiredTokenError from "./errors/expiredToken";
import Gruppi from "./gruppoUtente";
import PageLogin from "./login";
import Main from "./main";
import Materiali from "./materiali";
import Qualita from "./qualita";
import Venduto from "./venduto";
import Products from "./prodotto";
import Prospects from "./prospect";
import Utenti from "./utente";

function Router() {
  return (
    /**@TODO return to BrowserRouter when server htaccess was set */
    <HashRouter>
      <Routes>
        <Route element={<App />}>
          <Route
            path="*"
            element={
              <RequireAuth>
                <Routes>
                  <Route path="prodotti/*" element={<Products />} />
                  <Route path="venduto/*" element={<Venduto />} />
                  <Route path="materiali/*" element={<Materiali />} />
                  <Route path="qualita/*" element={<Qualita />} />
                  <Route path="prospects/*" element={<Prospects />} />
                  <Route path="clienti/*" element={<Clienti />} />
                  <Route path="utenti/*" element={<Utenti />} />
                  <Route path="gruppi-utente/*" element={<Gruppi />} />
                  <Route path="/" element={<Main />} />
                </Routes>
              </RequireAuth>
            }
          />
          <Route path="login" element={<PageLogin />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const isLogged = useAppSelector((state) => state.auth.isLogged);
  const unknown = useAppSelector((state) => state.auth.unknown);

  if (isLogged === false && unknown === false) {
    return <Navigate to="/login" />;
  }
  if (isLogged === false && unknown === true) {
    return <ExpiredTokenError />;
  }

  if (isLogged === true) return children;

  return (
    <Container
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Container>
  );
}

export default Router;
