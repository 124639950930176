import { settings } from "../../customize/settings";
import { IBaseError } from "../../interfaces/errors";
import { ICava } from "../../interfaces/place/models";
import { IListResponse } from "../../interfaces/rest";
import { getJWT } from "../../lib/jwt";

const BASE_URL = `${settings.apiUrl}cava`

export const getCava = async (id: number): Promise<ICava> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: ICava | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as ICava;
}

export const getCavaList = async (): Promise<IListResponse<ICava>> => {
	const url = `${BASE_URL}/list`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IListResponse<ICava> | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IListResponse<ICava>;
}