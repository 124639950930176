import { settings } from "../../customize/settings";
import { IBaseError } from "../../interfaces/errors";
import { IDeleteResponse, IListResponse, IStoreResponse, IUpdateResponse } from "../../interfaces/rest";
import { IUser, IUserStore, IUserUpdate } from "../../interfaces/user/models";
import { getJWT } from "../../lib/jwt";

const BASE_URL = `${settings.apiUrl}user`

export const getUser = async (id: number): Promise<IUser> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IUser | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IUser;
}

export const getUserList = async (): Promise<IListResponse<IUser>> => {
	const url = `${BASE_URL}/list`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IListResponse<IUser> | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IListResponse<IUser>;
}

export const storeUser = async (data: IUserStore): Promise<IStoreResponse> => {
	const url = `${BASE_URL}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const body = JSON.stringify(data);
	const response = await fetch(url, {
		method: "POST",
		headers: headers,
		body
	});
	const json: IStoreResponse | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IStoreResponse;
}

export const updateUser = async (id: number, data: IUserUpdate): Promise<IUpdateResponse> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const body = JSON.stringify(data);
	const response = await fetch(url, {
		method: "PUT",
		headers: headers,
		body
	});
	const json: IUpdateResponse | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IUpdateResponse;
}

export const removeUser = async (id: number): Promise<IDeleteResponse> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "DELETE",
		headers: headers
	});
	const json: IDeleteResponse | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IDeleteResponse;
}
