import { Box, Icon, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { IDettaglio } from "../../../../../interfaces/dettaglio/models";
import {
  getMQPrice,
  getTNPrice,
  getTotalMQ,
  getTotalNumberOfBlocks,
  getTotalNumberOfSlabs,
  getTotalTN,
} from "../../../../../lib/functions";

interface IInfobarProps {
  rows: IDettaglio[];
  rowsSelected: number[];
  canViewCommercials: boolean;
}

function Infobar({ rows, canViewCommercials, rowsSelected }: IInfobarProps) {
  const [rowsToCalc, setRowsToCalc] = useState<IDettaglio[]>([]);
  const [slabs, setSlabs] = useState<number>(0);
  const [blocks, setBlocks] = useState<number>(0);
  const [MQ, setMQ] = useState<string>("0,00");
  const [TN, setTN] = useState<string>("0,00");
  const [MQPrice, setMQPrice] = useState<string>("0,00");
  const [TNPrice, setTNPrice] = useState<string>("0,00");

  useEffect(() => {
    setSlabs(getTotalNumberOfSlabs(rowsToCalc));
    setMQ(getTotalMQ(rowsToCalc, 2, 2));
    setMQPrice(getMQPrice(rowsToCalc, 2, 2));
    setBlocks(getTotalNumberOfBlocks(rowsToCalc));
    setTN(getTotalTN(rowsToCalc, 2, 2));
    setTNPrice(getTNPrice(rowsToCalc, 2, 2));
  }, [rowsToCalc]);
  useEffect(() => {
    if (rowsSelected.length === 0) setRowsToCalc(rows);
    else setRowsToCalc(rows.filter((row) => rowsSelected.includes(row.id)));
  }, [rows, rowsSelected]);

  return (
    <Box
      sx={{
        position: "absolute",
        pl: 2,
        left: 0,
        bottom: 0,
        height: 53,
        display: "flex",
        alignItems: "center",
      }}
    >
      {slabs > 0 && (
        <Box sx={{ display: "flex", alignItems: "center", marginRight: 2 }}>
          <Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
            <Tooltip title="Lastre">
              <Icon
                className="fa-light fa-layer-group"
                fontSize="small"
                color="action"
                sx={{ overflow: "visible", mr: 1 }}
              />
            </Tooltip>
            <Typography variant="body1" component="span" color="text.primaryc ">
              {slabs}
            </Typography>
          </Box>
          <Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
            <Typography
              variant="overline"
              color="text.secondary"
              sx={{ mr: 1, lineHeight: "unset" }}
            >
              MQ:
            </Typography>
            <Typography variant="body1" component="span" color="text.primary">
              {MQ}
            </Typography>
          </Box>
          {canViewCommercials && (
            <>
              <Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
                <Typography
                  variant="overline"
                  color="text.secondary"
                  sx={{ mr: 1, lineHeight: "unset" }}
                >
                  Prezzo/MQ:
                </Typography>
                <Typography
                  variant="body1"
                  component="span"
                  color="text.primary"
                >
                  {MQPrice} €
                </Typography>
              </Box>
            </>
          )}
        </Box>
      )}
      {blocks > 0 && (
        <Box sx={{ display: "flex", alignItems: "center", marginRight: 2 }}>
          <Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
            <Tooltip title="Blocchi">
              <Icon
                className="fa-light fa-cube"
                fontSize="small"
                color="action"
                sx={{ overflow: "visible", mr: 1 }}
              />
            </Tooltip>
            <Typography variant="body1" component="span" color="text.primary">
              {blocks}
            </Typography>
          </Box>
          <Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
            <Typography
              variant="overline"
              color="text.secondary"
              sx={{ mr: 1, lineHeight: "unset" }}
            >
              TN:
            </Typography>
            <Typography variant="body1" component="span" color="text.primary">
              {TN}
            </Typography>
          </Box>
          {canViewCommercials && (
            <>
              <Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
                <Typography
                  variant="overline"
                  color="text.secondary"
                  sx={{ mr: 1, lineHeight: "unset" }}
                >
                  Prezzo/TN:
                </Typography>
                <Typography
                  variant="body1"
                  component="span"
                  color="text.primary"
                >
                  {TNPrice} €
                </Typography>
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

export default Infobar;
