import { IUser } from "../../../interfaces/user/models";

export const GET = "user/get";
export const LIST = "user/list";
export const EMPTY = "user/empty";

export interface IUserState {
	results: number;
	list: number[];
	items: Record<number, IUser> | null;
};

export const initialState: IUserState = {
	results: 0,
	list: [],
	items: null
};