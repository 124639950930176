import { ISettings } from "../../interfaces/settings/models";

export const settings: ISettings = {
  apiUrl: "https://mag.gemeg.it/api/public/",
  main: {
    soldList: true,
    prospects: true,
    clients: true,
  },
  list: {
    sideNav: {
      hiddenFilters: {
        materialeMateriali: true,
        statoLavorazioni: true,
      },
    },
    fullTextPaths: ["lotto.codice"],
    grid: {
      columnsToHide: ["stato_lavorazione", "disponibilita"],
      groupingKeys: [
        "idLotto",
        "suffissoCodice",
        "idTipoLotto",
        "idMagazzino",
        "zona",
        "idDisponibilita",
        "opzCliente",
      ],
      initColumnVisibiliity: {
        cava: false,
        codiceCava: false,
        dataCarico: false,
        materiale: false,
        uso: false,
        magazzino: false,
        zona: false,
        fila: false,
      },
    },
  },
  item: {
    grid: {
      initDefaultColumnVisibiliity: {
        costo: false,
        um_costo: false,
      },
      defaultColumnsToHide: ["images"],
      initHistoryColumnVisibiliity: {
        costo: false,
        um_costo: false,
      },
      initPackingListColumnsVisibility: {
        costo: false,
        um_costo: false,
      },
    },
    images: {
      slabs: {
        hiddenUpdateFields: {
          slab: true,
        },
        shareZeroIfAllSelected: true,
      },
    },
  },
};
