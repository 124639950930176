import { createAction } from "@reduxjs/toolkit";
import { IQualitaExt } from "../../../../interfaces/lotto/attributes/models";
import {
  EMPTY,
  GET,
  IQualitaExtListPayload,
  IQualitaListPayload,
  LIST,
  LIST_EXT,
} from "./types";

export const listAction = createAction<IQualitaListPayload>(LIST);

export const listExtAction = createAction<IQualitaExtListPayload>(LIST_EXT);

export const getAction = createAction<IQualitaExt>(GET);

export const emptyAction = createAction(EMPTY);
