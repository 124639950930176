import {
  DataGrid,
  GridColumns,
  GridColumnVisibilityModel,
  GridRowParams,
} from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { settings } from "../../../../../../customize/settings";
import { EShowColumnState, IDettaglioTreeNode } from "../interfaces";
import {
  flattener,
  hasAncestorsClosed,
  hideColumns,
  transform,
} from "./functions";
import { createHistoryGridColumns } from "./historyGridColumns";
import { IHistoryGridProps } from "./interfaces";

function HistoryGrid({
  dettagli,
  canViewCommercials,
  canViewStoragePlace,
  canViewCosts,
  showAdminColumns,
}: IHistoryGridProps) {
  const { t } = useTranslation();
  const [rows, setRows] = useState<IDettaglioTreeNode[]>([]);
  const [displayColumns, setDysplayColumns] = useState<
    GridColumns<IDettaglioTreeNode>
  >([]);
  const [openParents, setOpenParents] = useState<number[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(
      settings.item?.grid?.initHistoryColumnVisibiliity ?? {}
    );
  const getRowClassName = (
    params: GridRowParams<IDettaglioTreeNode>
  ): string => {
    const id: number = params.id as number;
    if (hasAncestorsClosed(id, rows, openParents)) {
      return "hidden";
    }
    return "";
  };
  const columns: GridColumns<IDettaglioTreeNode> = useMemo(() => {
    const open = (id: number) => {
      if (!openParents.includes(id)) setOpenParents([...openParents, id]);
    };
    const close = (id: number) => {
      if (openParents.includes(id))
        setOpenParents(openParents.filter((item) => item !== id));
    };
    return createHistoryGridColumns({
      t,
      canViewCommercials,
      canViewStoragePlace,
      canViewCosts,
      onOpen: open,
      onClose: close,
      openList: openParents,
    });
  }, [t, canViewCommercials, canViewStoragePlace, canViewCosts, openParents]);
  useEffect(() => {
    let columnsToHide: string[] = [];
    if (showAdminColumns === EShowColumnState.HIDE_ALL)
      columnsToHide = [
        "costo",
        "um_costo",
        "magazzino",
        "zona",
        "fila",
        "opzStato",
        "opzCliente",
        "agente",
        "opzNote",
        "prezzo",
      ];
    if (showAdminColumns === EShowColumnState.HIDE_PARTIAL)
      columnsToHide = [
        "costo",
        "um_costo",
        "magazzino",
        "zona",
        "fila",
        "opzStato",
        "opzCliente",
        "agente",
        "opzNote",
      ];
    const _displayColumns = !columnsToHide.length
      ? columns
      : hideColumns(columns, columnsToHide);
    setDysplayColumns(_displayColumns);
  }, [columns, showAdminColumns]);
  useEffect(() => {
    const _treeRows = flattener(transform(dettagli));
    const _openParents = _treeRows
      .filter((item) => !item.leaf)
      .map((item) => item.id);
    setOpenParents(_openParents);
    setRows(_treeRows);
  }, [dettagli]);
  return (
    <DataGrid
      columns={displayColumns}
      columnVisibilityModel={columnVisibilityModel}
      onColumnVisibilityModelChange={(newModel) =>
        setColumnVisibilityModel(newModel)
      }
      getRowClassName={getRowClassName}
      disableSelectionOnClick
      rows={rows}
      autoHeight={true}
      hideFooter={true}
    />
  );
}

export default HistoryGrid;
