import { Button, ButtonGroup, Icon, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IDettagliSelectViewToolbarProps } from "./interfaces";

const i18_PATH = 'prodotto.view.lottoDettagli.dettagliSelectViewToolbar.'

function DettagliSelectViewToolbar({ mode, onSetMode }: IDettagliSelectViewToolbarProps) {
	const { t } = useTranslation();
	return (
		<ButtonGroup disableElevation>
			<Tooltip title={t(`${i18_PATH}In magazzino`)}>
				<Button
					variant={mode === "flat" ? "contained" : "outlined"}
					endIcon={<Icon className="fa-light fa-list" sx={{ overflow: "visible", marginRight: "4px", marginLeft: "-8px" }} />}
					sx={{ endIcon: { margin: 0 } }}
					onClick={() => onSetMode("flat")}
				></Button>
			</Tooltip>
			<Tooltip title={t(`${i18_PATH}Storia del lotto`)}>
				<Button
					variant={mode === "tree" ? "contained" : "outlined"}
					endIcon={<Icon className="fa-light fa-list-tree" sx={{ overflow: "visible", marginRight: "4px", marginLeft: "-8px" }} />}
					onClick={() => onSetMode("tree")}
				></Button>
			</Tooltip>
		</ButtonGroup>
	)
}

export default DettagliSelectViewToolbar;