import { AxiosProgressEvent } from "axios";
import React from "react";
import { errorHandler } from "../../error/handler";
import { removeImage, uploadImage } from "../../fetch/qualitaImage";
import { QualitaImageUploadFormData } from "../../interfaces/lotto/attributes/forms";
import { useAppDispatch } from "../../store/hooks";
import {
  cleanUploadProgressAction,
  endActionToDoAction,
  messageAction,
  startActionToDoAction,
  startUploadProgressAction,
  updateUploadProgressAction,
} from "../../store/ui/actions";

export const useQualitaImageRemove = (id: number | null) => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const effect = async () => {
      if (!id) return;
      const actionToDo = "remove-immagine-qualita";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        await removeImage(id);
        dispatch(endActionToDoAction({ actionToDo, success: true }));
        dispatch(
          messageAction({ message: "immagine rimossa", kind: "success" })
        );
      } catch (err) {
        errorHandler(err, actionToDo);
      }
    };
    effect();
  }, [dispatch, id]);
};

export const useQualitaImageUpload = (data: QualitaImageUploadFormData) => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const onUploadProgress = (progress: AxiosProgressEvent) => {
      const { loaded, total } = progress;
      const progressPerc = (loaded * 100) / (total || 1);
      dispatch(
        updateUploadProgressAction({ index: 0, progress: progressPerc })
      );
    };
    const effect = async () => {
      if (!data) return;
      const actionToDo = "upload-immagine-qualita";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        dispatch(startUploadProgressAction({ numberOfFiles: 1 }));
        await uploadImage(data, onUploadProgress);
        dispatch(endActionToDoAction({ actionToDo, success: true }));
        dispatch(cleanUploadProgressAction({}));
        dispatch(
          messageAction({
            message: "upload immagine riuscito",
            kind: "success",
          })
        );
      } catch (err) {
        errorHandler(err, actionToDo);
        dispatch(cleanUploadProgressAction({}));
      }
    };
    effect();
  }, [dispatch, data]);
};
