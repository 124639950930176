import { Box } from '@mui/material';
import { DataGrid, GridColumnVisibilityModel } from '@mui/x-data-grid';
import React, { useEffect, useState } from "react";
import { getColumnVisibilityModel, storeColumnVisibilityModel } from '../../../../../lib/functions';
import { createColumns } from './columns';
import { ITableProps } from './interfaces';
import TableToolbar from './toolbar';

function Table(props: ITableProps) {
	const { rows, onEdit, onRemove, onAdd } = props;
	const [pageSize, setPageSize] = useState<number>(25);
	const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel | null>(null);
	const columns = React.useMemo(() => createColumns({ onEdit, onRemove }), [onEdit, onRemove]);
	useEffect(() => {
		const model = getColumnVisibilityModel('UserColumnVisibilityModel');
		if (model) setColumnVisibilityModel(model);
		else setColumnVisibilityModel({});
	}, []);
	useEffect(() => {
		storeColumnVisibilityModel('UserColumnVisibilityModel', columnVisibilityModel);
	}, [columnVisibilityModel]);
	return (
		<Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
			{!!columnVisibilityModel &&
				<DataGrid
					rows={rows}
					columns={columns}
					columnVisibilityModel={columnVisibilityModel || undefined}
					onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
					rowsPerPageOptions={[25, 50, 100]}
					onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
					pageSize={pageSize}
					disableSelectionOnClick
					components={{ Toolbar: TableToolbar }}
					componentsProps={{ toolbar: { onAdd } }}
				/>
			}
		</Box>
	)
}

export default Table;