import { ITipoLotto } from "../../../../interfaces/dettaglio/attributes/models";

export const GET = "tipoLotto/get";
export const LIST = "tipoLotto/list";
export const EMPTY = "tipoLotto/empty";

export interface ITipoLottoState {
	results: number;
	list: number[];
	items: Record<number, ITipoLotto> | null
};

export const initialState: ITipoLottoState = {
	results: 0,
	list: [],
	items: null
};