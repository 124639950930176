import * as React from 'react';
import { errorHandler } from '../../../error/handler';
import { getFondo, getFondoList } from '../../../fetch/lotto/attributes/fondo';
import { IFondo } from '../../../interfaces/lotto/attributes/models';
import { normalizer } from '../../../lib/normalizer';
import { useAppDispatch } from '../../../store/hooks';
import { emptyAction, getAction, listAction } from '../../../store/lotto/attributes/fondo/actions';
import { IFondoState } from '../../../store/lotto/attributes/fondo/types';
import { endActionToDoAction, startActionToDoAction } from '../../../store/ui/actions';

export const useGetFondo = (id: number) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!id) return;
			const actionToDo = 'get-fondo';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const fondo = await getFondo(id);
				dispatch(getAction(fondo));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id])
}

export const useFondoList = (query: number) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!query) return;
			const actionToDo = 'list-fondo';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const { results, items: _items } = await getFondoList();
				_items.sort((itemA, itemB) => (!!itemA?.ordinamento && !!itemB.ordinamento) ? itemA.ordinamento - itemB.ordinamento : 0);
				const { list, items } = normalizer<number, IFondo>(_items, "id");
				const actionPayload: IFondoState = {
					results,
					list,
					items
				}
				dispatch(listAction(actionPayload));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
			return () => {
				dispatch(emptyAction());
			}
		}
		effect();
	}, [dispatch, query])
}
