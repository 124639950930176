import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetMateriale,
  useMaterialeUpdate,
} from "../../../effects/lotto/attributes/materiale";
import { MaterialeUpdateFormData } from "../../../interfaces/lotto/attributes/forms";
import { useAppSelector } from "../../../store/hooks";

const i18_PATH = "materiale.update.";

function Update() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState<MaterialeUpdateFormData>(null);
  const { id } = useParams();
  const materialeId = !!id ? parseInt(id) : null;
  const materiale = useAppSelector(
    (state) => state.lotto_materiale.itemsExt?.[parseInt(id ?? "")]
  );
  const [nome, setNome] = useState("");
  const [descrizione, setDescrizione] = useState("");

  const onDescrizioneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDescrizione(event.target.value);
  };
  const submit = () => {
    setFormData({ descrizione });
  };

  const back = () => {
    navigate("..");
  };

  useMaterialeUpdate(materialeId, formData);
  useGetMateriale(materialeId);
  useEffect(() => {
    if (materiale?.nome) setNome(materiale.nome);
    if (materiale?.ext?.descrizione) setDescrizione(materiale.ext.descrizione);
  }, [materiale]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        backgroundColor: "#fff",
      }}
    >
      <Box sx={{ flex: 1, position: "relative" }}>
        <Box
          sx={{
            backgroundColor: "#fff",
            boxSizing: "border-box",
            position: "absolute",
            height: "100%",
            width: "100%",
            overflowY: "auto",
          }}
        >
          <Box sx={{ p: 2 }}>
            <Typography variant="subtitle1">Modifica Materiale</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", padding: 2 }}>
            <TextField
              margin="dense"
              label={t(`${i18_PATH}Nome`)}
              type="text"
              value={nome}
              disabled
            />
            {/** da sostituire con un editor complesso */}
            <TextField
              margin="dense"
              label={t(`${i18_PATH}Descrizione`)}
              type="text"
              value={descrizione}
              onChange={onDescrizioneChange}
              multiline
              rows={10}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: 2,
          border: "1px solid rgba(224, 224, 224, 1)",
        }}
      >
        <Button onClick={back}>Cancella</Button>
        <Button variant="contained" onClick={submit}>
          Salva
        </Button>
      </Box>
    </Box>
  );
}

export default Update;
