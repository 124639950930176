import { GridCsvExportMenuItem, GridToolbarContainer, GridToolbarExportContainer } from '@mui/x-data-grid';

function PackingListToolbar() {
	return (
		<GridToolbarContainer sx={{ displayPrint: 'none' }}>
			<GridToolbarExportContainer>
				<GridCsvExportMenuItem options={{ fileName: "packingList", delimiter: ';' }} />
			</GridToolbarExportContainer>
		</GridToolbarContainer>
	);
}

export default PackingListToolbar;