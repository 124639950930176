import { AppBar, Box, Button, Dialog, DialogActions, Icon, IconButton, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { IDettaglio } from "../../../../../../../interfaces/dettaglio/models";
import { ILotto } from "../../../../../../../interfaces/lotto/models";
import Transition from "../../../../../../../lib/dialogTransition";
import PackingListGrid from "../../grids/packingListGrid";
import { IPackingListDialogProps } from "../interfaces";
import PackingListBottomBar from "./packingListBottomBar";

const createPackingList = (lotto: ILotto, selectedItems: IDettaglio[]): IDettaglio[] => {
	const packingList: IDettaglio[] = []
	selectedItems.forEach((item) => {
		packingList.push({
			...item,
			lotto: lotto,
		})
	});
	return packingList;
}


function DettaglioPackageListDialog({
	isOpen,
	lotto,
	selectedItems,
	canViewCosts,
	canViewCommercials,
	canViewStoragePlace,
	canViewCommercialOptions,
	showAdminColumns,
	onClose
}: IPackingListDialogProps) {
	const [packingList, setPackingList] = useState<IDettaglio[]>([]);
	useEffect(() => {
		setPackingList(createPackingList(lotto, selectedItems));
	}, [lotto, selectedItems])
	const close = () => onClose();
	const print = () => window.print();

	return (
		<Dialog
			open={isOpen}
			onClose={close}
			TransitionComponent={Transition}
			fullWidth
			maxWidth="xl"
		>
			<AppBar sx={{ position: 'relative', displayPrint: 'none' }}>
				<Toolbar variant="dense">
					<Typography variant="h5" sx={{ flex: 1 }} display="block" >Packing List</Typography>
					<IconButton
						edge="end"
						color="inherit"
						onClick={close}
						aria-label="close"
					>
						<Icon className="fa-light fa-xmark" sx={{ overflow: "visible" }} />
					</IconButton>
				</Toolbar>
			</AppBar>
			<Box>
				<Box sx={{ p: 2, height: '80vh', overflowY: 'auto', display: "flex", flexDirection: "column", position: "relative", backgroundColor: "#fff" }}>
					<PackingListGrid
						packingList={packingList}
						canViewCosts={canViewCosts}
						canViewCommercials={canViewCommercials}
						canViewStoragePlace={canViewStoragePlace}
						canViewCommercialOptions={canViewCommercialOptions}
						showAdminColumns={showAdminColumns}
					/>
					<PackingListBottomBar rows={packingList} canViewCommercials={true} />
				</Box>
			</Box>
			<DialogActions sx={{ displayPrint: 'none' }}>
				<Button variant="outlined" onClick={print} >print</Button>
				<Button onClick={close}>chiudi</Button>
			</DialogActions>
		</Dialog>
	)
}

export default DettaglioPackageListDialog;