import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";
import {
	emptyAction,
	startActionToDoAction,
	endActionToDoAction,
	messageAction,
	startUploadProgressAction,
	updateUploadProgressAction,
	cleanUploadProgressAction
} from "./actions";
import { initialState } from "./types";

export default createReducer(initialState, (builder) => {
	builder.addCase(startActionToDoAction, (state, action) => produce(state, draft => {
		const { actionToDo } = action.payload;
		draft.actionsToDo.push(actionToDo);
		draft.lastActionDone = null;
	}));
	builder.addCase(endActionToDoAction, (state, action) => produce(state, draft => {
		const { actionToDo, success } = action.payload;
		draft.actionsToDo = draft.actionsToDo.filter(item => item !== actionToDo)
		if (success) draft.lastActionDone = actionToDo;
		else draft.lastActionError = actionToDo;
	}));
	builder.addCase(messageAction, (state, action) => produce(state, draft => {
		const message = action.payload;
		draft.message = message;
	}));
	builder.addCase(startUploadProgressAction, (state, action) => produce(state, draft => {
		const { numberOfFiles } = action.payload;
		draft.uploadProgress = Array.from({ length: numberOfFiles }, () => 0);
	}));
	builder.addCase(updateUploadProgressAction, (state, action) => produce(state, draft => {
		const { index, progress } = action.payload;
		draft.uploadProgress[index] = progress;
	}));
	builder.addCase(cleanUploadProgressAction, (state) => produce(state, draft => {
		draft.uploadProgress = [];
	}));
	builder.addCase(emptyAction, () => initialState);
});
