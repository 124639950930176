import { IGroupPermissions } from "../../../interfaces/user/models";

export const GET = "groupPermissions/get";
export const LIST = "groupPermissions/list";
export const EMPTY = "groupPermissions/empty";

export interface IGroupPermissionsState {
	results: number;
	list: number[];
	items: Record<number, IGroupPermissions> | null;
};

export const initialState: IGroupPermissionsState = {
	results: 0,
	list: [],
	items: null
};