import { createAction } from '@reduxjs/toolkit';
import { IMagazzino } from '../../../interfaces/place/models';
import { EMPTY, GET, IMagazzinoState, LIST } from './types';

export const listAction = createAction<IMagazzinoState>(LIST);

export const getAction = createAction<IMagazzino>(GET);

export const emptyAction = createAction(EMPTY);

