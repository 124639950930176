import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";
import {
  clearShareTokenAction,
  emptyAction,
  getAction,
  getShareTokenAction,
  listAction,
  updateCommercialsAction,
  updateMeasuresAction,
  updateNotesAction,
  updateStatoLavorazioneAction,
  updateStorageAction,
} from "./actions";
import { initialState } from "./types";

export default createReducer(initialState, (builder) => {
  builder.addCase(listAction, (state, action) =>
    produce(state, (draft) => {
      const { results, list, items } = action.payload;
      draft.results = results;
      draft.list = list;
      draft.items = items;
    })
  );
  builder.addCase(getAction, (state, action) =>
    produce(state, (draft) => {
      const lotto = action.payload;
      if (!draft.items) draft.items = {};
      draft.items[lotto.id] = lotto;
    })
  );
  builder.addCase(emptyAction, () => initialState);
  builder.addCase(getShareTokenAction, (state, action) =>
    produce(state, (draft) => {
      draft.shareToken = action.payload;
    })
  );
  builder.addCase(clearShareTokenAction, (state, action) =>
    produce(state, (draft) => {
      draft.shareToken = null;
    })
  );
  builder.addCase(updateCommercialsAction, (state, action) =>
    produce(state, (draft) => {
      const { lottoId, dettaglioIds, lastreDa, lastreA, prezzo } =
        action.payload;
      const dettagli = draft.items?.[lottoId]?.dettagli;
      dettaglioIds.forEach((id) => {
        const current = dettagli?.find((dettaglio) => dettaglio.id === id);
        if (!!current) {
          if (lastreDa !== undefined) current.lastreDa = lastreDa;
          if (lastreA !== undefined) current.lastreA = lastreA;
          if (prezzo !== undefined) current.prezzo = prezzo;
        }
      });
    })
  );
  builder.addCase(updateStorageAction, (state, action) =>
    produce(state, (draft) => {
      const { lottoId, dettaglioIds, zona, fila } = action.payload;
      const dettagli = draft.items?.[lottoId]?.dettagli;
      dettaglioIds.forEach((id) => {
        const current = dettagli?.find((dettaglio) => dettaglio.id === id);
        if (!!current) {
          if (zona !== undefined) {
            current.idZona = zona?.id || null;
            current.zona = zona;
          }

          if (fila !== undefined) {
            current.idFila = fila?.id || null;
            current.fila = fila;
          }
        }
      });
    })
  );
  builder.addCase(updateStatoLavorazioneAction, (state, action) =>
    produce(state, (draft) => {
      const { lottoId, dettaglioIds, statoLavorazione } = action.payload;
      const dettagli = draft.items?.[lottoId]?.dettagli;
      dettaglioIds.forEach((id) => {
        const current = dettagli?.find((dettaglio) => dettaglio.id === id);
        if (!!current) {
          if (statoLavorazione !== undefined) {
            if (statoLavorazione === null) {
              current.stato_lavorazione = null;
              current.idStatoLavorazione = null;
            } else {
              current.stato_lavorazione = statoLavorazione;
              current.idStatoLavorazione = statoLavorazione.id;
            }
          }
        }
      });
    })
  );
  builder.addCase(updateNotesAction, (state, action) =>
    produce(state, (draft) => {
      const { lottoId, dettaglioIds, opzNote } = action.payload;
      const dettagli = draft.items?.[lottoId]?.dettagli;
      dettaglioIds.forEach((id) => {
        const current = dettagli?.find((dettaglio) => dettaglio.id === id);
        if (!!current) {
          if (opzNote !== undefined && opzNote !== null) {
            current.opzNote = opzNote;
          }
        }
      });
    })
  );
  builder.addCase(updateMeasuresAction, (state, action) =>
    produce(state, (draft) => {
      const { lottoId, dettaglioIds, misX, misY, misZ } = action.payload;
      const dettagli = draft.items?.[lottoId]?.dettagli;
      dettaglioIds.forEach((id) => {
        const current = dettagli?.find((dettaglio) => dettaglio.id === id);
        if (!!current) {
          if (misX !== undefined) current.misX = misX;
          if (misY !== undefined) current.misY = misY;
          if (misZ !== undefined) current.misZ = misZ;
        }
      });
    })
  );
});
