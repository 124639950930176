import {
  AppBar,
  Box,
  Button,
  Icon,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  LinkProps,
  NavLink,
  useMatch,
  useResolvedPath,
} from "react-router-dom";
import logo from "../../../customize/assets/logo.png";
import { settings } from "../../../customize/settings";
import { EClientsViews, EProspectsViews } from "../../../interfaces/user/enums";
import { IUser } from "../../../interfaces/user/models";

const i18_PATH = "navBar.";

interface INavigationBarProps {
  user: IUser;
  onLogout: () => void;
}

function CustomLink({ children, to }: LinkProps) {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: false });

  return (
    <Button
      color="inherit"
      sx={{ mx: 1, my: 1, display: "flex" }}
      component={NavLink}
      variant={match ? "outlined" : "text"}
      to={to}
    >
      {children}
    </Button>
  );
}

function NavigationBar({ user, onLogout }: INavigationBarProps) {
  const { t } = useTranslation();
  return (
    <AppBar position="static" sx={{ zIndex: 999 }}>
      <Toolbar>
        <NavLink to="/">
          <img src={logo} alt="logo" height={60} />
        </NavLink>
        {!user.unknown && (
          <Box sx={{ display: "flex", flexGrow: 1, px: 8 }}>
            <CustomLink to="/prodotti">{t(`${i18_PATH}Prodotti`)}</CustomLink>
            {settings.main?.soldList &&
              (user?.admin ||
                user?.user_group?.group_permissions?.canViewSoldList) && (
                <CustomLink to="/venduto">{t(`${i18_PATH}Venduto`)}</CustomLink>
              )}
            {settings.main?.materialList && (
              <CustomLink to="/materiali">
                {t(`${i18_PATH}Materiali`)}
              </CustomLink>
            )}
            {settings.main?.qualitaList && (
              <CustomLink to="/qualita">{t(`${i18_PATH}Qualità`)}</CustomLink>
            )}
            {settings.main?.prospects &&
              (user.admin ||
                user.user_group?.group_permissions?.prospects !==
                  EProspectsViews.none) && (
                <CustomLink to="/prospects">
                  {t(`${i18_PATH}Prospects`)}
                </CustomLink>
              )}
            {settings.main?.clients &&
              (user.admin ||
                user.user_group?.group_permissions?.clients !==
                  EClientsViews.none) && (
                <CustomLink to="/clienti">{t(`${i18_PATH}Clienti`)}</CustomLink>
              )}
            {(user.admin ||
              user.user_group?.group_permissions?.canViewUsers) && (
              <CustomLink to="/utenti">{t(`${i18_PATH}Utenti`)}</CustomLink>
            )}
            {user.admin && (
              <CustomLink to="/gruppi-utente">
                {t(`${i18_PATH}Gruppi Utenti`)}
              </CustomLink>
            )}
          </Box>
        )}
        {!user.unknown && (
          <Box
            sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end" }}
          >
            <Button
              color="inherit"
              sx={{ mx: 1, my: 1, display: "flex" }}
              startIcon={<Icon className="fa-light fa-circle-user" />}
            >
              <Typography
                variant="caption"
                noWrap
                component="div"
                sx={{ mx: 1 }}
              >
                {user?.nome}
              </Typography>
            </Button>
            <Tooltip title={t(`${i18_PATH}Esci`)}>
              <IconButton size="large" color="inherit" onClick={onLogout}>
                <Icon
                  className="fa-light fa-right-from-bracket"
                  sx={{ overflow: "visible" }}
                />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default NavigationBar;
