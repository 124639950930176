import * as React from 'react';
import { errorHandler } from '../../error/handler';
import { getZona, getZonaList } from '../../fetch/place/zona';
import { IZona } from '../../interfaces/place/models';
import { normalizer } from '../../lib/normalizer';
import { useAppDispatch } from '../../store/hooks';
import { emptyAction, getAction, listAction } from '../../store/place/zona/actions';
import { IZonaState } from '../../store/place/zona/types';
import { endActionToDoAction, startActionToDoAction } from '../../store/ui/actions';

export const useZonaGet = (id: number) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!id) return;
			const actionToDo = 'get-zona';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const zona = await getZona(id);
				dispatch(getAction(zona));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id])
}

export const useZonaList = (query: number) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!query) return;
			const actionToDo = 'list-zona';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const { results, items: _items } = await getZonaList();
				_items.sort((itemA, itemB) => (!!itemA?.ordinamento && !!itemB.ordinamento) ? itemA.ordinamento - itemB.ordinamento : 0);
				const { list, items } = normalizer<number, IZona>(_items, "id");
				const actionPayload: IZonaState = {
					results,
					list,
					items
				}
				dispatch(listAction(actionPayload));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
			return () => {
				dispatch(emptyAction());
			}
		}
		effect();
	}, [dispatch, query])
}