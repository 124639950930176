import { createAction } from '@reduxjs/toolkit';
import { IUso } from '../../../../interfaces/lotto/attributes/models';
import { EMPTY, GET, IUsoState, LIST } from './types';

export const listAction = createAction<IUsoState>(LIST);

export const getAction = createAction<IUso>(GET);

export const emptyAction = createAction(EMPTY);

