import { createAction } from '@reduxjs/toolkit';
import { IProspect } from '../../interfaces/prospect/models';
import { EMPTY, GET, IProspectState, LIST } from './types';

export const listAction = createAction<IProspectState>(LIST);

export const getAction = createAction<IProspect>(GET);

export const emptyAction = createAction(EMPTY);

