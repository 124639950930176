import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Edit from './edit';
import Store from './store';
import List from './list';

function Router() {
	return (
		<Routes>
			<Route path="/" element={<List />} />
			<Route path="/:userGroupId" element={<Edit />} />
			<Route path="/new" element={<Store />} />
		</Routes>
	);
}

export default Router;