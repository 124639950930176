import { settings } from "../../../customize/settings";
import { IBaseError } from "../../../interfaces/errors";
import { QualitaUpdateFormData } from "../../../interfaces/lotto/attributes/forms";
import {
  IQualita,
  IQualitaExt,
} from "../../../interfaces/lotto/attributes/models";
import { IListResponse, IUpdateResponse } from "../../../interfaces/rest";
import { getJWT } from "../../../lib/jwt";

const BASE_URL = `${settings.apiUrl}qualita`;

export const getQualita = async (id: number): Promise<IQualitaExt> => {
  const url = `${BASE_URL}/${id}`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const json: IQualitaExt | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IQualitaExt;
};

export const getQualitaList = async (): Promise<IListResponse<IQualita>> => {
  const url = `${BASE_URL}/list`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const json: IListResponse<IQualita> | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IListResponse<IQualita>;
};

export const getQualitaExtList = async (): Promise<
  IListResponse<IQualitaExt>
> => {
  const url = `${BASE_URL}/listExt`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const json: IListResponse<IQualita> | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IListResponse<IQualitaExt>;
};

export const updateQualita = async (
  id: number,
  data: QualitaUpdateFormData
): Promise<IUpdateResponse> => {
  const url = `${BASE_URL}/${id}`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const body = JSON.stringify(data);
  const response = await fetch(url, {
    method: "PUT",
    headers: headers,
    body,
  });
  const json: IUpdateResponse | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IUpdateResponse;
};
