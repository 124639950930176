import { LinearProgress, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { IItemProps } from './interfaces';

function FileToUpload({ file, isUploading, progress }: IItemProps) {
	return (
		<ListItem>
			<ListItemIcon>
				<img
					src={file.preview}
					alt={file.name}
					style={{ height: '60px', marginRight: '8px' }}
					// Revoke data uri after image is loaded
					onLoad={() => { URL.revokeObjectURL(file.preview) }}
				/>
			</ListItemIcon>
			{!isUploading &&
				<ListItemText
					primary={file.name}
					secondary={file.size + " bytes"}
				/>
			}
			{isUploading &&
				<LinearProgress sx={{ flex: 1 }} variant="determinate" value={progress} />
			}
		</ListItem>
	)
}

export default FileToUpload;