import { List, ListSubheader } from "@mui/material";
import { useMemo } from "react";
import { settings } from "../../../../../../../customize/settings";
import { EOptionType } from "../../../../../../../interfaces/enums";
import { IFilterProps } from "../../interfaces";
import ClientiFilter from "./clienti";
import DisponibilitaFilter from "./disponibilita";
import PrezzoFilter from "./prezzo";

function CommercialeFiltersContainer({ filters, setFilters }: IFilterProps) {
  const showClienti: boolean = useMemo(
    () =>
      filters.some(
        (filter) =>
          filter.type === EOptionType.disponibilita &&
          [1, 2].includes(filter.numericValue as number)
      ),
    [filters]
  );

  return (
    <List
      dense={true}
      sx={{ p: 0 }}
      component="nav"
      subheader={<ListSubheader component="div">Commerciale</ListSubheader>}
    >
      {!settings.list?.sideNav?.hiddenFilters?.commercialeDisponibilita && (
        <DisponibilitaFilter filters={filters} setFilters={setFilters} />
      )}

      {showClienti &&
        !settings.list?.sideNav?.hiddenFilters?.commercialeClienti && (
          <ClientiFilter filters={filters} setFilters={setFilters} />
        )}
      {!settings.list?.sideNav?.hiddenFilters?.commercialePrezzo && (
        <PrezzoFilter filters={filters} setFilters={setFilters} />
      )}
    </List>
  );
}

export default CommercialeFiltersContainer;
