import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { FixedSizeGrid as Grid } from 'react-window';
import { IDettaglio } from "../../../../../interfaces/dettaglio/models";
import { matrixify } from "./functions";
import { GridCell } from "./gridCell";
import { ILottoGridProps } from "./interfaces";

function GridView({ rows, onView }: ILottoGridProps) {
	const [matrix, setMatrix] = useState<IDettaglio[][]>()
	const [matrixRows, setMatrixRows] = useState<number>(0);
	const [matrixColumns] = useState<number>(4);
	const [gridHeight, setGridHeight] = useState<number>(0);
	const [gridWidth, setGridWidth] = useState<number>(0);
	const parent = useRef<Element>();
	const setGridDimensions = () => {
		const element = parent.current;
		if (!!element) {
			const { height, width } = element.getBoundingClientRect();
			setGridHeight(height);
			setGridWidth(width);
		}

	}
	useEffect(() => {
		const orderedRows: IDettaglio[] = [...rows];
		orderedRows.sort((itemA, itemB) => {
			const a = itemA.lotto?.codice || "";
			const b = itemB.lotto?.codice || "";
			if (a < b) return -1;
			if (a > b) return 1;
			return 0
		});
		const _matrix = matrixify(orderedRows, matrixColumns);
		setMatrixRows(_matrix.length);
		setMatrix(_matrix);
	}, [rows, matrixColumns]);
	useEffect(() => {
		setGridDimensions()
	}, [])
	return (
		<Box sx={{
			position: "absolute", height: "100%", width: "100%",
			"& .Grid": {
				boxSizing: "border-box",
				overflowX: "hidden"
			}
		}}
			ref={parent}
		>
			<Grid
				className="Grid"
				itemData={matrix}
				columnCount={matrixColumns}
				columnWidth={(gridWidth - 18) / matrixColumns}
				height={gridHeight}
				width={gridWidth}
				rowCount={matrixRows}
				rowHeight={380}
			>
				{(props) => GridCell({ ...props, onView })}
			</Grid >
		</Box >
	);
}

export default GridView;