import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserGroupList, useUserGroupRemove } from "../../../effects/user/userGroup";
import { IUserGroup } from "../../../interfaces/user/models";
import { useAppSelector } from "../../../store/hooks";
import DeleteDialog from "./components/dialogs/deleteDialog";
import Table from "./components/table/table";

function List() {
	const [query, setQuery] = useState<number>(1);
	const [userGroupToDelete, setUserGroupToDelete] = useState<IUserGroup | null>(null);
	const [userGroupIdToDelete, setUserGroupIdToDelete] = useState<number | null>(null);
	const [groupPermissionsIdToDelete, setGroupPermissionsIdToDelete] = useState<number | null>(null);
	useUserGroupList(query);
	useUserGroupRemove(userGroupIdToDelete, groupPermissionsIdToDelete);
	const navigate = useNavigate();
	const list = useAppSelector((state) => state.userGroup.list);
	const items = useAppSelector((state) => state.userGroup.items);
	const rows: IUserGroup[] = (!!list && !!items) ? list.map(item => items[item]) : [];
	const lastActionDone = useAppSelector((state) => state.ui.lastActionDone);
	useEffect(() => {
		if (lastActionDone === "remove-user-group") setQuery(query + 1);
	}, [lastActionDone, query]);
	const edit = (id: number) => navigate(`${id}`);
	const remove = (id: number) => setUserGroupToDelete(items?.[id] || null);
	const add = () => navigate(`new`);
	const confirmDialog = () => {
		setUserGroupIdToDelete(userGroupToDelete?.id || null);
		setGroupPermissionsIdToDelete(userGroupToDelete?.id_groupPermissions || null);
		setUserGroupToDelete(null);
	};
	const cancelDialog = () => setUserGroupToDelete(null);
	return (
		<Box sx={{ display: "flex", flexDirection: "column", flex: 1, backgroundColor: "#fff" }}>
			<Table
				rows={rows}
				onEdit={edit}
				onRemove={remove}
				onAdd={add}
			></Table>
			<DeleteDialog
				item={userGroupToDelete}
				onCancel={cancelDialog}
				onConfirm={confirmDialog}
			/>
		</Box>
	)
}

export default List;