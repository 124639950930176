import { EOptionType } from "../interfaces/enums";
import { IDettaglio, IFilterOption } from "../interfaces/dettaglio/models";
import { settings } from "../customize/settings";
import { isString } from "./utils";

export type FilterFunction = (item: IDettaglio, props: FilterProps) => boolean;
export type FilterProps = {
  numericValue?: number;
  numericValues?: number[];
  stringValue?: string;
  stringValues?: string[];
  booleanValue?: boolean;
};
export type Filter = (Item: IDettaglio) => boolean;

export const filterMateriale: FilterFunction = (
  item: IDettaglio,
  { numericValue }: FilterProps
): boolean => item?.lotto?.idMateriale === numericValue;

export const filterTipoLotto: FilterFunction = (
  item: IDettaglio,
  { numericValue }: FilterProps
): boolean => item.idTipoLotto === numericValue;

export const filterCava: FilterFunction = (
  item: IDettaglio,
  { numericValue }: FilterProps
): boolean => item?.lotto?.idCava === numericValue;

export const filterQualita: FilterFunction = (
  item: IDettaglio,
  { numericValue }: FilterProps
): boolean => item?.lotto?.idQualita === numericValue;

export const filterCategoria: FilterFunction = (
  item: IDettaglio,
  { numericValue }: FilterProps
): boolean => item?.lotto?.idCategoria === numericValue;

export const filterFondo: FilterFunction = (
  item: IDettaglio,
  { numericValue }: FilterProps
): boolean => item?.lotto?.idFondo === numericValue;

export const filterVenatura: FilterFunction = (
  item: IDettaglio,
  { numericValue }: FilterProps
): boolean => item?.lotto?.idVenatura === numericValue;

export const filterUso: FilterFunction = (
  item: IDettaglio,
  { numericValue }: FilterProps
): boolean => item?.lotto?.idUso === numericValue;

export const filterMagazzino: FilterFunction = (
  item: IDettaglio,
  { numericValue }: FilterProps
): boolean => item.idMagazzino === numericValue;

export const filterZona: FilterFunction = (
  item: IDettaglio,
  { numericValue }: FilterProps
): boolean => item.idZona === numericValue;

export const filterFila: FilterFunction = (
  item: IDettaglio,
  { numericValue }: FilterProps
): boolean => item.idFila === numericValue;

export const filterStatoLavorazione: FilterFunction = (
  item: IDettaglio,
  { numericValue }: FilterProps
): boolean => item?.idStatoLavorazione === numericValue;

export const filterDisponibilità: FilterFunction = (
  item: IDettaglio,
  { numericValue }: FilterProps
): boolean => item.idDisponibilita === numericValue;

export const filterOpzCliente: FilterFunction = (
  item: IDettaglio,
  { stringValue }: FilterProps
): boolean => item.opzCliente === stringValue;

export const filterFullText: FilterFunction = (
  item: IDettaglio,
  { stringValue }: FilterProps
): boolean => {
  if (!stringValue) return false;
  const fields = settings.list?.fullTextPaths;
  return fields.reduce((prev, field) => {
    return prev || searchInField(item, field, stringValue);
  }, false);
};

const searchInField = (
  item: IDettaglio,
  field: string,
  text: string
): boolean => {
  const keys = field.split(".");
  let value: any = item;
  while (keys.length) {
    const key = keys.shift();
    if (!!key) value = value[key];
    if (!value) return false;
  }
  if (!isString(value)) return false;
  return value.toLowerCase().includes(text.toLowerCase());
};
export const filterPrezzo: FilterFunction = (
  item: IDettaglio,
  { numericValues }: FilterProps
): boolean => {
  if (!numericValues) return false;
  const minPrice = numericValues[0];
  const maxPrice = numericValues[1];
  if (minPrice !== null && maxPrice !== null)
    return !!item.prezzo && item.prezzo >= minPrice && item.prezzo <= maxPrice;
  if (minPrice !== null) return !!item.prezzo && item.prezzo >= minPrice;
  if (maxPrice !== null) return !!item.prezzo && item.prezzo <= maxPrice;
  return false;
};

export const filterDaSegare: FilterFunction = (
  item: IDettaglio,
  { booleanValue }: FilterProps
): boolean => item.lotto?.daSegare === booleanValue;

export const selectFilter = ({
  type,
  numericValue,
  numericValues,
  stringValue,
  booleanValue,
}: IFilterOption): Filter => {
  switch (type) {
    case EOptionType.categoria:
      return (item: IDettaglio) => filterCategoria(item, { numericValue });
    case EOptionType.cava:
      return (item: IDettaglio) => filterCava(item, { numericValue });
    case EOptionType.fondo:
      return (item: IDettaglio) => filterFondo(item, { numericValue });
    case EOptionType.venatura:
      return (item: IDettaglio) => filterVenatura(item, { numericValue });
    case EOptionType.magazzino:
      return (item: IDettaglio) => filterMagazzino(item, { numericValue });
    case EOptionType.zona:
      return (item: IDettaglio) => filterZona(item, { numericValue });
    case EOptionType.fila:
      return (item: IDettaglio) => filterFila(item, { numericValue });
    case EOptionType.materiale:
      return (item: IDettaglio) => filterMateriale(item, { numericValue });
    case EOptionType.qualita:
      return (item: IDettaglio) => filterQualita(item, { numericValue });
    case EOptionType.tipoLotto:
      return (item: IDettaglio) => filterTipoLotto(item, { numericValue });
    case EOptionType.uso:
      return (item: IDettaglio) => filterUso(item, { numericValue });
    case EOptionType.statoLavorazione:
      return (item: IDettaglio) =>
        filterStatoLavorazione(item, { numericValue });
    case EOptionType.fullText:
      return (item: IDettaglio) => filterFullText(item, { stringValue });
    case EOptionType.disponibilita:
      return (item: IDettaglio) => filterDisponibilità(item, { numericValue });
    case EOptionType.cliente:
      return (item: IDettaglio) => filterOpzCliente(item, { stringValue });
    case EOptionType.prezzo:
      return (item: IDettaglio) => filterPrezzo(item, { numericValues });
    case EOptionType.daSegare:
      return (item: IDettaglio) => filterDaSegare(item, { booleanValue });
    default:
      return (_: IDettaglio) => true;
  }
};

export const concatANDFilters = (filters: Filter[]): Filter => {
  return (item: IDettaglio) => {
    let answer = true;
    filters.forEach((filter) => {
      if (!filter(item)) answer = false;
    });
    return answer;
  };
};

export const concatANDOptionsToFilter = (
  filterOptions: IFilterOption[]
): Filter => {
  const concatFilters = filterOptions.map((option) => selectFilter(option));
  return (item: IDettaglio) => {
    let answer = true;
    concatFilters.forEach((filter) => {
      if (!filter(item)) answer = false;
    });
    return answer;
  };
};

export const concatOROptionsToFilter = (
  filterOptions: IFilterOption[]
): Filter => {
  const concatFilters = filterOptions.map((option) => selectFilter(option));
  return (item: IDettaglio) => {
    let answer = false;
    concatFilters.forEach((filter) => {
      if (!!filter(item)) answer = true;
    });
    return answer;
  };
};

export const groupFiltersOptions = (
  filterOptions: IFilterOption[]
): IFilterOption[][] => {
  const group: Record<string, IFilterOption[]> = {};
  filterOptions.forEach((option) => {
    if (!group[option.type]) group[option.type] = [];
    group[option.type].push(option);
  });
  return Object.values(group);
};

export const createFilter = (filterOptions: IFilterOption[]): Filter => {
  const aggregateOptions = groupFiltersOptions(filterOptions);
  const aggregateFilters = aggregateOptions.map((options) =>
    concatOROptionsToFilter(options)
  );
  return concatANDFilters(aggregateFilters);
};

export const isFilterAndValueSelected = (
  filters: IFilterOption[],
  type: EOptionType,
  value: number | string | boolean
): boolean =>
  !!filters.find(
    (opt) =>
      opt.type === type &&
      (opt.numericValue === value ||
        opt.stringValue === value ||
        opt.booleanValue === value)
  );

export const isFilterAndValuesArraySelected = (
  filters: IFilterOption[],
  type: EOptionType,
  value: number
): boolean =>
  !!filters.find(
    (opt) => opt.type === type && opt.numericValues?.includes(value)
  );
