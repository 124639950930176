import { Button, ButtonGroup, Icon, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { EShowColumnState, IDettagliShowHideColumnsToolbarProps } from "./interfaces";

const i18_PATH = 'prodotto.view.lottoDettagli.showHideColumnsToolbar.'

function ShowHideColumnsToolbar({ showColumns, onShow, onHide, onPartialHide }: IDettagliShowHideColumnsToolbarProps) {
	const { t } = useTranslation();
	return (
		<ButtonGroup disableElevation>
			{showColumns === EShowColumnState.SHOW_ALL &&
				<>
					<Tooltip title={t(`${i18_PATH}Nascondi alcune colonne`)}>
						<Button
							endIcon={<Icon className="fa-light fa-eye" sx={{ overflow: "visible", marginRight: "4px", marginLeft: "-8px" }} />}
							onClick={onPartialHide}
						></Button>
					</Tooltip>
				</>
			}
			{showColumns === EShowColumnState.HIDE_PARTIAL &&
				<>
					<Tooltip title={t(`${i18_PATH}Nascondi tutte le colonne`)}>
						<Button
							endIcon={<Icon className="fa-light fa-eye-low-vision" sx={{ overflow: "visible", marginRight: "4px", marginLeft: "-8px" }} />}
							onClick={onHide}
						></Button>
					</Tooltip>
				</>
			}
			{showColumns === EShowColumnState.HIDE_ALL &&
				<>
					<Tooltip title={t(`${i18_PATH}Mostra tutte le colonne`)}>
						<Button
							endIcon={<Icon className="fa-light fa-eye-slash" sx={{ overflow: "visible", marginRight: "4px", marginLeft: "-8px" }} />}
							sx={{ endIcon: { margin: 0 } }}
							onClick={onShow}
						></Button>
					</Tooltip>
				</>
			}


		</ButtonGroup>
	)
}

export default ShowHideColumnsToolbar;
