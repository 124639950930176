import { createAction } from '@reduxjs/toolkit';
import {
	EMPTY,
	END_ACTION,
	IUICleanUploadProgressAction,
	IUIEndAction,
	IUIStartAction,
	IUIStartUploadProgressAction,
	IUIUpdateProgressUploadAction,
	Message,
	MESSAGE_ACTION,
	PROGRESS_UPLOAD_CLEAN_ACTION,
	PROGRESS_UPLOAD_START_ACTION,
	PROGRESS_UPLOAD_UPDATE_ACTION,
	START_ACTION
} from './types';

export const startActionToDoAction = createAction<IUIStartAction>(START_ACTION);

export const endActionToDoAction = createAction<IUIEndAction>(END_ACTION);

export const messageAction = createAction<Message>(MESSAGE_ACTION);

export const startUploadProgressAction = createAction<IUIStartUploadProgressAction>(PROGRESS_UPLOAD_START_ACTION);

export const updateUploadProgressAction = createAction<IUIUpdateProgressUploadAction>(PROGRESS_UPLOAD_UPDATE_ACTION);

export const cleanUploadProgressAction = createAction<IUICleanUploadProgressAction>(PROGRESS_UPLOAD_CLEAN_ACTION);

export const emptyAction = createAction(EMPTY);
