import { Box, Dialog, Icon, IconButton, Toolbar } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { useEffect, useState } from "react";
import { settings } from "../../../../../../customize/settings";
import Transition from "../../../../../../lib/dialogTransition";
import { getJWT } from "../../../../../../lib/jwt";
import { IImageDialogProps } from "./interfaces";

const IMAGE_URL = `${settings.apiUrl}image/`;

function ImageDialog({ cover, isOpen, close }: IImageDialogProps) {
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    const jwt = getJWT();
    if (cover) {
      const _imageUrl = `${IMAGE_URL}${cover.hdPath}?token=${jwt}`;
      setImageUrl(_imageUrl);
    }
  }, [cover]);
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={close}
      TransitionComponent={Transition}
    >
      <AppBar
        position="relative"
        color="transparent"
        sx={{ boxShadow: "none" }}
      >
        <Toolbar variant="dense">
          <Box sx={{ flex: 1 }} />
          <IconButton
            edge="end"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <Icon className="fa-light fa-xmark" sx={{ overflow: "visible" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          p: 1,
          boxSizing: "border-box",
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {!!cover && !!imageUrl && (
          <img height="80%" src={imageUrl} alt={cover.name} loading="lazy" />
        )}
      </Box>
    </Dialog>
  );
}

export default ImageDialog;
