import axios, { AxiosProgressEvent } from "axios";
import { settings } from "../../customize/settings";
import { IBaseError } from "../../interfaces/errors";
import { IQualitaImageUpload } from "../../interfaces/lotto/attributes/models";
import { IDeleteResponse, IStoreResponse } from "../../interfaces/rest";
import { getJWT } from "../../lib/jwt";

const BASE_URL = `${settings.apiUrl}qualitaImage`;

export const removeImage = async (id: number): Promise<IDeleteResponse> => {
  const url = `${BASE_URL}/${id}`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const response = await fetch(url, {
    method: "DELETE",
    headers: headers,
  });
  const json: IDeleteResponse | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IDeleteResponse;
};

export const uploadImage = async (
  data: IQualitaImageUpload,
  onUploadProgress: (progress: AxiosProgressEvent) => void = () => null
) => {
  const { idQualita, image } = data;
  const url = `${BASE_URL}`;
  const headers: Record<string, string> = {
    "Content-Type": "multipart/form-data",
  };
  const jwt = getJWT();
  if (jwt) headers["Authorization"] = "Bearer " + jwt;
  const formData = new FormData();
  formData.append("idQualita", String(idQualita));
  formData.append("image", image);
  const response = await axios.request({
    method: "post",
    url,
    headers,
    data: formData,
    onUploadProgress,
  });
  const json: IStoreResponse | IBaseError = response.data;
  if (response.status !== 200) throw json;
  return json as IStoreResponse;
};
