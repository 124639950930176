import { Box, Fade, Icon, IconButton, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { settings } from "../../../../../../../customize/settings";
import {
  getDettaglioByImage,
  getImageName,
} from "../../../../../../../lib/functions";
import { getJWT } from "../../../../../../../lib/jwt";
import { IImageItemParams } from "./interfaces";

const IMAGE_URL = `${settings.apiUrl}image/`;

function ImageItem({ image, lotto, height: h, zoom }: IImageItemParams) {
  const [displayAction, setDisplayAction] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [height, setHeight] = useState<number | undefined>();
  const [imageName, setImageName] = useState<string>("");

  useEffect(() => {
    const _height = !!h && h > 0 ? h - 60 : undefined;
    setHeight(_height);
  }, [h]);
  useEffect(() => {
    const jwt = getJWT();
    const _imageUrl = `${IMAGE_URL}${image.hdPath}?token=${jwt}`;
    setImageUrl(_imageUrl);
  }, [image]);
  useEffect(() => {
    const formulaLabel = settings.item?.images?.slabs?.label;
    if (!formulaLabel) return setImageName(image.name);
    const dettaglio = getDettaglioByImage(image, lotto.dettagli ?? []);
    if (!dettaglio) return setImageName(image.name);
    const name = getImageName(image, lotto, dettaglio, formulaLabel);
    if (!name) return setImageName(image.name);
    setImageName(name);
  }, [image, lotto]);

  if (!height) return null;

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Paper
        sx={{ height, position: "relative" }}
        onMouseEnter={() => setDisplayAction(true)}
        onMouseLeave={() => setDisplayAction(false)}
      >
        <img
          height={height}
          src={imageUrl}
          alt={image.fileName}
          loading="lazy"
        />
        <Fade in={displayAction}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              height: "100%",
              width: "100%",
              zIndex: 999,
              backgroundColor: "rgb(74 74 74 / 50%)",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                size="large"
                sx={{ fontSize: "4rem", color: "#fff" }}
                onClick={() => zoom(image)}
              >
                <Icon
                  className="fa-light fa-magnifying-glass-plus"
                  fontSize="inherit"
                  sx={{ overflow: "visible" }}
                />
              </IconButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 1,
              }}
            >
              <Typography variant="h5" color="#fff">
                {imageName}
              </Typography>
            </Box>
          </Box>
        </Fade>
      </Paper>
    </Box>
  );
}

export default ImageItem;
