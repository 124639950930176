import { ITranslations } from "../../intl/translations";

export const ENTranslations: ITranslations = {
  Lastre: "Slabs",
  Blocco: "Block",
  MQ: "M2",
  Grezzo: "Raw",
  Levigato: "Smooth",
  Lucidato: "Polished",
  Resinato: "Resin",
  Spazzolato: "Brushed",
  Opzionato: "Optioned",
  Impegnato: "Blocked",
  "Da Prezzare": "To Price",
  "Stand by": "Stand by",
  Libero: "Free",
  "In Lavorazione": "Under processing",
  main: {
    Prodotti: "Prodotti",
    Venduto: "Venduto",
    Materiali: "Materiali",
    Qualità: "Qualità",
    Prospects: "Prospects",
    Clienti: "Clienti",
  },
  navBar: {
    Prodotti: "Prodotti",
    Venduto: "Venduto",
    Materiali: "Materiali",
    Qualità: "Qualità",
    Prospects: "Prospects",
    Clienti: "Clienti",
    Utenti: "Utenti",
    "Gruppi Utenti": "Gruppi Utenti",
    Esci: "Esci",
  },
  materiale: {
    list: {
      columns: {
        Nome: "Nome",
        Descrizione: "Descrizione",
      },
    },
    update: {
      Nome: "Nome",
      Descrizione: "Descrizione",
      NoImmagine: "Nessuna immagine",
      Cancella: "Cancella",
      Salva: "Salva",
      Upload: "Upload",
      UploadInfo:
        "Trascina qua le tue immagini o clicca per selezionarle dal tuo archivio",
    },
  },
  qualita: {
    list: {
      columns: {
        Nome: "Nome",
        Materiale: "Materiale",
        Colore: "Colore",
        Utilizzo: "Utilizzo",
        Finiture: "Finiture",
        Densita: "Densita",
        ResistenzaCompressione: "ResistenzaCompressione",
        ResistenzaFlessione: "ResistenzaFlessione",
        CoefImbibizione: "CoefImbibizione",
        Descrizione: "Descrizione",
      },
    },
    update: {
      Nome: "Nome",
      Materiale: "Materiale",
      Colore: "Colore",
      Utilizzo: "Utilizzo",
      Finiture: "Finiture",
      Densita: "Densita",
      ResistenzaCompressione: "ResistenzaCompressione",
      ResistenzaFlessione: "ResistenzaFlessione",
      CoefImbibizione: "CoefImbibizione",
      Descrizione: "Descrizione",
      NoImmagine: "Nessuna immagine",
      Cancella: "Cancella",
      Salva: "Salva",
      Upload: "Upload",
      UploadInfo:
        "Trascina qua le tue immagini o clicca per selezionarle dal tuo archivio",
    },
  },
  prodotto: {
    view: {
      lottoMain: {
        Informazioni: "Information",
        "Modifica informazioni": "Edit information",
        Codice: "Code",
        Cava: "Quarry",
        Materiale: "Material",
        Categoria: "Category",
        Venatura: "Grain",
        Qualità: "Quality",
        Fondo: "Background",
        Uso: "Use",
        "Blocco da segare": "Block to be sawn",
        "Quantità iniziale": "Initial quantity",
        "Quantità disponibile": "Available quantity",
        "Pezzi iniziali": "Initial items",
        "Pezzi disponibili": "Available items",
        "Nessuna Immagine": "No image",
      },
      lottoImages: {
        "Immagini lastre": "Slabs images",
        "Immagini blocco": "Block images",
        "Modifica immagini": "Edit images",
        "Carica nuove immagini": "Upload new images",
        "Esci dalla modifica immagini": "Exit from edit images",
        thumbnail: {
          "Imposta come immagine di copertina": "Set as cover image",
          "Immagine di copertina": "Cover image",
          Modifica: "Edit",
          Elimina: "Delete",
        },
        dialog: {
          coverDialog: {
            "Imposta questa immagine come copertina":
              "Set this image as cover image",
            "Questa immagine sarà impostata come immagine principale del lotto, continuare?":
              "This image will be set as the main image of the lot, do you want to continue?",
            Cancella: "Cancel",
            Salva: "Save",
          },
          deleteDialog: {
            "Elimina immagine": "Delete image",
            "Sei sicuro di elimiare questa immagine?":
              "Are you sure you want to delete this image?",
            Cancella: "Cancel",
            Elimina: "Delete",
          },
          updateDialog: {
            "Campo obbligatorio": "Required field",
            "Modifica immagine": "Edit image",
            Cancella: "Cancel",
            Salva: "Save",
          },
          uploadDialog: {
            "Trascina qua le tue immagini o clicca per selezionarle dal tuo archivio":
              "Drop your images here or click to select them from your archive",
            Cancella: "Cancel",
            Upload: "Upload",
          },
        },
      },
      lottoDettagli: {
        Dettaglio: "Detail",
        showHideColumnsToolbar: {
          "Mostra tutte le colonne": "Mostra tutte le colonne",
          "Nascondi tutte le colonne": "Nascondi tutte le colonne",
          "Nascondi alcune colonne": "Nascondi alcune colonne",
        },
        dettagliSelectViewToolbar: {
          "In magazzino": "In stock",
          "Storia del lotto": "Lot history",
        },
        tableCells: {
          "Non venduto": "Not sold",
          Venduto: "Sold",
        },
        grids: {
          defaultGridColumns: {
            Tipo: "Type",
            Magazzino: "Warehouse",
            Zona: "Area",
            Fila: "Row",
            Bundle: "Bundle",
            Da: "From",
            A: "To",
            Pezzi: "Items",
            Lun: "L",
            Alt: "H",
            Spes: "D",
            Quantità: "Quantity",
            Costo: "Cost",
            Prezzo: "Price",
            Lavorazione: "Processing",
            Disponibilita: "Availability",
            Cliente: "Customer",
            Agente: "Agent",
            Note: "Note",
          },
          historyGridColumns: {
            Tipo: "Type",
            Magazzino: "Warehouse",
            Zona: "Area",
            Fila: "Row",
            Bundle: "Bundle",
            Da: "From",
            A: "To",
            Pezzi: "Items",
            Lun: "L",
            Alt: "H",
            Spes: "D",
            Quantità: "Quantity",
            Costo: "Cost",
            Prezzo: "Price",
            Lavorazione: "Processing",
            Venduto: "Sold",
          },
          gridFooter: {
            Pezzi: "Items",
            "Prezzo al MQ": "Price per m2",
            Totale: "Total",
            "Costo totale": "Total cost",
            Condividi: "Share",
            Opziona: "Option",
            "Packing List": "Packing List",
            Dividi: "Split",
            Modifica: "Edit",
          },
          packingListGridColumns: {
            Lotto: "Lot",
            Bundle: "Bundle",
            Da: "From",
            A: "To",
            Pezzi: "Items",
            Lun: "L",
            Alt: "H",
            Spes: "D",
            Quantità: "Quantity",
            Costo: "Cost",
            Prezzo: "Price",
            Lavorazione: "Processing",
          },
        },
      },
    },
  },
};
