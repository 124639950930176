import {
  Collapse,
  Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useMemo, useState } from "react";
import { EOptionType } from "../../../../../../../interfaces/enums";
import { IZona } from "../../../../../../../interfaces/place/models";
import { isFilterAndValueSelected } from "../../../../../../../lib/filters";
import { useAppSelector } from "../../../../../../../store/hooks";
import { IFilterProps } from "../../interfaces";

function ZoneFilter({ filters, setFilters }: IFilterProps) {
  const [open, setOpen] = useState<boolean>(false);

  const zonaList = useAppSelector((state) => state.place_zona.list);
  const zonaItems = useAppSelector((state) => state.place_zona.items);
  const dettaglioItems = useAppSelector((state) => state.dettaglio.items || {});

  const list: IZona[] = useMemo(() => {
    const idMagazzino = filters.find(
      (f) => f.type === EOptionType.magazzino && !!f.numericValue
    )?.numericValue as number;
    const _zone: IZona[] =
      !!zonaList && !!zonaItems ? zonaList.map((id) => zonaItems[id]) : [];
    const selectableZoneIds: number[] = Object.values(dettaglioItems)
      .filter((item) => item.idMagazzino === idMagazzino)
      .map((item) => item.idZona || 0)
      .filter(Boolean);
    return _zone.filter((zona) => selectableZoneIds.includes(zona.id));
  }, [zonaList, zonaItems, dettaglioItems, filters]);

  const toggle = () => {
    setOpen(!open);
  };

  const toggleSelected = (item: IZona) => {
    if (isFilterAndValueSelected(filters, EOptionType.zona, item.id)) {
      setFilters(
        filters.filter(
          (opt) =>
            opt.type !== EOptionType.zona && opt.type !== EOptionType.fila
        )
      );
    } else {
      setFilters([
        ...filters.filter(
          (opt) =>
            opt.type !== EOptionType.zona && opt.type !== EOptionType.fila
        ),
        {
          type: EOptionType.zona,
          numericValue: item.id,
          stringValue: item.nome,
        },
      ]);
    }
  };

  return (
    <>
      <ListItemButton onClick={toggle}>
        <ListItemIcon>
          <Icon className="fa-light fa-map-pin" sx={{ overflow: "visible" }} />
        </ListItemIcon>
        <ListItemText primary="Zone" />
        {open ? (
          <Icon
            className="fa-light fa-angle-up"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        ) : (
          <Icon
            className="fa-light fa-angle-down"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense={true}>
          {list.map((item) => (
            <ListItemButton
              key={item.id}
              sx={{ display: "flex", pl: 5, pr: 1 }}
              onClick={() => toggleSelected(item)}
              selected={isFilterAndValueSelected(
                filters,
                EOptionType.zona,
                item.id
              )}
            >
              <ListItemText sx={{ flex: 1 }} primary={item.nome} />
              <ListItemIcon sx={{ minWidth: 0 }}>
                {isFilterAndValueSelected(
                  filters,
                  EOptionType.zona,
                  item.id
                ) && (
                  <Icon
                    className="fa-light fa-check"
                    sx={{ overflow: "visible" }}
                  />
                )}
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
}

export default ZoneFilter;
