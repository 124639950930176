import { Icon, Tooltip } from "@mui/material";
import { GridActionsCellItem, GridAlignment, GridColumns, GridRowParams, GridValueGetterParams } from "@mui/x-data-grid";
import { ICategoria, IFondo, IMateriale, IQualita, IUso, IVenatura } from "../../../../../interfaces/lotto/attributes/models";
import { IProspect } from "../../../../../interfaces/prospect/models";
import { ICreateColumnsPros } from "./interfaces";

export const createColumns = ({ onEdit, onRemove }: ICreateColumnsPros): GridColumns<IProspect> => [
	{
		field: 'actions',
		type: 'actions',
		align: 'left' as GridAlignment,
		hideable: false,
		maxWidth: 100,
		getActions: (params: GridRowParams<IProspect>) => [
			<GridActionsCellItem
				icon={<Tooltip title="Modifica"><Icon className="fa-light fa-pen" sx={{ overflow: "visible" }} /></Tooltip>}
				label="Edit"
				onClick={() => onEdit(params.id as number)}
			/>,
			<GridActionsCellItem
				icon={<Tooltip title="Elimina"><Icon className="fa-light fa-trash-can" sx={{ overflow: "visible" }} /></Tooltip>}
				label="Delete"
				onClick={() => onRemove(params.id as number)}
			/>

		],
	},
	{
		field: 'cliente',
		headerName: 'Cliente',
		width: 160
	},
	{
		field: 'societa',
		headerName: 'Società',
		width: 160
	},
	{
		field: 'societ1',
		headerName: 'Società 1',
		width: 160
	},
	{
		field: 'stato',
		headerName: 'Stato',
		width: 160
	},
	{
		field: 'paese',
		headerName: 'Paese',
		width: 160
	},
	{
		field: 'citta',
		headerName: 'Città',
		width: 160
	},
	{
		field: 'telefono',
		headerName: 'Telefono',
		width: 160
	},
	{
		field: 'website',
		headerName: 'Website',
		width: 160
	},
	{
		field: 'contatto1',
		headerName: 'Contatto 1',
		width: 160
	},
	{
		field: 'telefono1',
		headerName: 'Telefono 1',
		width: 160
	},
	{
		field: 'email1',
		headerName: 'Email 1',
		width: 160
	},
	{
		field: 'contatto2',
		headerName: 'Contatto 2',
		width: 160
	},
	{
		field: 'telefono2',
		headerName: 'Telefono 2',
		width: 160
	},
	{
		field: 'email2',
		headerName: 'Email 2',
		width: 160
	},
	{
		field: 'contatto3',
		headerName: 'Contatto 3',
		width: 160
	},
	{
		field: 'telefono3',
		headerName: 'Telefono 3',
		width: 160
	},
	{
		field: 'email3',
		headerName: 'Email 3',
		width: 160
	},
	{
		field: 'agente',
		headerName: 'Agente',
		width: 160
	},
	{
		field: 'responsabilegemeg',
		headerName: 'Responsabile',
		width: 160
	},
	{
		field: 'materiale',
		headerName: 'Materiale',
		valueGetter: (params: GridValueGetterParams<IMateriale | null>) => params.value?.nome,
		width: 160
	},
	{
		field: 'qualita',
		headerName: 'Qualità',
		valueGetter: (params: GridValueGetterParams<IQualita | null>) => params.value?.nome,
		width: 160
	},
	{
		field: 'categoria',
		headerName: 'Categoria',
		valueGetter: (params: GridValueGetterParams<ICategoria | null>) => params.value?.nome,
		width: 160
	},
	{
		field: 'fondo',
		headerName: 'Fondo',
		valueGetter: (params: GridValueGetterParams<IFondo | null>) => params.value?.nome,
		width: 160
	},
	{
		field: 'venatura',
		headerName: 'Venatura',
		valueGetter: (params: GridValueGetterParams<IVenatura | null>) => params.value?.nome,
		width: 160
	},
	{
		field: 'uso',
		headerName: 'Uso',
		valueGetter: (params: GridValueGetterParams<IUso | null>) => params.value?.nome,
		width: 160
	},
	{
		field: 'notaprofilazione',
		headerName: 'Nota',
		width: 360
	},
];