import { configureStore } from "@reduxjs/toolkit";
import auth from "./auth/reducers";
import cliente from "./cliente/reducers";
import dettaglio_tipoLotto from "./dettaglio/attributes/tipoLotto/reducers";
import dettaglio_disponibilita from "./dettaglio/attributes/disponibilita/reducers";
import dettaglio_stato_lavorazione from "./dettaglio/attributes/statoLavorazione/reducers";
import dettaglio from "./dettaglio/dettaglio/reducers";
import lotto_categoria from "./lotto/attributes/categoria/reducers";
import lotto_fondo from "./lotto/attributes/fondo/reducers";
import lotto_materiale from "./lotto/attributes/materiale/reducers";
import lotto_qualita from "./lotto/attributes/qualita/reducers";
import lotto_uso from "./lotto/attributes/uso/reducers";
import lotto_venatura from "./lotto/attributes/venatura/reducers";
import lotto from "./lotto/lotto/reducers";
import place_cava from "./place/cava/reducers";
import place_magazzino from "./place/magazzino/reducers";
import place_zona from "./place/zona/reducers";
import place_fila from "./place/fila/reducers";
import prospect from "./prospect/reducers";
import ui from "./ui/reducers";
import groupPermissions from "./user/groupPermissions/reducers";
import user from "./user/user/reducers";
import userGroup from "./user/userGroup/reducers";

export const store = configureStore({
  reducer: {
    auth,
    cliente,
    dettaglio,
    dettaglio_tipoLotto,
    dettaglio_disponibilita,
    dettaglio_stato_lavorazione,
    groupPermissions,
    lotto,
    lotto_categoria,
    lotto_fondo,
    lotto_materiale,
    lotto_qualita,
    lotto_uso,
    lotto_venatura,
    place_cava,
    place_magazzino,
    place_zona,
    place_fila,
    prospect,
    ui,
    user,
    userGroup,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
