import { settings } from "../customize/settings";
import { IDettaglio } from "../interfaces/dettaglio/models";

type DettaglioKey = string;

const generateKeyPlaceholder = (
  key: keyof IDettaglio,
  dettaglio: IDettaglio
): string => `${key}${dettaglio[key]}`;

const createDettaglioGroupKey = (dettaglio: IDettaglio): DettaglioKey => {
  const extraGroupingKeys = settings.list.grid?.groupingKeys ?? [];
  const groupingKeys = extraGroupingKeys.map((key) =>
    generateKeyPlaceholder(key, dettaglio)
  );
  if (!groupingKeys.length) return `id${dettaglio.id}`;
  return groupingKeys.join(";");
};

const mergeDettagli = (
  oldValue: IDettaglio,
  newValue: IDettaglio
): IDettaglio => {
  const pezzi = oldValue.pezzi + newValue.pezzi;
  const quantitaUM = oldValue.quantitaUM + newValue.quantitaUM;
  const mc = oldValue.mc + newValue.mc;
  const mq = oldValue.mq + newValue.mq;
  const kg = oldValue.kg + newValue.kg;
  const prezzo =
    (oldValue.prezzo * oldValue.quantitaUM +
      newValue.prezzo * newValue.quantitaUM) /
    quantitaUM;
  return {
    ...oldValue,
    pezzi,
    quantitaUM,
    mc,
    mq,
    kg,
    prezzo,
  };
};

export const dettaglioGrouping = (
  dettaglioList: IDettaglio[]
): IDettaglio[] => {
  const dettaglioMap: Record<DettaglioKey, IDettaglio> = {};
  for (const dettaglio of dettaglioList) {
    const dettaglioKey = createDettaglioGroupKey(dettaglio);
    if (!!dettaglioMap[dettaglioKey])
      dettaglioMap[dettaglioKey] = mergeDettagli(
        dettaglioMap[dettaglioKey],
        dettaglio
      );
    else dettaglioMap[dettaglioKey] = dettaglio;
  }
  return Object.values(dettaglioMap);
};
