import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Icon,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ILottoImage } from "../../../../../interfaces/lotto/models";
import { useAppSelector } from "../../../../../store/hooks";
import CoverDialog from "./dialogs/cover/coverDialog";
import DeleteDialog from "./dialogs/delete/deleteDialog";
import UpdateDialog from "./dialogs/update/updateDialog";
import UploadDialog from "./dialogs/upload/uploadDialog";
import { ILottoImagesProps } from "./interfaces";
import Thumbnail from "./thumbnail";

const i18_PATH = "prodotto.view.lottoImages.";

function DettaglioLottoImages({
  lotto,
  user,
  images,
  isBlock,
  viewImage,
  setIsEditMode,
  isEditMode,
}: ILottoImagesProps) {
  const { t } = useTranslation();
  const lastActionDone = useAppSelector((state) => state.ui.lastActionDone);
  const [selectedImage, setSelectedImage] = useState<ILottoImage | null>(null);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState<boolean>(false);
  const [isCoverOpen, setIsCoverOpen] = useState<boolean>(false);
  const [isUploadOpen, setIsUploadOpen] = useState<boolean>(false);
  const [canSetCommercials, setCanSetCommercials] = useState<boolean>(false);

  const onEnterUpdateMode = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsEditMode(true);
  };
  const onExitUpdateMode = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsEditMode(false);
  };

  const onOpenUploadDialog = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsUploadOpen(true);
  };
  const openDelete = (image: ILottoImage) => {
    setSelectedImage(image);
    setIsDeleteOpen(true);
  };
  const closeDelete = () => {
    setSelectedImage(null);
    setIsDeleteOpen(false);
  };
  const openUpdate = (image: ILottoImage) => {
    setSelectedImage(image);
    setIsUpdateOpen(true);
  };
  const closeUpdate = () => {
    setSelectedImage(null);
    setIsUpdateOpen(false);
  };
  const openCover = (image: ILottoImage) => {
    setSelectedImage(image);
    setIsCoverOpen(true);
  };
  const closeCover = () => {
    setSelectedImage(null);
    setIsCoverOpen(false);
  };
  const closeUpload = () => {
    setIsUploadOpen(false);
  };
  const openImage = (image: ILottoImage) => {
    viewImage(image, isBlock);
  };
  useEffect(() => {
    const _canSetCommercials =
      !!user?.admin || !!user?.user_group?.group_permissions?.canSetCommercials;
    setCanSetCommercials(_canSetCommercials);
  }, [user]);
  useEffect(() => {
    if (!lastActionDone) return;
    if (
      [
        "update-image",
        "set-cover-image",
        "remove-immagine",
        "upload-immagine",
        "upload-immagini",
      ].includes(lastActionDone)
    ) {
      setSelectedImage(null);
      setIsUpdateOpen(false);
      setIsCoverOpen(false);
      setIsDeleteOpen(false);
      setIsUploadOpen(false);
    }
  }, [lastActionDone]);
  return (
    <>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={
            <Icon
              className="fa-light fa-angle-down"
              fontSize="small"
              sx={{ overflow: "visible" }}
            />
          }
          aria-controls="qualita-content"
          id="qualita-header"
        >
          {!isBlock && (
            <Typography variant="subtitle1" sx={{ flex: 1 }}>
              {t(`${i18_PATH}Immagini lastre`)}
            </Typography>
          )}
          {!!isBlock && (
            <Typography variant="subtitle1" sx={{ flex: 1 }}>
              {t(`${i18_PATH}Immagini blocco`)}
            </Typography>
          )}
          {canSetCommercials && (
            <>
              {!isEditMode && (
                <Box sx={{ mr: 2 }}>
                  <Tooltip title={t(`${i18_PATH}Modifica immagini`)}>
                    <IconButton onClick={onEnterUpdateMode}>
                      <Icon
                        className="fa-light fa-pen-circle"
                        sx={{ overflow: "visible" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              {isEditMode && (
                <Box sx={{ mr: 2 }}>
                  <Tooltip title={t(`${i18_PATH}Carica nuove immagini`)}>
                    <IconButton onClick={onOpenUploadDialog}>
                      <Icon
                        className="fa-light fa-plus-circle"
                        sx={{ overflow: "visible" }}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t(`${i18_PATH}Esci dalla modifica immagini`)}>
                    <IconButton onClick={onExitUpdateMode}>
                      <Icon
                        className="fa-light fa-circle-xmark"
                        sx={{ overflow: "visible" }}
                      />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {images.map((image, index) => (
              <Grid item xs={3} key={index}>
                <Thumbnail
                  image={image}
                  lotto={lotto}
                  isEditMode={isEditMode}
                  onOpen={openImage}
                  onDelete={openDelete}
                  onUpdate={openUpdate}
                  onCoverChange={openCover}
                />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <DeleteDialog
        isOpen={isDeleteOpen}
        image={selectedImage}
        close={closeDelete}
      />
      <UpdateDialog
        isOpen={isUpdateOpen}
        image={selectedImage}
        isBlock={isBlock}
        close={closeUpdate}
      />
      <CoverDialog
        isOpen={isCoverOpen}
        image={selectedImage}
        close={closeCover}
      />
      <UploadDialog
        isOpen={isUploadOpen}
        idLotto={lotto.id}
        isBlock={isBlock}
        close={closeUpload}
      />
    </>
  );
}

export default DettaglioLottoImages;
