import { Box } from "@mui/material";
import {
  DataGrid,
  GridColumns,
  GridColumnVisibilityModel,
} from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { settings } from "../../../../../../customize/settings";
import { IDettaglio } from "../../../../../../interfaces/dettaglio/models";
import PackingListToolbar from "../dialogs/packingList/packingListToolbar";
import { EShowColumnState } from "../interfaces";
import { getRowBundleClassName, hideColumns } from "./functions";
import { IPackingListGridProps } from "./interfaces";
import { createPackingListGridColumns } from "./packingListGridColumns";

function PackingListGrid({
  packingList,
  canViewCosts,
  canViewCommercials,
  canViewStoragePlace,
  canViewCommercialOptions,
  showAdminColumns,
}: IPackingListGridProps) {
  const { t } = useTranslation();
  const [displayColumns, setDysplayColumns] = useState<GridColumns<IDettaglio>>(
    []
  );
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>(
      settings.item?.grid?.initPackingListColumnsVisibility ?? {}
    );
  const columns: GridColumns<IDettaglio> = useMemo(
    () =>
      createPackingListGridColumns({
        t,
        canViewCosts,
        canViewCommercials,
        canViewStoragePlace,
        canViewCommercialOptions,
      }),
    [
      t,
      canViewCosts,
      canViewCommercials,
      canViewStoragePlace,
      canViewCommercialOptions,
    ]
  );
  useEffect(() => {
    let columnsToHide: string[] = [];
    if (showAdminColumns === EShowColumnState.HIDE_ALL)
      columnsToHide = [
        "costo",
        "um_costo",
        "magazzino",
        "zona",
        "fila",
        "opzStato",
        "opzCliente",
        "agente",
        "opzNote",
        "prezzo",
      ];
    if (showAdminColumns === EShowColumnState.HIDE_PARTIAL)
      columnsToHide = [
        "costo",
        "um_costo",
        "magazzino",
        "zona",
        "fila",
        "opzStato",
        "opzCliente",
        "agente",
        "opzNote",
      ];
    const _displayColumns = !columnsToHide.length
      ? columns
      : hideColumns(columns, columnsToHide);
    setDysplayColumns(_displayColumns);
  }, [columns, showAdminColumns]);
  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: "#FFF",
        "& .bundle-row--even": {
          bgcolor: "#f5f9ff",
        },
        "& .bundle-row--odd": {
          bgcolor: "#fff5f5",
        },
      }}
    >
      <DataGrid
        columns={displayColumns}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        rows={packingList}
        components={{
          Toolbar: PackingListToolbar,
        }}
        disableSelectionOnClick={true}
        checkboxSelection={false}
        getRowClassName={getRowBundleClassName}
      />
    </Box>
  );
}

export default PackingListGrid;
