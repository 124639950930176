import { createAction } from '@reduxjs/toolkit';
import { ITipoLotto } from '../../../../interfaces/dettaglio/attributes/models';
import { EMPTY, GET, ITipoLottoState, LIST } from './types';

export const listAction = createAction<ITipoLottoState>(LIST);

export const getAction = createAction<ITipoLotto>(GET);

export const emptyAction = createAction(EMPTY);

