import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import logo from "../../customize/assets/logo2.png";
import { useLogin, useLogout } from "../../effects/auth";
import { LoginFormData } from "../../interfaces/auth/forms";
import { useAppSelector } from "../../store/hooks";

function PageLogin() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [formData, setFormData] = useState<LoginFormData>(null);
  const error = useAppSelector((state) => state.auth.error);
  const isLogged = useAppSelector((state) => state.auth.isLogged);
  useLogout();
  useLogin(formData);
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const handleSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setFormData({ email, password });
  };
  if (isLogged === true) {
    return <Navigate to="/" />;
  }
  return (
    <Container
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card sx={{ p: 1 }}>
        <CardContent sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ m: 2 }}>
            <img src={logo} alt="logo" height={200} />
          </Box>
          <Box
            component="form"
            sx={{ display: "flex", flexDirection: "column" }}
            onSubmit={handleSubmit}
          >
            <Typography variant="subtitle1" sx={{ textAlign: "center" }}>
              LOGIN
            </Typography>
            <TextField
              error={!!error?.email}
              helperText={error?.email?.join()}
              margin="dense"
              sx={{ width: "30ch" }}
              label="Email"
              type="string"
              value={email}
              onChange={handleEmailChange}
            />
            <TextField
              error={!!error?.password}
              helperText={error?.password?.join()}
              margin="dense"
              sx={{ width: "30ch" }}
              label="Password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
            />
            <Button type="submit" variant="contained">
              Entra
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Container>
  );
}

export default PageLogin;
