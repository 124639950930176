import { List, ListSubheader } from "@mui/material";
import { IFilterProps } from "../../interfaces";
import StatoLavorazioneFilter from "./lavorazioni";

function LavorazioniFiltersContainer({ filters, setFilters }: IFilterProps) {
  return (
    <List
      dense={true}
      sx={{ p: 0 }}
      component="nav"
      subheader={<ListSubheader component="div">Lavorazioni</ListSubheader>}
    >
      <StatoLavorazioneFilter filters={filters} setFilters={setFilters} />
    </List>
  );
}

export default LavorazioniFiltersContainer;
