import { settings } from "../../../customize/settings";
import { IBaseError } from "../../../interfaces/errors";
import { MaterialeUpdateFormData } from "../../../interfaces/lotto/attributes/forms";
import {
  IMateriale,
  IMaterialeExt,
} from "../../../interfaces/lotto/attributes/models";
import { IListResponse, IUpdateResponse } from "../../../interfaces/rest";
import { getJWT } from "../../../lib/jwt";

const BASE_URL = `${settings.apiUrl}materiale`;

export const getMateriale = async (id: number): Promise<IMaterialeExt> => {
  const url = `${BASE_URL}/${id}`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const json: IMateriale | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IMaterialeExt;
};

export const getMaterialeList = async (): Promise<
  IListResponse<IMateriale>
> => {
  const url = `${BASE_URL}/list`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const json: IListResponse<IMateriale> | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IListResponse<IMateriale>;
};

export const getMaterialeExtList = async (): Promise<
  IListResponse<IMaterialeExt>
> => {
  const url = `${BASE_URL}/listExt`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const json: IListResponse<IMaterialeExt> | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IListResponse<IMaterialeExt>;
};

export const updateMateriale = async (
  id: number,
  data: MaterialeUpdateFormData
): Promise<IUpdateResponse> => {
  const url = `${BASE_URL}/${id}`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const body = JSON.stringify(data);
  const response = await fetch(url, {
    method: "PUT",
    headers: headers,
    body,
  });
  const json: IUpdateResponse | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IUpdateResponse;
};
