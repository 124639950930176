import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  Icon,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import { ElementRef, SyntheticEvent, useEffect, useRef, useState } from "react";
import { settings } from "../../../../../../../customize/settings";
import Transition from "../../../../../../../lib/dialogTransition";
import { IUpdateDialogProps } from "../interfaces";
import CommercialsForm from "./commercialsForm";
import MeasuresForm from "./measuresForm";
import NotesForm from "./notesForm";
import ProcessingStatusForm from "./processingStatusForm";
import StorageForm from "./storageForm";

type MeasuresFormRef = ElementRef<typeof MeasuresForm>;
type CommercialsFormRef = ElementRef<typeof CommercialsForm>;
type StorageFormRef = ElementRef<typeof StorageForm>;
type ProcessingStatusFormRef = ElementRef<typeof ProcessingStatusForm>;
type NotesFormRef = ElementRef<typeof NotesForm>;

const createTabList = (): string[] => {
  const list = [];
  if (!settings.item?.grid?.actions?.commercial?.sections?.measures?.hidden)
    list.push("measures");
  if (!settings.item?.grid?.actions?.commercial?.sections?.commercial?.hidden)
    list.push("commercial");
  if (!settings.item?.grid?.actions?.commercial?.sections?.storage?.hidden)
    list.push("storage");
  if (!settings.item?.grid?.actions?.commercial?.sections?.processing?.hidden)
    list.push("processing");
  if (!settings.item?.grid?.actions?.commercial?.sections?.notes?.hidden)
    list.push("notes");
  return list;
};

function DettaglioUpdateDialog({
  isOpen,
  lottoId,
  selectedItems,
  canSetCommercials,
  canSetCommercialOptions,
  canSetStoragePlace,
  canSetFeatures,
  onClose,
}: IUpdateDialogProps) {
  const measuresFormRef = useRef<MeasuresFormRef>(null);
  const commercialFormRef = useRef<CommercialsFormRef>(null);
  const storageFormRef = useRef<StorageFormRef>(null);
  const processingStatusFormRef = useRef<ProcessingStatusFormRef>(null);
  const notesFormRef = useRef<NotesFormRef>(null);
  const [title, setTitle] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [tabList] = useState<string[]>(createTabList());
  const handleTabChange = (_: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const save = () => {
    measuresFormRef?.current?.onSubmit();
    commercialFormRef?.current?.onSubmit();
    storageFormRef?.current?.onSubmit();
    processingStatusFormRef?.current?.onSubmit();
    notesFormRef?.current?.onSubmit();
  };
  const close = () => {
    onClose();
  };
  useEffect(() => {
    if (selectedItems.length === 1) {
      setTitle("Modifica il dettaglio selezionato");
    } else if (selectedItems.length > 1) {
      setTitle("Modifica tutti i dettagli selezionati");
    } else {
      setTitle("Nessun dettaglio selezionato");
    }
    setSelectedTab(0);
  }, [selectedItems]);
  return (
    <Dialog
      open={isOpen}
      onClose={close}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar variant="dense">
          <Typography variant="h5" sx={{ flex: 1 }} display="block">
            {title}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <Icon className="fa-light fa-xmark" sx={{ overflow: "visible" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2, overflowY: "auto" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="update-tabs"
          >
            {canSetCommercials &&
              !settings.item?.grid?.actions?.commercial?.sections?.measures
                ?.hidden && (
                <Tab
                  label="Misure"
                  id="tab-measures"
                  aria-controls="tab-measures"
                />
              )}
            {canSetCommercials &&
              !settings.item?.grid?.actions?.commercial?.sections?.commercial
                ?.hidden && (
                <Tab
                  label="Informazioni commerciali"
                  id="tab-commercial"
                  aria-controls="tab-commercial"
                />
              )}
            {canSetStoragePlace &&
              !settings.item?.grid?.actions?.commercial?.sections?.storage
                ?.hidden && (
                <Tab
                  label="Posizione"
                  id="tab-storage"
                  aria-controls="tab-storage"
                />
              )}
            {canSetFeatures &&
              !settings.item?.grid?.actions?.commercial?.sections?.processing
                ?.hidden && (
                <Tab
                  label="Lavorazione"
                  id="tab-processing"
                  aria-controls="tab-processing"
                />
              )}
            {canSetCommercialOptions &&
              !settings.item?.grid?.actions?.commercial?.sections?.notes
                ?.hidden && (
                <Tab label="Note" id="tab-notes" aria-controls="tab-notes" />
              )}
          </Tabs>
        </Box>
        {canSetCommercials &&
          !settings.item?.grid?.actions?.commercial?.sections?.measures
            ?.hidden && (
            <MeasuresForm
              hidden={selectedTab !== tabList.indexOf("measures")}
              lottoId={lottoId}
              selectedItems={selectedItems}
              ref={measuresFormRef}
            ></MeasuresForm>
          )}
        {canSetCommercials &&
          !settings.item?.grid?.actions?.commercial?.sections?.commercial
            ?.hidden && (
            <CommercialsForm
              hidden={selectedTab !== tabList.indexOf("commercial")}
              lottoId={lottoId}
              selectedItems={selectedItems}
              ref={commercialFormRef}
            ></CommercialsForm>
          )}
        {canSetStoragePlace && (
          <StorageForm
            hidden={selectedTab !== tabList.indexOf("storage")}
            lottoId={lottoId}
            selectedItems={selectedItems}
            ref={storageFormRef}
          ></StorageForm>
        )}
        {canSetFeatures &&
          !settings.item?.grid?.actions?.commercial?.sections?.processing
            ?.hidden && (
            <ProcessingStatusForm
              hidden={selectedTab !== tabList.indexOf("processing")}
              lottoId={lottoId}
              selectedItems={selectedItems}
              ref={processingStatusFormRef}
            ></ProcessingStatusForm>
          )}
        {canSetCommercialOptions &&
          !settings.item?.grid?.actions?.commercial?.sections?.notes
            ?.hidden && (
            <NotesForm
              hidden={selectedTab !== tabList.indexOf("notes")}
              lottoId={lottoId}
              selectedItems={selectedItems}
              ref={notesFormRef}
            ></NotesForm>
          )}
      </Box>
      <DialogActions>
        <Button onClick={close}>Cancella</Button>
        <Button onClick={save} variant="contained" color="primary">
          Salva
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DettaglioUpdateDialog;
