import { createAction } from "@reduxjs/toolkit";
import { IMaterialeExt } from "../../../../interfaces/lotto/attributes/models";
import {
  EMPTY,
  GET,
  IMaterialeExtListPayload,
  IMaterialeListPayload,
  LIST,
  LIST_EXT,
} from "./types";

export const listAction = createAction<IMaterialeListPayload>(LIST);

export const listExtAction = createAction<IMaterialeExtListPayload>(LIST_EXT);

export const getAction = createAction<IMaterialeExt>(GET);

export const emptyAction = createAction(EMPTY);
