import { List, ListSubheader } from "@mui/material";
import { IFilterProps } from "../../interfaces";
import AltreInformazioniBloccoFilter from "./blocco";

function AltreInformazioniFiltersContainer({
  filters,
  setFilters,
}: IFilterProps) {
  return (
    <List
      dense={true}
      sx={{ p: 0 }}
      component="nav"
      subheader={
        <ListSubheader component="div">Altre informazioni</ListSubheader>
      }
    >
      <AltreInformazioniBloccoFilter
        filters={filters}
        setFilters={setFilters}
      />
    </List>
  );
}

export default AltreInformazioniFiltersContainer;
