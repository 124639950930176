export const normalizer = <K extends string | number, T>(list: T[], property: keyof T): { list: K[], items: Record<K, T> } => {
	const _list: K[] = [];
	const _items: Record<K, T> = {} as Record<K, T>;

	list.forEach(item => {
		const key = item[property] as unknown as K
		_list.push(key);
		_items[key] = item;
	})
	return {
		list: _list,
		items: _items
	};
}

export const normalizerTransform = <K extends string | number, T extends { id: K }, Z extends { id: K }>(list: T[], transform: (item: T) => Z): { list: K[], items: Record<K, Z> } => {
	const _list: K[] = [];
	const _items: Record<K, Z> = {} as Record<K, Z>;

	list.forEach(item => {
		_list.push(item.id);
		_items[item.id] = transform(item);
	})
	return {
		list: _list,
		items: _items
	};
}