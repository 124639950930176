import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import React from 'react';
import { ITableToolbarProps } from './interfaces';

function TableToolbar({ onAdd }: ITableToolbarProps) {
	return (
		<GridToolbarContainer>
			<GridToolbarExport csvOptions={{ fileName: "clienti", delimiter: ';' }} />
		</GridToolbarContainer>
	);
}

export default TableToolbar;