import {
  Box,
  Card,
  CardActionArea,
  CardMedia,
  Icon,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { settings } from "../../../../../customize/settings";
import { ILottoImage } from "../../../../../interfaces/lotto/models";
import { getJWT } from "../../../../../lib/jwt";
import {
  ICellImagesParams,
  ICellTreeNodeParams,
  ICellVendutoParams,
} from "./interfaces";

const i18_PATH = "prodotto.view.lottoDettagli.tableCells.";

const IMAGE_URL = `${settings.apiUrl}image/`;

export function CellTreeNode({
  id,
  leaf,
  depth,
  tipo,
  onOpen,
  onClose,
  isOpen,
}: ICellTreeNodeParams) {
  const paddingLeft = 2 * (depth || 0);
  return (
    <Box sx={{ display: "flex", alignItems: "center", pl: paddingLeft }}>
      {!leaf && isOpen && (
        <IconButton onClick={() => onClose(id)}>
          <Icon
            className="fa-light fa-angle-down"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        </IconButton>
      )}
      {!leaf && !isOpen && (
        <IconButton onClick={() => onOpen(id)}>
          <Icon
            className="fa-light fa-angle-right"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        </IconButton>
      )}
      {leaf && (
        <Box
          sx={{
            width: "36px",
            height: "36px",
          }}
        />
      )}
      {tipo === "Blocco" && (
        <Tooltip title="Blocco">
          <Icon className="fa-light fa-cube" sx={{ overflow: "visible" }} />
        </Tooltip>
      )}
      {tipo === "Lastre" && (
        <Tooltip title="Lastre">
          <Icon
            className="fa-light fa-layer-group"
            sx={{ overflow: "visible" }}
          />
        </Tooltip>
      )}
    </Box>
  );
}

export function CellVenduto({ value }: ICellVendutoParams) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {value === 0 && (
        <Tooltip title={t(`${i18_PATH}Non venduto`)}>
          <Icon
            className="fa-light fa-square-dashed"
            sx={{ overflow: "visible" }}
          />
        </Tooltip>
      )}
      {value > 0 && (
        <Tooltip title={t(`${i18_PATH}Venduto`)}>
          <Icon
            className="fa-light fa-square-check"
            sx={{ overflow: "visible" }}
          />
        </Tooltip>
      )}
    </Box>
  );
}

export function Descendant() {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
      }}
    >
      <Icon className="fa-light fa-l" sx={{ overflow: "visible" }} />
    </Box>
  );
}

export function CellThumbnail({ images, onViewImage }: ICellImagesParams) {
  const [thumb, setThumb] = useState<string>();
  const [image, setImage] = useState<ILottoImage>();
  useEffect(() => {
    if (!!images?.length) {
      const jwt = getJWT();
      const _thumb = `${IMAGE_URL}${images[0].mdPath}?token=${jwt}`;
      setImage(images[0]);
      setThumb(_thumb);
    } else {
      setThumb(undefined);
    }
  }, [images]);
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!thumb && (
        <Icon
          className="fa-light fa-image-slash"
          sx={{ overflow: "visible" }}
        />
      )}
      {!!thumb && !!image && (
        <Card>
          <CardActionArea onClick={() => onViewImage(image)}>
            <CardMedia
              component="img"
              image={thumb}
              sx={{ objectFit: "contain" }}
              alt="image"
            />
          </CardActionArea>
        </Card>
      )}
    </Box>
  );
}
