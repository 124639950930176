import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Icon,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDettaglio } from "../../../../../interfaces/dettaglio/models";
import { ILottoImage } from "../../../../../interfaces/lotto/models";
import {
  addImagesToDettagli,
  dettagliFilterNotSold,
  getDetailsTableViewMode,
  storeDetailsTableViewMode,
} from "../../../../../lib/functions";
import { useAppSelector } from "../../../../../store/hooks";
import DettagliSelectViewToolbar from "./dettagliSelectViewToolbar";
import DettaglioCommercialOptionsDialog from "./dialogs/commercialOptions/commercialOptionsDialog";
import DettaglioPackageListDialog from "./dialogs/packingList/packingListDialog";
import ShareDialog from "./dialogs/share/shareDialog";
import DettaglioSplitDialog from "./dialogs/split/splitDialog";
import DettaglioUpdateDialog from "./dialogs/update/updateDialog";
import DefaultGrid from "./grids/defaultGrid";
import HistoryGrid from "./grids/historyGrid";
import { EShowColumnState, ILottoDettagliProps } from "./interfaces";
import ShowHideColumnsToolbar from "./showHideColumnsToolbar";

const i18_PATH = "prodotto.view.lottoDettagli.";

function LottoDettagli({
  lotto,
  user,
  selectedItems,
  setSelectedItems,
  viewImage,
}: ILottoDettagliProps) {
  const { t } = useTranslation();
  const lastActionDone = useAppSelector((state) => state.ui.lastActionDone);
  const [dettagli, setDettagli] = useState<IDettaglio[]>([]);
  const [shareDialogOpen, setShareDialogOpen] = useState<boolean>(false);
  const [splitDialogOpen, setSplitDialogOpen] = useState<boolean>(false);
  const [
    commercialOptionsUpdateDialogOpen,
    setCommercialOptionsUpdateDialogOpen,
  ] = useState<boolean>(false);
  const [packingListDialogOpen, setPackingListDialogOpen] =
    useState<boolean>(false);
  const [updateDialogOpen, setUpdateDialogOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<"flat" | "tree">("flat");
  const [showAdminColumns, setShowAdminColumns] =
    useState<EShowColumnState | null>(null);
  const [canViewCosts, setCanViewCosts] = useState<boolean>(false);
  const [canViewCommercials, setCanViewCommercials] = useState<boolean>(false);
  const [canViewStoragePlace, setCanViewStoragePlace] =
    useState<boolean>(false);
  const [canViewBlockHistory, setCanViewBlockHistory] =
    useState<boolean>(false);
  const [canViewCommercialOptions, setCanViewCommercialOptions] =
    useState<boolean>(false);
  const [canSetCommercials, setCanSetCommercials] = useState<boolean>(false);
  const [canSetStoragePlace, setCanSetStoragePlace] = useState<boolean>(false);
  const [canSetFeatures, setCanSetFeatures] = useState<boolean>(false);
  const [canSetCommercialOptions, setCanSetCommercialOptions] =
    useState<boolean>(false);
  const [canShowHideColumns, setCanSetHideColumns] = useState<boolean>(false);
  const [canShare, setCanShare] = useState<boolean>(false);
  const onUpdate = () => {
    setUpdateDialogOpen(true);
  };
  const onSplit = () => {
    setSplitDialogOpen(true);
  };
  const onCommercialOptionsUpdate = () => {
    setCommercialOptionsUpdateDialogOpen(true);
  };
  const onPackingList = () => {
    setPackingListDialogOpen(true);
  };
  const onShare = () => {
    setShareDialogOpen(true);
  };
  const onViewImage = (image: ILottoImage) => {
    viewImage(image);
  };
  useEffect(() => {
    if (!lastActionDone) return;
    if (
      [
        "update-commercials-dettaglio",
        "update-stato-lavorazione-dettaglio",
        "update-storage-dettaglio",
      ].includes(lastActionDone)
    ) {
      setSelectedItems([]);
      setUpdateDialogOpen(false);
    }
    if (["update-commercial-options-dettaglio"].includes(lastActionDone)) {
      setSelectedItems([]);
      setCommercialOptionsUpdateDialogOpen(false);
    }
    if (["split-dettaglio"].includes(lastActionDone)) {
      setSelectedItems([]);
      setSplitDialogOpen(false);
    }
  }, [lastActionDone, setSelectedItems]);
  useEffect(() => {
    const _canViewCosts =
      !!user?.admin || !!user?.user_group?.group_permissions?.canViewCosts;
    /** price_allowed is for anonymous user */
    const _canViewCommercials =
      !!user?.admin ||
      !!user?.user_group?.group_permissions?.canViewCommercials ||
      !!user?.price_allowed;
    const _canViewStoragePlace =
      !!user?.admin ||
      !!user?.user_group?.group_permissions?.canViewStoragePlace;
    const _canViewBlockHistory =
      !!user?.admin ||
      !!user?.user_group?.group_permissions?.canViewBlockHistory;
    const _canViewCommercialOptions =
      !!user?.admin ||
      !!user?.user_group?.group_permissions?.canViewCommercialOptions;
    const _canSetCommercials =
      !!user?.admin || !!user?.user_group?.group_permissions?.canSetCommercials;
    const _canSetStoragePlace =
      !!user?.admin ||
      !!user?.user_group?.group_permissions?.canSetStoragePlace;
    const _canSetFeatures =
      !!user?.admin || !!user?.user_group?.group_permissions?.canSetFeatures;
    const _canSetCommercialOptions =
      !!user?.admin ||
      !!user?.user_group?.group_permissions?.canSetCommercialOptions;
    const _canSetHideColumns =
      _canViewCommercialOptions || _canViewCosts || _canViewStoragePlace;
    const _canShare =
      !!user?.admin || !!user?.user_group?.group_permissions?.canShare;
    setCanViewCosts(_canViewCosts);
    setCanViewCommercials(_canViewCommercials);
    setCanViewStoragePlace(_canViewStoragePlace);
    setCanViewBlockHistory(_canViewBlockHistory);
    setCanViewCommercialOptions(_canViewCommercialOptions);
    setCanSetCommercials(_canSetCommercials);
    setCanSetStoragePlace(_canSetStoragePlace);
    setCanSetFeatures(_canSetFeatures);
    setCanSetCommercialOptions(_canSetCommercialOptions);
    setCanSetHideColumns(_canSetHideColumns);
    setCanShare(_canShare);

    const tmp = getDetailsTableViewMode();
    if (!!user?.unknown) setShowAdminColumns(EShowColumnState.SHOW_ALL);
    else if (!!tmp) setShowAdminColumns(tmp);
    else setShowAdminColumns(EShowColumnState.HIDE_ALL);
  }, [user]);
  useEffect(() => {
    if (user?.unknown) setSelectedItems(dettagli);
  }, [dettagli, user, setSelectedItems]);
  useEffect(() => {
    const _dettagli = lotto.dettagli ?? [];
    const _images = lotto.images ?? [];
    setDettagli(addImagesToDettagli(_dettagli, _images));
  }, [lotto]);
  useEffect(() => {
    if (showAdminColumns) storeDetailsTableViewMode(showAdminColumns);
  }, [showAdminColumns]);
  return (
    <>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={
            <Icon
              className="fa-light fa-angle-down"
              fontSize="small"
              sx={{ overflow: "visible" }}
            />
          }
          aria-controls="qualita-content"
          id="qualita-header"
        >
          <Typography variant="subtitle1">
            {t(`${i18_PATH}Dettaglio`)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            "& .hidden": {
              display: "none !important",
            },
          }}
        >
          {!!showAdminColumns && (
            <>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {canShowHideColumns && (
                  <ShowHideColumnsToolbar
                    showColumns={showAdminColumns}
                    onHide={() =>
                      setShowAdminColumns(EShowColumnState.HIDE_ALL)
                    }
                    onPartialHide={() =>
                      setShowAdminColumns(EShowColumnState.HIDE_PARTIAL)
                    }
                    onShow={() =>
                      setShowAdminColumns(EShowColumnState.SHOW_ALL)
                    }
                  />
                )}
                {canViewBlockHistory && (
                  <DettagliSelectViewToolbar mode={mode} onSetMode={setMode} />
                )}
              </Box>
              {mode === "flat" && (
                <DefaultGrid
                  dettagli={dettagli}
                  selectedItems={selectedItems}
                  canShare={canShare}
                  canViewCosts={canViewCosts}
                  canViewCommercials={canViewCommercials}
                  canViewStoragePlace={canViewStoragePlace}
                  canViewCommercialOptions={canViewCommercialOptions}
                  canSetCommercials={canSetCommercials}
                  canSetStoragePlace={canSetStoragePlace}
                  canSetFeatures={canSetFeatures}
                  canSetCommercialOptions={canSetCommercialOptions}
                  showAdminColumns={showAdminColumns}
                  onSelectedItemsChange={(selectedItems) =>
                    setSelectedItems(selectedItems)
                  }
                  onUpdate={onUpdate}
                  onSplit={onSplit}
                  onShare={onShare}
                  onCommercialOptionsUpdate={onCommercialOptionsUpdate}
                  onPackingList={onPackingList}
                  onViewImage={onViewImage}
                />
              )}
              {mode === "tree" && (
                <HistoryGrid
                  dettagli={dettagli}
                  canViewCommercials={canViewCommercials}
                  canViewStoragePlace={canViewStoragePlace}
                  canViewCosts={canViewCosts}
                  showAdminColumns={showAdminColumns}
                />
              )}
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <DettaglioSplitDialog
        isOpen={splitDialogOpen}
        selectedItem={selectedItems[0]}
        canSetCommercialOptions={canSetCommercialOptions}
        onClose={() => {
          setSplitDialogOpen(false);
        }}
      />
      <DettaglioCommercialOptionsDialog
        isOpen={commercialOptionsUpdateDialogOpen}
        lottoId={lotto?.id}
        selectedItems={selectedItems}
        canSetCommercialOptions={canSetCommercialOptions}
        onClose={() => {
          setCommercialOptionsUpdateDialogOpen(false);
        }}
      />
      {!!showAdminColumns && (
        <DettaglioPackageListDialog
          isOpen={packingListDialogOpen}
          lotto={lotto}
          selectedItems={selectedItems}
          canViewCosts={canViewCosts}
          canViewCommercials={canViewCommercials}
          canViewStoragePlace={canViewStoragePlace}
          canViewCommercialOptions={canViewCommercialOptions}
          showAdminColumns={showAdminColumns}
          onClose={() => {
            setPackingListDialogOpen(false);
          }}
        />
      )}
      <DettaglioUpdateDialog
        isOpen={updateDialogOpen}
        lottoId={lotto?.id}
        selectedItems={selectedItems}
        canViewCommercials={canViewCommercials}
        canViewStoragePlace={canViewStoragePlace}
        canSetCommercials={canSetCommercials}
        canSetCommercialOptions={canSetCommercialOptions}
        canSetStoragePlace={canSetStoragePlace}
        canSetFeatures={canSetFeatures}
        onClose={() => {
          setUpdateDialogOpen(false);
        }}
      />
      <ShareDialog
        lotto={lotto}
        dettagli={selectedItems}
        isAllSelected={
          selectedItems.length === dettagliFilterNotSold(dettagli).length
        }
        isOpen={shareDialogOpen}
        close={() => setShareDialogOpen(false)}
      />
    </>
  );
}

export default LottoDettagli;
