import { createAction } from '@reduxjs/toolkit';
import { IAuthenticateError } from '../../interfaces/errors';
import { IUser } from '../../interfaces/user/models';
import { EMPTY, ERROR, GET, GET_UNKNOWN } from './types';


export const getAction = createAction<IUser | null>(GET);

export const getUnknownAction = createAction<IUser | null>(GET_UNKNOWN);

export const errorAction = createAction<IAuthenticateError>(ERROR);

export const emptyAction = createAction(EMPTY);

