import {
  Collapse,
  Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useMemo, useState } from "react";
import { EOptionType } from "../../../../../../../interfaces/enums";
import { ICava } from "../../../../../../../interfaces/place/models";
import { isFilterAndValueSelected } from "../../../../../../../lib/filters";
import { useAppSelector } from "../../../../../../../store/hooks";
import { IFilterProps } from "../../interfaces";

function CaveFilter({ filters, setFilters }: IFilterProps) {
  const [open, setOpen] = useState<boolean>(false);

  const cavaList = useAppSelector((state) => state.place_cava.list);
  const cavaItems = useAppSelector((state) => state.place_cava.items);

  const list: ICava[] = useMemo(
    () =>
      !!cavaList && !!cavaItems ? cavaList.map((id) => cavaItems[id]) : [],
    [cavaList, cavaItems]
  );
  const toggle = () => {
    setOpen(!open);
  };

  const toggleSelected = (item: ICava) => {
    if (isFilterAndValueSelected(filters, EOptionType.cava, item.id)) {
      setFilters(
        filters.filter(
          (opt) =>
            !(opt.type === EOptionType.cava && opt.numericValue === item.id)
        )
      );
    } else {
      setFilters([
        ...filters,
        {
          type: EOptionType.cava,
          numericValue: item.id,
          stringValue: item.nome,
        },
      ]);
    }
  };

  return (
    <>
      <ListItemButton onClick={toggle}>
        <ListItemIcon>
          <Icon
            className="fa-light fa-location-dot"
            sx={{ overflow: "visible" }}
          />
        </ListItemIcon>
        <ListItemText primary="Cava" />
        {open ? (
          <Icon
            className="fa-light fa-angle-up"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        ) : (
          <Icon
            className="fa-light fa-angle-down"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense={true}>
          {list.map((item) => (
            <ListItemButton
              key={item.id}
              sx={{ display: "flex", pl: 5, pr: 1 }}
              onClick={() => toggleSelected(item)}
              selected={isFilterAndValueSelected(
                filters,
                EOptionType.cava,
                item.id
              )}
            >
              <ListItemText sx={{ flex: 1 }} primary={item.nome} />
              <ListItemIcon sx={{ minWidth: 0 }}>
                {isFilterAndValueSelected(
                  filters,
                  EOptionType.cava,
                  item.id
                ) && (
                  <Icon
                    className="fa-light fa-check"
                    sx={{ overflow: "visible" }}
                  />
                )}
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
}

export default CaveFilter;
