import { Route, Routes } from "react-router-dom";
import List from "./list";
import View from "./view";

function Router() {
  return (
    <Routes>
      <Route path="/" element={<List />} />
      <Route path="/:lottoId" element={<View />} />
    </Routes>
  );
}

export default Router;
