import { Box, Button, Icon, InputBase } from "@mui/material";
import { ChangeEvent, useState } from "react";
import { IFilterOption } from "../../../../../interfaces/dettaglio/models";
import { EOptionType } from "../../../../../interfaces/enums";

interface ISearchToolbarProps {
  filters: IFilterOption[];
  setFilters: (filters: IFilterOption[]) => void;
}

function SearchToolbar({ filters, setFilters }: ISearchToolbarProps) {
  const [searchText, setSearchText] = useState<string>("");

  const onChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const clearSearchText = () => {
    setSearchText("");
    const _filters = [...filters].filter(
      (opt) => !(opt.type === EOptionType.fullText)
    );
    setFilters(_filters);
  };
  const search = () => {
    const _filters = [...filters].filter(
      (opt) => !(opt.type === EOptionType.fullText)
    );
    if (!!searchText)
      setFilters([
        ..._filters,
        {
          type: EOptionType.fullText,
          stringValue: searchText,
        },
      ]);
    else setFilters(_filters);
  };
  return (
    <Box
      sx={{
        position: "absolute",
        height: 32,
        left: 0,
        top: -32,
        display: "flex",
        alignItems: "center",
        border: "1px solid rgba(60, 60, 60, 0.5)",
        borderBottom: "none",
        borderRadius: 2,
        backgroundColor: "#ffffff",
      }}
    >
      <InputBase
        sx={{ pl: 2, flex: 1 }}
        placeholder="Ricerca"
        inputProps={{ "aria-label": "Ricerca" }}
        value={searchText}
        onChange={onChangeSearchText}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            ev.preventDefault();
            search();
          }
        }}
      />
      <Button
        variant="outlined"
        endIcon={
          <Icon
            className="fa-light fa-xmark"
            sx={{ overflow: "visible", marginRight: "4px", marginLeft: "-8px" }}
          />
        }
        sx={{
          endIcon: { margin: 0 },
          border: "none",
          "&:hover": { border: "none" },
        }}
        onClick={clearSearchText}
      ></Button>
    </Box>
  );
}

export default SearchToolbar;
