import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useProspectList, useProspectRemove } from "../../../effects/prospect/prospect";
import { IProspect } from "../../../interfaces/prospect/models";
import { useAppSelector } from "../../../store/hooks";
import DeleteDialog from "./components/dialogs/deleteDialog";
import Table from "./components/table/table";

function List() {
	const navigate = useNavigate();
	const [query, setQuery] = useState<number>(0);
	const [prospectToDelete, setProspectToDelete] = useState<IProspect | null>(null);
	const [prospectConfirmedToDelete, setProspectConfirmedToDelete] = useState<number | null>(null);
	const [rows, setRows] = useState<IProspect[]>([]);
	const list = useAppSelector((state) => state.prospect.list);
	const items = useAppSelector((state) => state.prospect.items);
	const lastActionDone = useAppSelector((state) => state.ui.lastActionDone);

	useEffect(() => {
		if (lastActionDone === "remove-prospect") setQuery(query + 1);
	}, [lastActionDone, query]);
	const edit = (id: number) => navigate(`${id}`);
	const remove = (id: number) => setProspectToDelete(items?.[id] || null);
	const add = () => navigate(`new`);
	const confirmDialog = () => {
		setProspectConfirmedToDelete(prospectToDelete?.id || null);
		setProspectToDelete(null);
	};
	const cancelDialog = () => setProspectToDelete(null);

	useEffect(() => {
		const _rows = (!!list && !!items) ? list.map(item => items[item]) : [];
		setRows(_rows);
	}, [list, items])

	useEffect(() => {
		setQuery(1);
	}, []);
	useProspectList(query);
	useProspectRemove(prospectConfirmedToDelete);
	return (
		<Box sx={{ display: "flex", flexDirection: "column", flex: 1, backgroundColor: "#fff" }}>
			<Table
				rows={rows}
				onEdit={edit}
				onRemove={remove}
				onAdd={add}
			></Table>
			<DeleteDialog
				item={prospectToDelete}
				onCancel={cancelDialog}
				onConfirm={confirmDialog}
			/>
		</Box>
	)
}

export default List;

