import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Icon,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { ILottoImage } from "../../../../../interfaces/lotto/models";
import { ILottoImagesProps } from "./interfaces";
import Thumbnail from "./thumbnail";

const i18_PATH = "prodotto.view.lottoImages.";

function DettaglioLottoImages({
  lotto,
  images,
  isBlock,
  viewImage,
}: ILottoImagesProps) {
  const { t } = useTranslation();

  const openImage = (image: ILottoImage) => {
    viewImage(image, isBlock);
  };

  return (
    <>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={
            <Icon
              className="fa-light fa-angle-down"
              fontSize="small"
              sx={{ overflow: "visible" }}
            />
          }
          aria-controls="qualita-content"
          id="qualita-header"
        >
          {!isBlock && (
            <Typography variant="subtitle1" sx={{ flex: 1 }}>
              {t(`${i18_PATH}Immagini lastre`)}
            </Typography>
          )}
          {!!isBlock && (
            <Typography variant="subtitle1" sx={{ flex: 1 }}>
              {t(`${i18_PATH}Immagini blocco`)}
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            {images.map((image, index) => (
              <Grid item xs={3} key={index}>
                <Thumbnail image={image} lotto={lotto} onOpen={openImage} />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default DettaglioLottoImages;
