import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { IDettaglio } from "../interfaces/dettaglio/models";
import { ILotto, ILottoImage } from "../interfaces/lotto/models";
import { EShowColumnState } from "../routes/prodotto/view/components/lottoDettagli/interfaces";

export const storeColumnVisibilityModel = (
  modelName: string,
  model: GridColumnVisibilityModel | null
): void => {
  if (!modelName) return;
  if (!!model) localStorage.setItem(modelName, JSON.stringify(model));
};
export const getColumnVisibilityModel = (
  modelName: string
): GridColumnVisibilityModel | null => {
  if (!modelName) return null;
  const model = localStorage.getItem(modelName);
  return !!model ? (JSON.parse(model) as GridColumnVisibilityModel) : null;
};

export const getDetailsTableViewMode = (): EShowColumnState | null => {
  return (
    (localStorage.getItem("detailsTableViewMode") as EShowColumnState) ?? null
  );
};

export const storeDetailsTableViewMode = (mode: EShowColumnState): void => {
  localStorage.setItem("detailsTableViewMode", mode as string);
};

export const dettaglioHasPrice = (item: IDettaglio): boolean =>
  parseFloat(String(item.prezzo).replaceAll(",", ".")) > 0;

export const dettaglioIsBlock = (item: IDettaglio): boolean =>
  item.idTipoLotto === 1;

export const dettaglioIsSlab = (item: IDettaglio): boolean =>
  item.idTipoLotto === 2;

export const dettaglioIsTiles = (item: IDettaglio): boolean =>
  item.idTipoLotto === 3;

export const dettaglioDoesExist = (item: IDettaglio): boolean =>
  item.numDettaglioFigli === 0;

export const dettaglioIsNotSold = (item: IDettaglio): boolean =>
  dettaglioDoesExist(item) && item.venduto === 0;

export const dettaglioIsSold = (item: IDettaglio): boolean =>
  dettaglioDoesExist(item) && item.venduto > 0;

export const dettaglioIsAvailable = (item: IDettaglio): boolean =>
  dettaglioIsNotSold(item) &&
  (item.idDisponibilita === 5 || item.idDisponibilita === 1);

export const isAllSlabs = (rows: IDettaglio[]): boolean =>
  !!rows.length && !rows.find((row) => row.idTipoLotto === 1);

export const dettagliFilterBlocks = (items: IDettaglio[]): IDettaglio[] =>
  items.filter(dettaglioIsBlock);

export const dettagliFilterSlabs = (items: IDettaglio[]): IDettaglio[] =>
  items.filter(dettaglioIsSlab);

export const dettagliFiltersTiles = (items: IDettaglio[]): IDettaglio[] =>
  items.filter(dettaglioIsTiles);

export const dettagliFilterExisting = (items: IDettaglio[]): IDettaglio[] =>
  items.filter(dettaglioDoesExist);

export const dettagliFilterExistingSlabs = (
  items: IDettaglio[]
): IDettaglio[] =>
  items.filter((item) => dettaglioIsSlab(item) && dettaglioDoesExist(item));

export const dettagliFilterExistingBlocks = (
  items: IDettaglio[]
): IDettaglio[] =>
  items.filter((item) => dettaglioIsBlock(item) && dettaglioDoesExist(item));

export const dettagliFilterNotSold = (items: IDettaglio[]): IDettaglio[] =>
  items.filter(dettaglioIsNotSold);

export const dettagliFilterSold = (items: IDettaglio[]): IDettaglio[] =>
  items.filter(dettaglioIsSold);

export const dettagliFilterNotSoldSlabs = (items: IDettaglio[]): IDettaglio[] =>
  items.filter((item) => dettaglioIsSlab(item) && dettaglioIsNotSold(item));

export const dettagliFilterNotSoldBlocks = (
  items: IDettaglio[]
): IDettaglio[] =>
  items.filter((item) => dettaglioIsBlock(item) && dettaglioIsNotSold(item));

export const dettagliFilterAvailable = (items: IDettaglio[]): IDettaglio[] =>
  items.filter(dettaglioIsAvailable);

export const dettagliFilterAvailableSlabs = (
  items: IDettaglio[]
): IDettaglio[] =>
  items.filter((item) => dettaglioIsSlab(item) && dettaglioIsAvailable(item));

export const dettagliFilterAvailableBlocks = (
  items: IDettaglio[]
): IDettaglio[] =>
  items.filter((item) => dettaglioIsBlock(item) && dettaglioIsAvailable(item));

export const dettagliFilterAvailableTiles = (
  items: IDettaglio[]
): IDettaglio[] =>
  items.filter((item) => dettaglioIsTiles(item) && dettaglioIsAvailable(item));

export const imagesFilter = (
  items: IDettaglio[],
  images: ILottoImage[]
): ILottoImage[] => {
  const availableItems = dettagliFilterNotSold(items);
  const answer: ILottoImage[] = [];
  images.forEach((image) => {
    if (
      !!image.slab &&
      availableItems.find(
        (item) => item.lastreDa <= image.slab && item.lastreA >= image.slab
      )
    )
      answer.push(image);
    else if (
      !!image.bundle &&
      availableItems.find((item) => item.bundle === image.bundle)
    )
      answer.push(image);
    else if (!image.bundle && !image.slab) answer.push(image);
  });
  return answer;
};

export const getElementsNumber = (rows: unknown[]): number => rows.length;

export const getTotalNumberOfSlabs = (rows: IDettaglio[]): number =>
  rows
    .filter((row) => row.tipo_lotto.nome === "Lastre")
    .reduce(
      (previousValue, currentValue) => previousValue + currentValue.pezzi,
      0
    );

export const getTotalNumberOfBlocks = (rows: IDettaglio[]): number =>
  rows
    .filter((row) => row.tipo_lotto.nome === "Blocco")
    .reduce(
      (previousValue, currentValue) => previousValue + currentValue.pezzi,
      0
    );

export const getTotalMQ = (
  rows: IDettaglio[],
  decimalToDisplay: number = 2,
  decimalToCalc?: number
): string =>
  Number(
    rows
      .filter((row) => row.tipo_lotto.nome === "Lastre")
      .reduce((previousValue, currentValue) => {
        return (
          previousValue +
          (!!decimalToCalc
            ? parseFloat(currentValue.quantitaUM.toFixed(decimalToCalc))
            : currentValue.quantitaUM)
        );
      }, 0)
      .toFixed(decimalToDisplay)
  ).toLocaleString("it-IT");

export const getTotalTN = (
  rows: IDettaglio[],
  decimalToDisplay: number = 2,
  decimalToCalc?: number
): string =>
  Number(
    rows
      .filter((row) => row.tipo_lotto.nome === "Blocco")
      .reduce(
        (previousValue, currentValue) =>
          previousValue +
          (!!decimalToCalc
            ? parseFloat(currentValue.quantitaUM.toFixed(decimalToCalc))
            : currentValue.quantitaUM),
        0
      )
      .toFixed(decimalToDisplay)
  ).toLocaleString("it-IT");

export const getTotalSlabsPrice = (
  rows: IDettaglio[],
  decimalToDisplay: number = 2,
  decimalToCalc?: number
): string =>
  Number(
    rows
      .filter((row) => row.tipo_lotto.nome === "Lastre")
      .reduce((previousValue, currentValue) => {
        const priceMQ = parseFloat(
          parseFloat(String(currentValue.prezzo).replaceAll(",", ".")).toFixed(
            decimalToCalc
          )
        );
        return previousValue + priceMQ * currentValue.quantitaUM;
      }, 0)
      .toFixed(decimalToDisplay)
  ).toLocaleString("it-IT");

export const getTotalBlocksPrice = (
  rows: IDettaglio[],
  decimalToDisplay: number = 2,
  decimalToCalc?: number
): string =>
  Number(
    rows
      .filter((row) => row.tipo_lotto.nome === "Blocco")
      .reduce((previousValue, currentValue) => {
        const priceTN = parseFloat(
          parseFloat(String(currentValue.prezzo).replaceAll(",", ".")).toFixed(
            decimalToCalc
          )
        );
        return previousValue + priceTN * currentValue.quantitaUM;
      }, 0)
      .toFixed(decimalToDisplay)
  ).toLocaleString("it-IT");
export const getMQPrice = (
  rows: IDettaglio[],
  decimalToDisplay: number = 2,
  decimalToCalc?: number
): string => {
  const slabs = rows.filter(
    (row) =>
      row.tipo_lotto.nome === "Lastre" &&
      parseFloat(String(row.prezzo).replaceAll(",", ".")) > 0
  );
  const totalPrice = slabs.reduce((previousValue, currentValue) => {
    const priceMQ = parseFloat(
      parseFloat(String(currentValue.prezzo).replaceAll(",", ".")).toFixed(
        decimalToCalc
      )
    );
    return previousValue + priceMQ * currentValue.quantitaUM;
  }, 0);
  const totalMQ = slabs.reduce(
    (previousValue, currentValue) => previousValue + currentValue.quantitaUM,
    0
  );
  const mqPrice = totalPrice / totalMQ || 0;
  return Number(mqPrice.toFixed(decimalToDisplay)).toLocaleString("it-IT");
};

export const getTNPrice = (
  rows: IDettaglio[],
  decimalToDisplay: number = 2,
  decimalToCalc?: number
): string => {
  const slabs = rows.filter(
    (row) =>
      row.tipo_lotto.nome === "Blocco" &&
      parseFloat(String(row.prezzo).replaceAll(",", ".")) > 0
  );
  const totalPrice = slabs.reduce((previousValue, currentValue) => {
    const priceTN = parseFloat(
      parseFloat(String(currentValue.prezzo).replaceAll(",", ".")).toFixed(
        decimalToCalc
      )
    );
    return previousValue + priceTN * currentValue.quantitaUM;
  }, 0);
  const totalTN = slabs.reduce(
    (previousValue, currentValue) => previousValue + currentValue.quantitaUM,
    0
  );
  const mqPrice = totalPrice / totalTN || 0;
  return Number(mqPrice.toFixed(decimalToDisplay)).toLocaleString("it-IT");
};

export const calcQualtityUM = (item: IDettaglio): number => {
  if (dettaglioIsBlock(item)) return item.quantitaUM;
  const height = item.misX;
  const width = item.misY;
  const num = item.pezzi;
  return (height * width * num) / 10000;
};

export const getTotalCosts = (
  rows: IDettaglio[],
  decimalToDisplay: number = 2,
  decimalToCalc?: number
): string =>
  Number(
    rows
      .reduce((previousValue, currentValue) => {
        const costiDiretti = parseFloat(
          parseFloat(
            String(currentValue.costiDiretti).replaceAll(",", ".")
          ).toFixed(decimalToCalc)
        );
        const costiPadre = parseFloat(
          parseFloat(
            String(currentValue.costiPadre).replaceAll(",", ".")
          ).toFixed(decimalToCalc)
        );

        const costi = costiDiretti + costiPadre;
        return previousValue + costi;
      }, 0)
      .toFixed(decimalToDisplay)
  ).toLocaleString("it-IT");

export const getDettaglioByImage = (
  image: ILottoImage,
  items: IDettaglio[]
): IDettaglio | null => {
  if (!!image.slab) {
    return (
      items.find(
        (item) =>
          !!item.lastreDa &&
          !!item.lastreA &&
          image.slab >= item.lastreDa &&
          image.slab <= item.lastreA
      ) ?? null
    );
  }
  if (!!image.bundle) {
    return (
      items.find((item) => !!item.bundle && image.bundle === item.bundle) ??
      null
    );
  }
  return null;
};

export const getImageName = (
  image: ILottoImage,
  lotto: ILotto,
  dettaglio: IDettaglio,
  formula: string
): string => {
  const source = { image, lotto, dettaglio };
  const matches = formula?.match(/\[(.+?)\]/gi) ?? [];
  const values = matches?.map((match) => searchInSource(source, match));
  for (let i = 0; i < matches.length; ++i) {
    const match = matches[i];
    const value = values[i];
    formula = formula.replace(match, value);
  }
  return formula;
};

const searchInSource = (source: any, match: string): string => {
  const field = match.substring(1, match.length - 1);
  const keys = field.split(".");
  let value: any = source;
  while (keys.length) {
    const key = keys.shift();
    if (!!key) value = value[key];
    if (!value) return "";
  }
  return value?.toString() ?? "";
};

export const addImagesToDettagli = (
  dettagli: IDettaglio[],
  images: ILottoImage[]
): IDettaglio[] => {
  const _images = JSON.parse(JSON.stringify(images)) as ILottoImage[];
  const _dettagli = JSON.parse(JSON.stringify(dettagli)) as IDettaglio[];
  _images.sort(
    (imageA, imageB) =>
      imageA.order - imageB.order ||
      imageA.slab - imageB.slab ||
      imageA.bundle - imageB.bundle
  );
  _dettagli.forEach((item) => (item.images = []));
  _images.forEach((image) => {
    const slab = image.slab;
    const bundle = image.bundle;
    let detailsFound: IDettaglio[] = [];
    if (!!slab)
      detailsFound = _dettagli.filter(
        (item) => item.lastreDa <= slab && item.lastreA >= slab
      );
    else if (!!bundle)
      detailsFound = _dettagli.filter((item) => item.bundle === bundle);
    detailsFound.forEach((detail) => {
      detail.images ??= [];
      detail.images.push(image);
    });
  });
  return _dettagli;
};
