import { Icon, Tooltip } from "@mui/material";
import {
  GridActionsCellItem,
  GridAlignment,
  GridColumns,
  GridRowParams,
} from "@mui/x-data-grid";
import { IMaterialeExt } from "../../../../../interfaces/lotto/attributes/models";
import { ICreateColumnsPros } from "./interfaces";

const i18_PATH = "materiale.list.columns.";

export const createColumns = ({
  t,
  onEdit,
}: ICreateColumnsPros): GridColumns<IMaterialeExt> => [
  {
    field: "actions",
    type: "actions",
    align: "left" as GridAlignment,
    hideable: false,
    maxWidth: 100,
    getActions: (params: GridRowParams<IMaterialeExt>) => [
      <GridActionsCellItem
        icon={
          <Tooltip title="Modifica">
            <Icon className="fa-light fa-pen" sx={{ overflow: "visible" }} />
          </Tooltip>
        }
        label="Edit"
        onClick={() => onEdit(params.id as number)}
      />,
    ],
  },
  {
    field: "nome",
    headerName: t(`${i18_PATH}Nome`) ?? undefined,
    width: 160,
  },
  {
    field: "ext.descrizione",
    headerName: t(`${i18_PATH}Descrizione`) ?? undefined,
    valueGetter: ({ row }) => row.ext?.descrizione,
    flex: 1,
  },
];
