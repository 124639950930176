import {
  IQualita,
  IQualitaExt,
} from "../../../../interfaces/lotto/attributes/models";

export const GET = "lotto_qualita/get";
export const LIST = "lotto_qualita/list";
export const LIST_EXT = "lotto_qualita/listExt";
export const EMPTY = "lotto_qualita/empty";

export interface IQualitaState {
  results: number;
  resultsExt: number;
  list: number[];
  listExt: number[];
  items: Record<number, IQualita> | null;
  itemsExt: Record<number, IQualitaExt> | null;
}

export interface IQualitaListPayload {
  results: number;
  list: number[];
  items: Record<number, IQualita> | null;
}

export interface IQualitaExtListPayload {
  resultsExt: number;
  listExt: number[];
  itemsExt: Record<number, IQualitaExt> | null;
}

export const initialState: IQualitaState = {
  results: 0,
  resultsExt: 0,
  list: [],
  listExt: [],
  items: null,
  itemsExt: null,
};
