import { Box, Button, Icon } from '@mui/material';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { ITableToolbarProps } from './interfaces';

function TableToolbar({ onAdd }: ITableToolbarProps) {
	return (
		<GridToolbarContainer>
			<GridToolbarExport csvOptions={{ fileName: "prospects", delimiter: ';' }} />
			<Box sx={{ display: 'flex', flexGrow: 1, px: 1, justifyContent: 'flex-end' }}>
				<Button variant="outlined" startIcon={<Icon className="fa-light fa-plus" sx={{ overflow: "visible" }} />} color="inherit" sx={{ mx: 1, my: 1, display: 'flex' }} onClick={() => onAdd()}>Nuovo Prospect</Button>
			</Box>
		</GridToolbarContainer>
	);
}

export default TableToolbar;