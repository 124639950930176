import { IVenatura } from "../../../../interfaces/lotto/attributes/models";

export const GET = "lotto_venatura/get";
export const LIST = "lotto_venatura/list";
export const EMPTY = "lotto_venatura/empty";

export interface IVenaturaState {
	results: number;
	list: number[];
	items: Record<number, IVenatura> | null;
};

export const initialState: IVenaturaState = {
	results: 0,
	list: [],
	items: null
};