export const getJWT = (): string | null => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const tempJwt = urlParams.get("token");
  if (!!tempJwt) return tempJwt;
  const jwt = localStorage.getItem("JWT");
  if (!!jwt) return jwt;
  return null;
};

export const isUnknownJWT = (): boolean => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const tempJwt = urlParams.get("token");
  return !!tempJwt;
};

export const setJWT = (jwt: string): void => localStorage.setItem("JWT", jwt);

export const emptyJWT = (): void => localStorage.removeItem("JWT");
