import { AppBar, Box, Button, Dialog, DialogActions, Icon, IconButton, TextField, Toolbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDettaglioSplit } from "../../../../../../../effects/dettaglio/dettaglio";
import { DettaglioSplitFormData } from "../../../../../../../interfaces/dettaglio/forms";
import Transition from "../../../../../../../lib/dialogTransition";
import { ISplitDialogProps } from "../interfaces";


function DettaglioSplitDialog({ isOpen, selectedItem, canSetCommercialOptions, onClose }: ISplitDialogProps) {

	const [isValid, setIsValid] = useState<boolean>(false);
	const [id, setId] = useState<number | null>(null);
	const [formData, setFormData] = useState<DettaglioSplitFormData>(null);
	const [quantity, setQuantity] = useState<string>();

	const onQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const __quantity = event.target.value;
		const _quantity = (!!__quantity) ? parseInt(__quantity) : 0;
		const _isValid = !!_quantity && _quantity < selectedItem.pezzi;
		setQuantity(__quantity);
		setIsValid(_isValid);
	};

	const close = () => {
		setFormData(null);
		onClose();
	}

	const onSubmit = () => {
		if (canSetCommercialOptions && isValid) {
			const _formData: DettaglioSplitFormData = {
				quantity: (!!quantity) ? parseInt(quantity) : 0
			}
			setFormData(_formData);
		}
	}
	useEffect(() => {
		setFormData(null);
		if (selectedItem) setId(selectedItem.id);
	}, [selectedItem, isOpen])
	useDettaglioSplit(id, formData);
	return (
		<Dialog
			open={isOpen}
			onClose={close}
			TransitionComponent={Transition}
			fullWidth
			maxWidth="md"
		>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar variant="dense">
					<Typography variant="h5" sx={{ flex: 1 }} display="block" >Dividi il dettaglio</Typography>
					<IconButton
						edge="end"
						color="inherit"
						onClick={close}
						aria-label="close"
					>
						<Icon className="fa-light fa-xmark" sx={{ overflow: "visible" }} />
					</IconButton>
				</Toolbar>
			</AppBar>
			<Box sx={{ p: 2, overflowY: 'auto', display: 'flex' }}>
				<TextField
					margin="dense"
					label="Numero Pezzi"
					type="number"
					name="numPezzi"
					value={quantity}
					onChange={onQuantityChange}
					sx={{ flex: 1 }}
					inputProps={{ maxLength: 12 }}
				/>
			</Box>
			<DialogActions>
				<Button onClick={close}>Cancella</Button>
				<Button disabled={!canSetCommercialOptions || !isValid} onClick={onSubmit} variant="contained" color="primary">Dividi</Button>
			</DialogActions>
		</Dialog>
	)
}

export default DettaglioSplitDialog