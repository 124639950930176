import { ITipoLotto, IDisponibilita, IStatoLavorazione } from "../interfaces/dettaglio/attributes/models";
import { IDettaglio } from "../interfaces/dettaglio/models";
import { ICategoria, IFondo, IMateriale, IQualita, IUso, IVenatura } from "../interfaces/lotto/attributes/models";
import { ILotto, ILottoImage } from "../interfaces/lotto/models";
import { ICava, IFila, IMagazzino, IZona } from "../interfaces/place/models";
import { IProspect } from "../interfaces/prospect/models";
import { IUser, IUserGroup } from "../interfaces/user/models";

export const lottoTransform = (
	item: ILotto,
	cava: Record<number, ICava>,
	categoria: Record<number, ICategoria>,
	fondo: Record<number, IFondo>,
	materiale: Record<number, IMateriale>,
	qualita: Record<number, IQualita>,
	uso: Record<number, IUso>,
	venatura: Record<number, IVenatura>,
	cover: Record<number, ILottoImage>
): ILotto => ({
	...item,
	cava: cava[item.idCava],
	categoria: (!!item.idCategoria) ? categoria[item.idCategoria] : null,
	fondo: (!!item.idFondo) ? fondo[item.idFondo] : null,
	materiale: (!!item.idMateriale) ? materiale[item.idMateriale] : null,
	qualita: (!!item.idQualita) ? qualita[item.idQualita] : null,
	uso: (!!item.idUso) ? uso[item.idUso] : null,
	venatura: (!!item.idVenatura) ? venatura[item.idVenatura] : null,
	cover: cover[item.id]
});

export const dettaglioTransform = (
	item: IDettaglio,
	lottoMap: Record<number, ILotto>,
	tipoLottoMap: Record<number, ITipoLotto>,
	magazzinoMap: Record<number, IMagazzino>,
	zonaMap: Record<number, IZona>,
	filaMap: Record<number, IFila>,
	disponibilitaMap: Record<number, IDisponibilita>,
	statoLavorazioneMap: Record<number, IStatoLavorazione>,
): IDettaglio => ({
	...item,
	lotto: lottoMap[item.idLotto],
	tipo_lotto: tipoLottoMap[item.idTipoLotto],
	magazzino: (!!item.idMagazzino) ? magazzinoMap[item.idMagazzino] : null,
	zona: (!!item.idZona) ? zonaMap[item.idZona] : null,
	fila: (!!item.idFila) ? filaMap[item.idFila] : null,
	disponibilita: (!!item.idDisponibilita) ? disponibilitaMap[item.idDisponibilita] : null,
	stato_lavorazione: (!!item.idStatoLavorazione) ? statoLavorazioneMap[item.idStatoLavorazione] : null,
});

export const prospectTransform = (
	item: IProspect,
	materialeMap: Record<number, IMateriale>,
	qualitaMap: Record<number, IQualita>,
	categoriaMap: Record<number, ICategoria>,
	fondoMap: Record<number, IFondo>,
	venaturaMap: Record<number, IVenatura>,
	usoMap: Record<number, IUso>,
): IProspect => ({
	...item,
	materiale: (!!item.idMateriale) ? materialeMap[item.idMateriale] : null,
	qualita: (!!item.idQualita) ? qualitaMap[item.idQualita] : null,
	categoria: (!!item.idCategoria) ? categoriaMap[item.idCategoria] : null,
	fondo: (!!item.idFondo) ? fondoMap[item.idFondo] : null,
	venatura: (!!item.idVenatura) ? venaturaMap[item.idVenatura] : null,
	uso: (!!item.idUso) ? usoMap[item.idUso] : null
});

export const userTransform = (
	user: IUser,
	userGroup: Record<number, IUserGroup>
): IUser => ({
	...user,
	user_group: userGroup[user.id_userGroup]
});
