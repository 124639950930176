import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";
import { emptyAction, getAction, listAction } from "./actions";
import { initialState } from "./types";

export default createReducer(initialState, (builder) => {
	builder.addCase(listAction, (state, action) => produce(state, draft => {
		const { results, list, items } = action.payload
		draft.results = results;
		draft.list = list;
		draft.items = items;
	}));
	builder.addCase(getAction, (state, action) => produce(state, draft => {
		const fila = action.payload;
		if (!draft.items) draft.items = {};
		draft.items[fila.id] = fila;
	}));
	builder.addCase(emptyAction, () => initialState);
})