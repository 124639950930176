import { Box, Icon, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { dettaglioIsBlock, dettaglioIsSlab, dettaglioIsTiles } from "../../../lib/functions";
import { ICellTipoLottoParams } from "./interfaces";

export function CellTipoLotto({ dettaglio }: ICellTipoLottoParams) {
	const [color, setColor] = useState<string>("inherit");
	const [tooltipSuffix, setTooltipSuffix] = useState<string>("");
	useEffect(() => {
		const availability = dettaglio.disponibilita;
		if (availability) {
			setColor(availability.coloreWeb);
			setTooltipSuffix(` - ${availability.nome}`);
		}
	}, [dettaglio]);

	return (
		<Box sx={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
			{dettaglioIsBlock(dettaglio) &&
				<Tooltip title={`Blocco${tooltipSuffix}`}>
					<Icon className="fa-light fa-cube" sx={{ overflow: "visible", color }} />
				</Tooltip>
			}
			{dettaglioIsSlab(dettaglio) &&
				<Tooltip title={`Lastre${tooltipSuffix}`}>
					<Icon className="fa-light fa-layer-group" sx={{ overflow: "visible", color }} />
				</Tooltip>
			}
			{dettaglioIsTiles(dettaglio) &&
				<Tooltip title={`Marmette${tooltipSuffix}`}>
					<Icon className="fa-light fa-bring-forward" sx={{ overflow: "visible", color }} />
				</Tooltip>
			}
		</Box>
	)
}