import { createAction } from '@reduxjs/toolkit';
import { IUser } from '../../../interfaces/user/models';
import { EMPTY, GET, IUserState, LIST } from './types';

export const listAction = createAction<IUserState>(LIST);

export const getAction = createAction<IUser>(GET);

export const emptyAction = createAction(EMPTY);

