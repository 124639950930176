import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';
import Transition from '../../../../../lib/dialogTransition';
import { IDeleteProps } from './interfaces';

function DeleteDialog(props: IDeleteProps) {
	const { item, onCancel, onConfirm } = props;
	return (
		<Dialog
			open={!!item}
			TransitionComponent={Transition}
			keepMounted
			aria-describedby="alert-dialog"
		>
			<DialogTitle>{"Cancellare il Prospect?"}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Prospect: {item?.cliente}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onCancel}>Cancella</Button>
				<Button variant="contained" onClick={onConfirm}>Conferma</Button>
			</DialogActions>
		</Dialog>
	);
}

export default DeleteDialog;