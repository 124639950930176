import { useEffect } from "react";
import { errorHandler } from "../../../error/handler";
import {
  getMateriale,
  getMaterialeExtList,
  getMaterialeList,
  updateMateriale,
} from "../../../fetch/lotto/attributes/materiale";
import { MaterialeUpdateFormData } from "../../../interfaces/lotto/attributes/forms";
import {
  IMateriale,
  IMaterialeExt,
} from "../../../interfaces/lotto/attributes/models";
import { normalizer } from "../../../lib/normalizer";
import { useAppDispatch } from "../../../store/hooks";
import {
  emptyAction,
  getAction,
  listAction,
  listExtAction,
} from "../../../store/lotto/attributes/materiale/actions";
import {
  IMaterialeExtListPayload,
  IMaterialeListPayload,
} from "../../../store/lotto/attributes/materiale/types";
import {
  endActionToDoAction,
  messageAction,
  startActionToDoAction,
} from "../../../store/ui/actions";

export const useGetMateriale = (id: number | null) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    let ignore = false;
    const effect = async () => {
      if (!id) return;
      const actionToDo = "get-materiale";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        const materiale = await getMateriale(id);
        if (ignore)
          return dispatch(endActionToDoAction({ actionToDo, success: true }));
        dispatch(getAction(materiale));
        dispatch(endActionToDoAction({ actionToDo, success: true }));
      } catch (err) {
        errorHandler(err, actionToDo);
      }
    };
    effect();
    return () => {
      ignore = true;
    };
  }, [dispatch, id]);
};

export const useMaterialeList = (query: number) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    let ignore = false;
    const effect = async () => {
      if (!query) return;
      const actionToDo = "list-materiale";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        const { results, items: _items } = await getMaterialeList();
        if (ignore)
          return dispatch(endActionToDoAction({ actionToDo, success: true }));
        _items.sort((itemA, itemB) =>
          !!itemA?.ordinamento && !!itemB.ordinamento
            ? itemA.ordinamento - itemB.ordinamento
            : 0
        );
        const { list, items } = normalizer<number, IMateriale>(_items, "id");
        const actionPayload: IMaterialeListPayload = {
          results,
          list,
          items,
        };
        dispatch(listAction(actionPayload));
        dispatch(endActionToDoAction({ actionToDo, success: true }));
      } catch (err) {
        errorHandler(err, actionToDo);
      }
      return () => {
        dispatch(emptyAction());
      };
    };
    effect();
    return () => {
      ignore = true;
    };
  }, [dispatch, query]);
};

export const useMaterialeExtList = (query: number) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    let ignore = false;
    const effect = async () => {
      if (!query) return;
      const actionToDo = "list-materiale-ext";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        const { results, items: _items } = await getMaterialeExtList();
        if (ignore)
          return dispatch(endActionToDoAction({ actionToDo, success: true }));
        _items.sort((itemA, itemB) =>
          !!itemA?.ordinamento && !!itemB.ordinamento
            ? itemA.ordinamento - itemB.ordinamento
            : 0
        );
        const { list, items } = normalizer<number, IMaterialeExt>(_items, "id");
        const actionPayload: IMaterialeExtListPayload = {
          resultsExt: results,
          listExt: list,
          itemsExt: items,
        };
        dispatch(listExtAction(actionPayload));
        dispatch(endActionToDoAction({ actionToDo, success: true }));
      } catch (err) {
        errorHandler(err, actionToDo);
      }
      return () => {
        dispatch(emptyAction());
      };
    };
    effect();
    return () => {
      ignore = true;
    };
  }, [dispatch, query]);
};

export const useMaterialeUpdate = async (
  id: number | null,
  data: MaterialeUpdateFormData
) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    let ignore = false;
    const effect = async () => {
      if (!id || !data) return;
      const actionToDo = "update-materiale";
      try {
        dispatch(startActionToDoAction({ actionToDo }));
        await updateMateriale(id, data);
        if (ignore)
          return dispatch(endActionToDoAction({ actionToDo, success: true }));
        dispatch(endActionToDoAction({ actionToDo, success: true }));
        dispatch(
          messageAction({ message: "Materiale aggiornato", kind: "success" })
        );
      } catch (err) {
        errorHandler(err, actionToDo);
      }
    };
    effect();
    return () => {
      ignore = true;
    };
  }, [dispatch, id, data]);
};
