import { createAction } from '@reduxjs/toolkit';
import { ICategoria } from '../../../../interfaces/lotto/attributes/models';
import { EMPTY, GET, ICategoriaState, LIST } from './types';

export const listAction = createAction<ICategoriaState>(LIST);

export const getAction = createAction<ICategoria>(GET);

export const emptyAction = createAction(EMPTY);

