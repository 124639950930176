import { createAction } from "@reduxjs/toolkit";
import { IDettaglio } from "../../../interfaces/dettaglio/models";
import {
  EMPTY,
  GET,
  IDettaglioListPayload,
  IDettaglioListSoldPayload,
  LIST,
  LIST_SOLD,
} from "./types";

export const listAction = createAction<IDettaglioListPayload>(LIST);

export const listSoldAction =
  createAction<IDettaglioListSoldPayload>(LIST_SOLD);

export const getAction = createAction<IDettaglio>(GET);

export const emptyAction = createAction(EMPTY);
