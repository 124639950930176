import { IMagazzino } from "../../../interfaces/place/models";

export const GET = "magazzino/get";
export const LIST = "magazzino/list";
export const EMPTY = "magazzino/empty";

export interface IMagazzinoState {
	results: number;
	list: number[];
	items: Record<number, IMagazzino> | null;
};

export const initialState: IMagazzinoState = {
	results: 0,
	list: [],
	items: null
};