import {
  Box,
  Button,
  Collapse,
  Icon,
  InputAdornment,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { EOptionType } from "../../../../../../../interfaces/enums";
import { IFilterProps } from "../../interfaces";

function PrezzoFilter({ filters, setFilters }: IFilterProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [minPrice, setMinPrice] = useState<string>("");
  const [maxPrice, setMaxPrice] = useState<string>("");

  const toggle = () => {
    setOpen(!open);
  };

  const onMinPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMinPrice(event.target.value);
  };

  const onMaxPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMaxPrice(event.target.value);
  };

  const submitFilter = () => {
    if (!minPrice && !maxPrice) return;
    let stringValue = "Prezzo - ";
    const _minPrice = !isNaN(parseFloat(minPrice))
      ? parseFloat(minPrice)
      : null;
    const _maxPrice = !isNaN(parseFloat(maxPrice))
      ? parseFloat(maxPrice)
      : null;
    if (_minPrice !== null) stringValue += `da € ${_minPrice} `;
    if (_maxPrice !== null) stringValue += `a € ${_maxPrice} `;
    setFilters([
      ...filters.filter((opt) => !(opt.type === EOptionType.prezzo)),
      {
        type: EOptionType.prezzo,
        numericValues: [_minPrice as number, _maxPrice as number],
        stringValue,
      },
    ]);
  };
  return (
    <>
      <ListItemButton onClick={toggle}>
        <ListItemIcon>
          <Icon
            className="fa-light fa-euro-sign"
            sx={{ overflow: "visible" }}
          />
        </ListItemIcon>
        <ListItemText primary="Prezzo" />
        {open ? (
          <Icon
            className="fa-light fa-angle-up"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        ) : (
          <Icon
            className="fa-light fa-angle-down"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box sx={{ pl: 4, pr: 2, pv: 2 }}>
          <TextField
            size="small"
            margin="dense"
            label={`Prezzo minimo`}
            name="prezzo"
            type="number"
            value={minPrice}
            onChange={onMinPriceChange}
            sx={{ flex: 1, width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <>€</>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            size="small"
            margin="dense"
            label={`Prezzo massimo`}
            name="prezzo"
            type="number"
            value={maxPrice}
            onChange={onMaxPriceChange}
            sx={{ flex: 1, width: "100%" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <>€</>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box sx={{ display: "flex", pl: 4, pr: 2, pb: 2 }}>
          <Button
            onClick={submitFilter}
            size="small"
            variant="outlined"
            sx={{ flex: 1 }}
          >
            Filtra
          </Button>
        </Box>
      </Collapse>
    </>
  );
}

export default PrezzoFilter;
