import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetQualita,
  useQualitaUpdate,
} from "../../../effects/lotto/attributes/qualita";
import { QualitaUpdateFormData } from "../../../interfaces/lotto/attributes/forms";
import { useAppSelector } from "../../../store/hooks";
import ImageUpload from "./imageUpload";

const i18_PATH = "qualita.update.";

function Update() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState<QualitaUpdateFormData>(null);
  const { id } = useParams();
  const qualitaId = !!id ? parseInt(id) : null;
  const qualita = useAppSelector(
    (state) => state.lotto_qualita.itemsExt?.[parseInt(id ?? "")]
  );

  const [nome, setNome] = useState("");
  const [idMateriale, setIdMateriale] = useState<string>("");
  const [idFondo, setIdFondo] = useState<string>("");
  const [utilizzo, setUtilizzo] = useState<string>("");
  const [finiture, setFiniture] = useState<string>("");
  const [densita, setDensita] = useState<string>("");
  const [resistenzaCompressione, setResistenzaCompressione] =
    useState<string>("");
  const [resistenzaFlessione, setResistenzaFlessione] = useState<string>("");
  const [coefImbibizione, setCoefImbibizione] = useState<string>("");
  const [descrizione, setDescrizione] = useState("");

  const { items: materialeItems, list: materialeList } = useAppSelector(
    (state) => state.lotto_materiale
  );
  const { items: fondoItems, list: fondoList } = useAppSelector(
    (state) => state.lotto_fondo
  );

  const materialeOptions = useMemo(
    () =>
      !!materialeList && !!materialeItems
        ? materialeList.map((item) => materialeItems[item])
        : [],
    [materialeItems, materialeList]
  );
  const fondoOptions = useMemo(
    () =>
      !!fondoList && !!fondoItems
        ? fondoList.map((item) => fondoItems[item])
        : [],
    [fondoItems, fondoList]
  );

  const onMaterialeChange = (event: SelectChangeEvent<string>) => {
    setIdMateriale(event.target.value);
  };
  const onFondoChange = (event: SelectChangeEvent<string>) => {
    setIdFondo(event.target.value);
  };
  const onUtilizzoChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUtilizzo(event.target.value);
  };
  const onFinitureChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFiniture(event.target.value);
  };
  const onDensitaChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDensita(event.target.value);
  };
  const onResistenzaCompressioneChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setResistenzaCompressione(event.target.value);
  };
  const onResistenzaFlessioneChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setResistenzaFlessione(event.target.value);
  };
  const onCoefImbibizioneChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCoefImbibizione(event.target.value);
  };
  const onDescrizioneChange = (event: ChangeEvent<HTMLInputElement>) => {
    setDescrizione(event.target.value);
  };

  const submit = () => {
    setFormData({
      idMateriale: parseInt(idMateriale) || undefined,
      idFondo: parseInt(idFondo) || undefined,
      utilizzo,
      finiture,
      densita: parseFloat(densita) || undefined,
      resistenzaCompressione: parseFloat(resistenzaCompressione) || undefined,
      resistenzaFlessione: parseFloat(resistenzaFlessione) || undefined,
      coefImbibizione: parseFloat(coefImbibizione) || undefined,
      descrizione,
    });
  };

  const back = () => {
    navigate("..");
  };
  useQualitaUpdate(qualitaId, formData);
  useGetQualita(qualitaId);
  useEffect(() => {
    if (qualita?.nome) setNome(qualita.nome);
    if (qualita?.ext?.descrizione) setDescrizione(qualita.ext.descrizione);
    if (qualita?.ext?.idMateriale)
      setIdMateriale(String(qualita.ext.idMateriale));
    if (qualita?.ext?.idFondo) setIdFondo(String(qualita.ext.idFondo));
    if (qualita?.ext?.utilizzo) setUtilizzo(qualita.ext.utilizzo);
    if (qualita?.ext?.densita) setDensita(String(qualita.ext.densita));
    if (qualita?.ext?.resistenzaCompressione)
      setResistenzaCompressione(String(qualita.ext.resistenzaCompressione));
    if (qualita?.ext?.resistenzaFlessione)
      setResistenzaFlessione(String(qualita.ext.resistenzaFlessione));
    if (qualita?.ext?.coefImbibizione)
      setCoefImbibizione(String(qualita.ext.coefImbibizione));
  }, [qualita]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        backgroundColor: "#fff",
      }}
    >
      <Box sx={{ flex: 1, position: "relative" }}>
        <Box
          sx={{
            backgroundColor: "#fff",
            boxSizing: "border-box",
            position: "absolute",
            height: "100%",
            width: "100%",
            overflowY: "auto",
          }}
        >
          <Box sx={{ p: 2 }}>
            <Typography variant="subtitle1">Modifica Qualità</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", padding: 2 }}>
            <Box sx={{ display: "flex" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  pr: 1,
                }}
              >
                <TextField
                  margin="dense"
                  label={t(`${i18_PATH}Nome`)}
                  type="text"
                  value={nome}
                  disabled
                />
                <FormControl fullWidth sx={{ mt: 1, mb: 0.5 }}>
                  <InputLabel id="materiale_label">Materiale</InputLabel>
                  <Select
                    labelId="materiale_label"
                    id="materiale_select"
                    value={idMateriale}
                    label={t(`${i18_PATH}Materiale`)}
                    onChange={onMaterialeChange}
                  >
                    {materialeOptions.map((item) => (
                      <MenuItem key={String(item.id)} value={item.id}>
                        {item.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mt: 1, mb: 0.5 }}>
                  <InputLabel id="fondo_label">Fondo</InputLabel>
                  <Select
                    labelId="fondo_label"
                    id="fondo_select"
                    value={idFondo}
                    label={t(`${i18_PATH}Colore`)}
                    onChange={onFondoChange}
                  >
                    {fondoOptions.map((item) => (
                      <MenuItem key={String(item.id)} value={item.id}>
                        {item.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  px: 1,
                }}
              >
                <TextField
                  margin="dense"
                  label={t(`${i18_PATH}Utilizzo`)}
                  type="text"
                  value={utilizzo}
                  onChange={onUtilizzoChange}
                />
                <TextField
                  margin="dense"
                  label={t(`${i18_PATH}Finiture`)}
                  type="text"
                  value={finiture}
                  onChange={onFinitureChange}
                />
                <TextField
                  margin="dense"
                  label={t(`${i18_PATH}Densita`)}
                  type="number"
                  value={densita}
                  onChange={onDensitaChange}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  pl: 1,
                }}
              >
                <TextField
                  margin="dense"
                  label={t(`${i18_PATH}ResistenzaCompressione`)}
                  type="number"
                  value={resistenzaCompressione}
                  onChange={onResistenzaCompressioneChange}
                />
                <TextField
                  margin="dense"
                  label={t(`${i18_PATH}ResistenzaFlessione`)}
                  type="number"
                  value={resistenzaFlessione}
                  onChange={onResistenzaFlessioneChange}
                />
                <TextField
                  margin="dense"
                  label={t(`${i18_PATH}CoefImbibizione`)}
                  type="number"
                  value={coefImbibizione}
                  onChange={onCoefImbibizioneChange}
                />
              </Box>
            </Box>
            {/** da sostituire con un editor complesso */}
            <Box sx={{ display: "flex" }}>
              <TextField
                sx={{ flex: 2 }}
                margin="dense"
                label={t(`${i18_PATH}Descrizione`)}
                type="text"
                value={descrizione}
                onChange={onDescrizioneChange}
                multiline
                rows={10}
              />
              <Box sx={{ flex: 1 }}>
                <ImageUpload idQualita={qualitaId} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          padding: 2,
          border: "1px solid rgba(224, 224, 224, 1)",
        }}
      >
        <Button onClick={back}>{t(`${i18_PATH}Cancella`)}</Button>
        <Button variant="contained" onClick={submit}>
          {t(`${i18_PATH}Salva`)}
        </Button>
      </Box>
    </Box>
  );
}

export default Update;
