import { createAction } from '@reduxjs/toolkit';
import { IStatoLavorazione } from '../../../../interfaces/dettaglio/attributes/models';
import { EMPTY, GET, IStatoLavorazioneState, LIST } from './types';

export const listAction = createAction<IStatoLavorazioneState>(LIST);

export const getAction = createAction<IStatoLavorazione>(GET);

export const emptyAction = createAction(EMPTY);

