import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";
import { emptyAction, errorAction, getAction, getUnknownAction } from "./actions";
import { initialState } from "./types";

export default createReducer(initialState, (builder) => {
	builder.addCase(errorAction, (state, action) => produce(state, draft => {
		const error = action.payload
		draft.error = error;
		draft.user = null;
	}));
	builder.addCase(getAction, (state, action) => produce(state, draft => {
		const user = action.payload
		draft.user = user;
		draft.isLogged = !!user;
		draft.unknown = false;
		draft.error = null;
	}));
	builder.addCase(getUnknownAction, (state, action) => produce(state, draft => {
		const user = action.payload
		draft.user = user;
		draft.isLogged = !!user;
		draft.unknown = true;
		draft.error = null;
	}));
	builder.addCase(emptyAction, () => initialState);
})