import * as React from 'react';
import { errorHandler } from '../../error/handler';
import { getUserGroup, getUserGroupList, removeUserGroup, storeUserGroup, updateUserGroup } from '../../fetch/user/group';
import { removeGroupPermissions, storeGroupPermissions, updateGroupPermissions } from '../../fetch/user/permissions';
import { UserGroupStoreFormData, UserGroupUpdateFormData } from '../../interfaces/user/forms';
import { IUserGroup } from '../../interfaces/user/models';
import { normalizer } from '../../lib/normalizer';
import { useAppDispatch } from '../../store/hooks';
import { endActionToDoAction, messageAction, startActionToDoAction } from '../../store/ui/actions';
import { emptyAction, getAction, listAction } from '../../store/user/userGroup/actions';
import { IUserGroupState } from '../../store/user/userGroup/types';

export const useUserGroupGet = (id: number | null) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!id) return;
			const actionToDo = 'get-user-group';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const userGroup = await getUserGroup(id);
				dispatch(getAction(userGroup));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id])
}

export const useUserGroupList = (query: number) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!query) return;
			const actionToDo = 'list-user-group';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const { results, items: _items } = await getUserGroupList();
				const { list, items } = normalizer<number, IUserGroup>(_items, "id");
				const actionPayload: IUserGroupState = {
					results,
					list,
					items
				}
				dispatch(listAction(actionPayload));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
			return () => {
				dispatch(emptyAction());
			}
		}
		effect();
	}, [dispatch, query])
}

export const useUserGroupStore = (data: UserGroupStoreFormData) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!data) return;
			const { userGroup, permissions } = data;
			if (!(!!userGroup && !!permissions)) return;
			const actionToDo = 'store-user-group';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const { id: id_groupPermissions } = await storeGroupPermissions(permissions);
				await storeUserGroup({ ...userGroup, id_groupPermissions });
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(messageAction({ message: "Gruppo utente aggiunto", kind: "success" }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, data]);
}

export const useUserGroupUpdate = (userGroupId: number | null, groupPermissionsId: number | null, data: UserGroupUpdateFormData) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!(!!userGroupId && !!groupPermissionsId && !!data)) return;
			const { userGroup, permissions } = data;
			if (!(!!userGroup && !!permissions)) return;
			const actionToDo = 'update-user-group';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				await updateUserGroup(userGroupId, userGroup);
				await updateGroupPermissions(groupPermissionsId, permissions);
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(messageAction({ message: "Gruppo utente aggiornato", kind: "success" }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, userGroupId, groupPermissionsId, data]);
}

export const useUserGroupRemove = (userGroupId: number | null, groupPermissionsId: number | null,) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!(!!userGroupId && !!groupPermissionsId)) return;
			const actionToDo = 'remove-user-group';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				await removeGroupPermissions(groupPermissionsId);
				await removeUserGroup(userGroupId);
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(messageAction({ message: "Utente rimosso", kind: "success" }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, userGroupId, groupPermissionsId]);
}
