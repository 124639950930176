import { Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserGroupStore } from "../../../effects/user/userGroup";
import { EClientsViews, EProspectsViews } from "../../../interfaces/user/enums";
import { UserGroupStoreFormData } from "../../../interfaces/user/forms";
import { useAppSelector } from "../../../store/hooks";

function Store() {
	const navigate = useNavigate();
	const [formData, setFormData] = useState<UserGroupStoreFormData>({ userGroup: null, permissions: null });
	const [nome, setNome] = useState<string>("");
	const [nomeError, setNomeError] = useState<string | null>(null);
	const [descrizione, setDescrizione] = useState<string>("");
	const [canViewUsers, setCanViewUsers] = useState<boolean>(false);
	const [canViewCosts, setCanViewCosts] = useState<boolean>(false);
	const [canViewCommercials, setCanViewCommercials] = useState<boolean>(false);
	const [canViewStoragePlace, setCanViewStoragePlace] = useState<boolean>(false);
	const [canViewQuarry, setCanViewQuarry] = useState<boolean>(false);;
	const [canViewBlockHistory, setCanViewBlockHistory] = useState<boolean>(false);
	const [canViewCommercialOptions, setCanViewCommercialOptions] = useState<boolean>(false);
	const [canSetUsers, setCanSetUsers] = useState<boolean>(false);
	const [canSetCommercials, setCanSetCommercials] = useState<boolean>(false);
	const [canSetStoragePlace, setCanSetStoragePlace] = useState<boolean>(false);
	const [canSetFeatures, setCanSetFeatures] = useState<boolean>(false);
	const [canSetCommercialOptions, setCanSetCommercialOptions] = useState<boolean>(false);
	const [canShare, setCanShare] = useState<boolean>(false);
	const [prospectsViews, setProspectsViews] = useState<EProspectsViews>(EProspectsViews.none);
	const [clientsViews, setClientsViews] = useState<EClientsViews>(EClientsViews.none);
	const lastActionDone = useAppSelector((state) => state.ui.lastActionDone);

	useEffect(() => {
		if (lastActionDone === "store-user-group") navigate('..');
	}, [lastActionDone, navigate]);

	useUserGroupStore(formData);

	const onNomeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNome(event.target.value);
	};

	const onDescrizioneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDescrizione(event.target.value);
	};

	const onCanViewUsersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCanViewUsers(event.target.checked);
	}

	const onCanViewCostsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCanViewCosts(event.target.checked);
	}

	const onCanViewCommercialsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCanViewCommercials(event.target.checked);
	}

	const onCanViewStoragePlaceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCanViewStoragePlace(event.target.checked);
	}

	const onCanViewQuarryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCanViewQuarry(event.target.checked);
	}

	const onCanViewBlockHistoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCanViewBlockHistory(event.target.checked);
	}

	const onCanViewCommercialOptionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCanViewCommercialOptions(event.target.checked);
	}

	const onCanSetUsersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCanSetUsers(event.target.checked);
	}

	const onCanSetCommercialsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCanSetCommercials(event.target.checked);
	}

	const onCanSetStoragePlaceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCanSetStoragePlace(event.target.checked);
	}

	const onCanSetFeaturesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCanSetFeatures(event.target.checked);
	}

	const onCanSetCommercialOptionsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCanSetCommercialOptions(event.target.checked);
	}

	const onCanShareChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCanShare(event.target.checked);
	}

	const onProspectsViewChange = (event: SelectChangeEvent) => {
		setProspectsViews(event.target.value as EProspectsViews);
	}

	const onClientsViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) setClientsViews(EClientsViews.all);
		else setClientsViews(EClientsViews.none);
	}

	const checkError = (): boolean => {
		let errors = 0;
		if (!nome) {
			setNomeError('Campo obbligatorio');
			errors++;
		} else setNomeError(null);
		return errors > 0;
	}

	const submit = () => {
		if (!checkError()) {
			const data: UserGroupStoreFormData = {
				userGroup: {
					nome,
					descrizione
				},
				permissions: {
					canViewUsers,
					canViewCosts,
					canViewCommercials,
					canViewStoragePlace,
					canViewQuarry,
					canViewBlockHistory,
					canViewCommercialOptions,
					canSetUsers,
					canSetCommercials,
					canSetStoragePlace,
					canSetFeatures,
					canSetCommercialOptions,
					canShare,
					prospects: prospectsViews,
					clients: clientsViews,
				}
			};
			setFormData(data);
		}
	}

	const back = () => {
		navigate('..');
	}
	return (
		<Box sx={{ display: "flex", flexDirection: "column", flex: 1, backgroundColor: "#fff" }}>
			<Box sx={{ flex: 1, position: "relative" }}>
				<Box sx={{ backgroundColor: "#fff", boxSizing: "border-box", position: "absolute", height: "100%", width: "100%", overflowY: "auto" }}>
					<Box sx={{ p: 2 }}>
						<Typography variant="subtitle1">Aggiungi Nuovo Gruppo Utente</Typography>
					</Box>
					<Box
						sx={{ display: "flex", flexDirection: "column", padding: 2 }}
					>
						<TextField
							required={true}
							margin="dense"
							label="Nome"
							type="text"
							error={!!nomeError}
							helperText={nomeError}
							value={nome}
							onChange={onNomeChange}
						/>
						<TextField
							margin="dense"
							label="Descrizione"
							type="text"
							value={descrizione}
							onChange={onDescrizioneChange}
						/>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={canViewUsers}
										onChange={onCanViewUsersChange}
									/>
								}
								label="Può vedere la lista degli utenti?"
							/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={canSetUsers}
										onChange={onCanSetUsersChange}
									/>
								}
								label="Può creare un nuovo utente o modificare un utente esistente?"
							/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={canViewCosts}
										onChange={onCanViewCostsChange}
									/>
								}
								label="Può vedere i costi?"
							/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={canViewCommercials}
										onChange={onCanViewCommercialsChange}
									/>
								}
								label="Può vedere i prezzi e le informazioni commerciali di un lotto in vendita?"
							/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={canSetCommercials}
										onChange={onCanSetCommercialsChange}
									/>
								}
								label="Può modificare i prezzi e le informazioni commercial di un lotto in vendita?"
							/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={canViewStoragePlace}
										onChange={onCanViewStoragePlaceChange}
									/>
								}
								label="Può vedere il luogo di stoccaggio (magazzino, posizione, zona, fila)?"
							/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={canSetStoragePlace}
										onChange={onCanSetStoragePlaceChange}
									/>
								}
								label="Può modificare il luogo di stoccaggio nel magazzino (zona, fila)?"
							/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={canSetFeatures}
										onChange={onCanSetFeaturesChange}
									/>
								}
								label="Può modificare le caratteristiche qualitative del lotto?"
							/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={canViewQuarry}
										onChange={onCanViewQuarryChange}
									/>
								}
								label="Può visualizzare la cava di provenienza?"
							/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={canViewBlockHistory}
										onChange={onCanViewBlockHistoryChange}
									/>
								}
								label="Può visualizzare la storia di un lotto?"
							/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={canViewCommercialOptions}
										onChange={onCanViewCommercialOptionsChange}
									/>
								}
								label="Può vedere le informazioni sulle opzioni di vendita?"
							/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={canSetCommercialOptions}
										onChange={onCanSetCommercialOptionsChange}
									/>
								}
								label="Può modificare opzionare e/o impegnare un prodotto per la vendita?"
							/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={canShare}
										onChange={onCanShareChange}
									/>
								}
								label="Può condividere un lotto con utenti esterni?"
							/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={clientsViews === EClientsViews.all}
										onChange={onClientsViewChange}
									/>
								}
								label="Può consultare la lista clienti?"
							/>
						</FormGroup>
						<FormControl
							fullWidth sx={{ mt: 1, mb: .5 }}
						>
							<InputLabel id="prospects-label">Visualizza i prospects</InputLabel>
							<Select
								labelId="prospects-label"
								value={prospectsViews}
								label="Visualizza i prospect"
								onChange={onProspectsViewChange}
							>
								<MenuItem value={EProspectsViews.none}>
									Non può visualizzare
								</MenuItem>
								<MenuItem value={EProspectsViews.mine}>
									Visualizza solo i propri
								</MenuItem>
								<MenuItem value={EProspectsViews.all}>
									Visualizza tutti
								</MenuItem>
							</Select>
						</FormControl>
					</Box>
				</Box>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "flex-end", padding: 2, border: "1px solid rgba(224, 224, 224, 1)" }}>
				<Button onClick={back} >Cancella</Button>
				<Button variant="contained" onClick={submit}>Salva</Button>
			</Box>
		</Box>
	)
}

export default Store;