import { IUserGroup } from "../../../interfaces/user/models";

export const GET = "userGroup/get";
export const LIST = "userGroup/list";
export const EMPTY = "userGroup/empty";

export interface IUserGroupState {
	results: number;
	list: number[];
	items: Record<number, IUserGroup> | null;
};

export const initialState: IUserGroupState = {
	results: 0,
	list: [],
	items: null
};