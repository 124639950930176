import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getColumnVisibilityModel,
  storeColumnVisibilityModel,
} from "../../../../../lib/functions";
import { createColumns } from "./columns";
import { ITableProps } from "./interfaces";

function Table({ rows, onEdit }: ITableProps) {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState<number>(25);
  const columns = useMemo(() => createColumns({ t, onEdit }), [t, onEdit]);
  const columnVisibilityModel = useMemo(
    () => getColumnVisibilityModel("ProspectColumnVisibilityModel") ?? {},
    []
  );
  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
      {!!columnVisibilityModel && (
        <DataGrid
          rows={rows}
          columns={columns}
          columnVisibilityModel={columnVisibilityModel || undefined}
          onColumnVisibilityModelChange={(newModel) =>
            storeColumnVisibilityModel(
              "ProspectColumnVisibilityModel",
              newModel
            )
          }
          rowsPerPageOptions={[25, 50, 100]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          pageSize={pageSize}
          disableSelectionOnClick
        />
      )}
    </Box>
  );
}

export default Table;
