import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Icon,
  IconButton,
  List,
  Toolbar,
  Typography,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useQualitaImageUpload } from "../../../../../effects/qualitaImage/qualitaImage";
import { QualitaImageUploadFormData } from "../../../../../interfaces/lotto/attributes/forms";
import Transition from "../../../../../lib/dialogTransition";
import { useAppSelector } from "../../../../../store/hooks";

import FileToUpload from "./fileToUpload";
import { IFileExt, IUploadDialogProps } from "./interfaces";

const i18_PATH = "qualita.update.";

function UploadDialog({ isOpen, idQualita, close }: IUploadDialogProps) {
  const { t } = useTranslation();
  const uploadProgress = useAppSelector((state) => state.ui.uploadProgress);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [images, setImages] = useState<IFileExt[]>([]);
  const [data, setData] = useState<QualitaImageUploadFormData>(null);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setImages(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const removeAllFiles = () => setImages([]);
  const cancel = () => {
    removeAllFiles();
    close();
  };
  const upload = () => {
    if (!!idQualita && images.length === 1) {
      setIsUploading(true);
      setData({ idQualita, image: images[0] });
    }
  };
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () =>
      images.forEach((image) => URL.revokeObjectURL((image as any).preview));
  }, [images]);
  useEffect(() => {
    setData(null);
    setImages([]);
    setIsUploading(false);
  }, [isOpen, idQualita]);
  useQualitaImageUpload(data);
  return (
    <Dialog open={isOpen} TransitionComponent={Transition}>
      <AppBar color="transparent" sx={{ boxShadow: "none" }}>
        <Toolbar variant="dense">
          <Box sx={{ flex: 1 }} />
          <IconButton
            edge="end"
            color="primary"
            onClick={close}
            aria-label="close"
          >
            <Icon className="fa-light fa-xmark" sx={{ overflow: "visible" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2 }}>
        <Box className="container">
          <Box
            sx={{
              border: "1px dotted grey",
              boxSizing: "border-box",
              p: 1,
              height: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            {...getRootProps({})}
          >
            <input {...getInputProps()} />
            <Typography>{t(`${i18_PATH}UploadInfo`)}</Typography>
          </Box>
          <List>
            {images.map((file: IFileExt, index: number) => (
              <FileToUpload
                file={file}
                key={file.name}
                progress={uploadProgress[index] || 0}
                isUploading={isUploading}
              />
            ))}
          </List>
        </Box>
      </Box>
      <DialogActions>
        <Button variant="outlined" onClick={cancel}>
          {t(`${i18_PATH}Cancella`)}
        </Button>
        <Button variant="contained" onClick={upload}>
          {t(`${i18_PATH}Upload`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UploadDialog;
