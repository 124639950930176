import * as React from 'react';
import { errorHandler } from '../../../error/handler';
import { getCategoria, getCategoriaList } from '../../../fetch/lotto/attributes/categoria';
import { ICategoria } from '../../../interfaces/lotto/attributes/models';
import { normalizer } from '../../../lib/normalizer';
import { useAppDispatch } from '../../../store/hooks';
import { emptyAction, getAction, listAction } from '../../../store/lotto/attributes/categoria/actions';
import { ICategoriaState } from '../../../store/lotto/attributes/categoria/types';
import { endActionToDoAction, startActionToDoAction } from '../../../store/ui/actions';

export const useGetCategoria = (id: number) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!id) return;
			const actionToDo = 'get-categoria';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const categoria = await getCategoria(id);
				dispatch(getAction(categoria));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id])
}

export const useCategoriaList = (query: number) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!query) return;
			const actionToDo = 'list-categoria';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const { results, items: _items } = await getCategoriaList();
				_items.sort((itemA, itemB) => (!!itemA?.ordinamento && !!itemB.ordinamento) ? itemA.ordinamento - itemB.ordinamento : 0);
				const { list, items } = normalizer<number, ICategoria>(_items, "id");
				const actionPayload: ICategoriaState = {
					results,
					list,
					items
				}
				dispatch(listAction(actionPayload));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
			return () => {
				dispatch(emptyAction());
			}
		}
		effect();
	}, [dispatch, query])
}
