import { IDettaglio } from "../../../interfaces/dettaglio/models";

export const GET = "dettaglio/get";
export const LIST = "dettaglio/list";
export const LIST_SOLD = "dettaglio/list_sold";
export const EMPTY = "dettaglio/empty";

export interface IDettaglioState {
  results: number;
  soldResults: number;
  list: number[];
  soldList: number[];
  items: Record<number, IDettaglio> | null;
  soldItems: Record<number, IDettaglio> | null;
}

export interface IDettaglioListPayload {
  results: number;
  list: number[];
  items: Record<number, IDettaglio> | null;
}

export interface IDettaglioListSoldPayload {
  soldResults: number;
  soldList: number[];
  soldItems: Record<number, IDettaglio> | null;
}

export const initialState: IDettaglioState = {
  results: 0,
  soldResults: 0,
  list: [],
  soldList: [],
  items: null,
  soldItems: null,
};
