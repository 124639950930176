import { AppBar, Box, Button, Dialog, DialogActions, FormControl, Icon, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, TextFieldProps, Toolbar, Typography } from "@mui/material";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import moment, { Moment } from "moment";
import 'moment/locale/it';
import { useEffect, useState } from "react";
import { useDettaglioUpdateCommercialOptions } from "../../../../../../../effects/dettaglio/dettaglio";
import { IDisponibilita } from "../../../../../../../interfaces/dettaglio/attributes/models";
import { DettaglioUpdateCommercialOptionsFormData } from "../../../../../../../interfaces/dettaglio/forms";
import { IDettaglio } from "../../../../../../../interfaces/dettaglio/models";
import Transition from "../../../../../../../lib/dialogTransition";
import { useAppSelector } from "../../../../../../../store/hooks";
import { ICommercialOptionsDialogProps } from "../interfaces";

const locale = "it-IT";

function DettaglioCommercialOptionsDialog({ isOpen, lottoId, selectedItems, canSetCommercialOptions, onClose }: ICommercialOptionsDialogProps) {
	const [ids, setIds] = useState<number[]>([]);
	const [initValue, setInitValue] = useState<IDettaglio | null>(null);
	const [formData, setFormData] = useState<DettaglioUpdateCommercialOptionsFormData>(null);
	const [idDisponibilita, setIdDisponibilita] = useState<string>();
	const [opzCliente, setOpzCliente] = useState<string>();
	const [opzNote, setOpzNote] = useState<string>();
	const [opzScandeza, setOpzScadenza] = useState<Moment | null>();
	const [disponibilitaOptions, setDisponibilitaOptions] = useState<IDisponibilita[]>([])

	const disponibilitaList = useAppSelector((state) => state.dettaglio_disponibilita.list);
	const disponibilitaItems = useAppSelector((state) => state.dettaglio_disponibilita.items);

	const onIdDisponibiltaChange = (event: SelectChangeEvent) => {
		const _idDisponibilta = parseInt(event.target.value);
		setIdDisponibilita(String(_idDisponibilta));
		if (_idDisponibilta > 2) {
			setOpzCliente("");
			setOpzNote("");
		}
		if (_idDisponibilta !== 1) {
			setOpzScadenza(null);
		}
	}

	const onOpzClienteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setOpzCliente(event.target.value);
	};

	const onOpzScadenzaChange = (newValue: Moment | null) => {
		setOpzScadenza(newValue)
	};

	const onOpzNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setOpzNote(event.target.value);
	};

	const close = () => {
		setInitValue(null);
		setFormData(null);
		onClose();
	}

	const onSubmit = () => {
		if (!ids.length) return;
		const _idDisponibilita = (!!idDisponibilita) ? parseInt(idDisponibilita) : null;
		const _opzCliente = !!_idDisponibilita && _idDisponibilita <= 2 ? opzCliente : null;
		const _opzNote = !!_idDisponibilita && _idDisponibilita <= 2 ? opzNote : null;
		//const _ozpScadenza = !!_idDisponibilita && _idDisponibilita === 1 && moment.isMoment(opzScandeza) ? opzScandeza.format('YYYY-MM-DD') : null;
		const _formData: DettaglioUpdateCommercialOptionsFormData = {
			idDisponibilita: _idDisponibilita,
			opzCliente: _opzCliente,
			//opzScadenza: _ozpScadenza,
			opzNote: _opzNote,
		}
		setFormData(_formData);
		onClose();
	}

	useEffect(() => {
		setFormData(null);
		if (selectedItems.length === 1) {
			setInitValue(selectedItems[0]);
		} else {
			setInitValue(null);
		}
		const _ids = selectedItems.map(item => item.id);
		setIds(_ids);
	}, [selectedItems, isOpen])

	useEffect(() => {
		if (!!initValue) {
			setIdDisponibilita(String(initValue.idDisponibilita || ""));
			setOpzCliente(String(initValue.opzCliente));
			setOpzNote(String(initValue.opzNote));
			if (!!initValue.opzScadenza) setOpzScadenza(moment(initValue.opzScadenza));
		} else {
			setIdDisponibilita("");
			setOpzCliente("");
			setOpzNote("");
			setOpzScadenza(null);
		}
	}, [initValue]);

	useEffect(() => {
		const _disponibilitaOptions: IDisponibilita[] = (!!disponibilitaList && !!disponibilitaItems) ? disponibilitaList.map(id => disponibilitaItems[id]) : [];
		setDisponibilitaOptions(_disponibilitaOptions);
	}, [disponibilitaList, disponibilitaItems]);
	useDettaglioUpdateCommercialOptions(lottoId, ids, formData);
	return (
		<Dialog
			open={isOpen}
			onClose={close}
			TransitionComponent={Transition}
			fullWidth
			maxWidth="md"
		>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar variant="dense">
					<Typography variant="h5" sx={{ flex: 1 }} display="block" >Opzioni di vendita</Typography>
					<IconButton
						edge="end"
						color="inherit"
						onClick={close}
						aria-label="close"
					>
						<Icon className="fa-light fa-xmark" sx={{ overflow: "visible" }} />
					</IconButton>
				</Toolbar>
			</AppBar>
			<Box sx={{ p: 2, overflowY: 'auto' }}>
				<Box sx={{ display: "flex" }}>
					<FormControl
						sx={{ mt: 1, mb: .5, mr: 1, flex: 1 }}
					>
						<InputLabel id="prospects-label">Disponibilità</InputLabel>
						<Select
							labelId="prospects-label"
							value={idDisponibilita}
							label="Disponibilità"
							onChange={onIdDisponibiltaChange}
						>
							{disponibilitaOptions.map(opt => (
								<MenuItem key={String(opt.id)} value={String(opt.id)}>
									{opt.nome}
								</MenuItem>
							))}
						</Select>
					</FormControl>
					{(!!idDisponibilita && parseInt(idDisponibilita) <= 2) &&
						<TextField
							margin="dense"
							label="Cliente"
							type="text"
							name="cliente"
							value={opzCliente}
							sx={{ mr: 1, flex: 1 }}
							onChange={onOpzClienteChange}
						/>
					}
					{(!!idDisponibilita && parseInt(idDisponibilita) === 1 && false) &&
						<LocalizationProvider dateAdapter={AdapterMoment} locale={locale}>
							<MobileDatePicker
								label="Scandenza opzione"
								value={opzScandeza}
								inputFormat="DD/MM/YYYY"
								onChange={onOpzScadenzaChange}
								renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField
									name="opzScandeza"
									sx={{ mt: 1 }}
									{...params} />}
							/>
						</LocalizationProvider>
					}
				</Box>
				{(!!idDisponibilita && parseInt(idDisponibilita) <= 2) &&
					<Box sx={{ display: "flex" }}>
						<TextField
							margin="dense"
							label="Note"
							type="text"
							multiline
							rows={4}
							value={opzNote}
							onChange={onOpzNoteChange}
							fullWidth
						/>
					</Box>
				}
			</Box>
			<DialogActions>
				<Button onClick={close}>Cancella</Button>
				<Button disabled={!canSetCommercialOptions} onClick={onSubmit} variant="contained" color="primary">Salva</Button>
			</DialogActions>
		</Dialog>
	)
}

export default DettaglioCommercialOptionsDialog;