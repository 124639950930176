import { settings } from "../../customize/settings";
import { IBaseError } from "../../interfaces/errors";
import { IDeleteResponse, IStoreResponse, IUpdateResponse } from "../../interfaces/rest";
import { IGroupPermissions, IGroupPermissionsStore, IGroupPermissionsUpdate } from "../../interfaces/user/models";
import { getJWT } from "../../lib/jwt";

const BASE_URL = `${settings.apiUrl}user-group-permissions`

export const getGroupPermissions = async (id: number): Promise<IGroupPermissions> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IGroupPermissions | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IGroupPermissions;
}

export const storeGroupPermissions = async (data: IGroupPermissionsStore): Promise<IStoreResponse> => {
	const url = `${BASE_URL}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const body = JSON.stringify(data);
	const response = await fetch(url, {
		method: "POST",
		headers: headers,
		body
	});
	const json: IStoreResponse | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IStoreResponse;
}

export const updateGroupPermissions = async (id: number, data: IGroupPermissionsUpdate): Promise<IUpdateResponse> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const body = JSON.stringify(data);
	const response = await fetch(url, {
		method: "PUT",
		headers: headers,
		body
	});
	const json: IUpdateResponse | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IUpdateResponse;
}

export const removeGroupPermissions = async (id: number): Promise<IDeleteResponse> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "DELETE",
		headers: headers
	});
	const json: IDeleteResponse | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IDeleteResponse;
}