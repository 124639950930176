import { GridColumns } from "@mui/x-data-grid";
import { ICliente } from "../../../../../interfaces/cliente/models";

export const createColumns = (): GridColumns<ICliente> => [
	{
		field: 'codice',
		headerName: 'Codice'
	},
	{
		field: 'ragioneSociale',
		headerName: 'Ragione Sociale',
		width: 320
	},
	{
		field: 'indirizzo',
		headerName: 'Indirizzo',
		width: 240
	},
	{
		field: 'cap',
		headerName: 'Cap',
		type: "number",
		width: 80
	},
	{
		field: 'localita',
		headerName: 'Località',
		width: 160
	},
	{
		field: 'provincia',
		headerName: 'Provincia',
		width: 80
	},
	{
		field: 'mail',
		headerName: 'Email',
		width: 240
	},
	{
		field: 'telefono',
		headerName: 'Telefono',
		width: 240
	}
]