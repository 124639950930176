import { Box, Button, Container, Icon } from "@mui/material";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { LinkProps, Navigate, NavLink } from "react-router-dom";
import logo from "../../customize/assets/logo2.png";
import { settings } from "../../customize/settings";
import { EClientsViews, EProspectsViews } from "../../interfaces/user/enums";
import { useAppSelector } from "../../store/hooks";

const i18_PATH = "main.";

interface ICustomProp extends LinkProps {
  icon: ReactNode;
}

function CustomLink({ children, to, icon, ...props }: ICustomProp) {
  return (
    <Button
      color="primary"
      sx={{ mx: 1, my: 1, display: "flex" }}
      component={NavLink}
      variant="contained"
      to={to}
      startIcon={icon}
      size="large"
    >
      {children}
    </Button>
  );
}

function Main() {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth.user);

  if (user?.unknown && !!user.lotto_allowed)
    return <Navigate to={`/prodotti/${user.lotto_allowed}`} />;
  return (
    <Container
      sx={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ m: 1, userSelect: "none" }}>
        <img src={logo} alt="logo" height={200} />
      </Box>
      <Box sx={{ m: 1, display: "flex" }}>
        <CustomLink
          to="prodotti"
          icon={
            <Icon className="fa-light fa-list" sx={{ overflow: "visible" }} />
          }
        >
          {t(`${i18_PATH}Prodotti`)}
        </CustomLink>
        {settings.main?.soldList &&
          (user?.admin ||
            user?.user_group?.group_permissions?.canViewSoldList) && (
            <CustomLink
              to="venduto"
              icon={
                <Icon
                  className="fa-light fa-list-check"
                  sx={{ overflow: "visible" }}
                />
              }
            >
              {t(`${i18_PATH}Venduto`)}
            </CustomLink>
          )}
        {settings.main?.materialList && (
          <CustomLink
            to="materiali"
            icon={
              <Icon
                className="fa-light fa-object-group"
                sx={{ overflow: "visible" }}
              />
            }
          >
            {t(`${i18_PATH}Materiali`)}
          </CustomLink>
        )}
        {settings.main?.qualitaList && (
          <CustomLink
            to="qualita"
            icon={
              <Icon
                className="fa-light fa-circle-bookmark"
                sx={{ overflow: "visible" }}
              />
            }
          >
            {t(`${i18_PATH}Qualità`)}
          </CustomLink>
        )}
        {settings.main?.prospects &&
          (user?.admin ||
            user?.user_group?.group_permissions?.prospects !==
              EProspectsViews.none) && (
            <CustomLink
              to="prospects"
              icon={
                <Icon
                  className="fa-light fa-address-book"
                  sx={{ overflow: "visible" }}
                />
              }
            >
              {t(`${i18_PATH}Prospects`)}
            </CustomLink>
          )}
        {settings.main?.clients &&
          (user?.admin ||
            user?.user_group?.group_permissions?.clients !==
              EClientsViews.none) && (
            <CustomLink
              to="clienti"
              icon={
                <Icon
                  className="fa-light fa-book-user"
                  sx={{ overflow: "visible" }}
                />
              }
            >
              {t(`${i18_PATH}Clienti`)}
            </CustomLink>
          )}
      </Box>
    </Container>
  );
}

export default Main;
