import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Icon,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDettaglio } from "../../../../../interfaces/dettaglio/models";
import { ILottoImage } from "../../../../../interfaces/lotto/models";
import {
  addImagesToDettagli,
  getDetailsTableViewMode,
  storeDetailsTableViewMode,
} from "../../../../../lib/functions";
import DefaultGrid from "./grids/defaultGrid";
import { EShowColumnState, ILottoDettagliProps } from "./interfaces";
import ShowHideColumnsToolbar from "./showHideColumnsToolbar";

const i18_PATH = "prodotto.view.lottoDettagli.";

function LottoDettagli({
  lotto,
  user,
  selectedItems,
  setSelectedItems,
  viewImage,
}: ILottoDettagliProps) {
  const { t } = useTranslation();
  const [dettagli, setDettagli] = useState<IDettaglio[]>([]);
  const [showAdminColumns, setShowAdminColumns] =
    useState<EShowColumnState | null>(null);
  const [canViewCosts, setCanViewCosts] = useState<boolean>(false);
  const [canViewCommercials, setCanViewCommercials] = useState<boolean>(false);
  const [canViewStoragePlace, setCanViewStoragePlace] =
    useState<boolean>(false);
  const [canViewCommercialOptions, setCanViewCommercialOptions] =
    useState<boolean>(false);
  const [canShowHideColumns, setCanSetHideColumns] = useState<boolean>(false);

  const onViewImage = (image: ILottoImage) => {
    viewImage(image);
  };

  useEffect(() => {
    const _canViewCosts =
      !!user?.admin || !!user?.user_group?.group_permissions?.canViewCosts;
    /** price_allowed is for anonymous user */
    const _canViewCommercials =
      !!user?.admin ||
      !!user?.user_group?.group_permissions?.canViewCommercials ||
      !!user?.price_allowed;
    const _canViewStoragePlace =
      !!user?.admin ||
      !!user?.user_group?.group_permissions?.canViewStoragePlace;
    const _canViewCommercialOptions =
      !!user?.admin ||
      !!user?.user_group?.group_permissions?.canViewCommercialOptions;
    const _canSetHideColumns =
      _canViewCommercialOptions || _canViewCosts || _canViewStoragePlace;

    setCanViewCosts(_canViewCosts);
    setCanViewCommercials(_canViewCommercials);
    setCanViewStoragePlace(_canViewStoragePlace);
    setCanViewCommercialOptions(_canViewCommercialOptions);
    setCanSetHideColumns(_canSetHideColumns);

    const tmp = getDetailsTableViewMode();
    if (!!user?.unknown) setShowAdminColumns(EShowColumnState.SHOW_ALL);
    else if (!!tmp) setShowAdminColumns(tmp);
    else setShowAdminColumns(EShowColumnState.HIDE_ALL);
  }, [user]);
  useEffect(() => {
    if (user?.unknown) setSelectedItems(dettagli);
  }, [dettagli, user, setSelectedItems]);
  useEffect(() => {
    const _dettagli = lotto.dettagli ?? [];
    const _images = lotto.images ?? [];
    setDettagli(addImagesToDettagli(_dettagli, _images));
  }, [lotto]);
  useEffect(() => {
    if (showAdminColumns) storeDetailsTableViewMode(showAdminColumns);
  }, [showAdminColumns]);
  return (
    <>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={
            <Icon
              className="fa-light fa-angle-down"
              fontSize="small"
              sx={{ overflow: "visible" }}
            />
          }
          aria-controls="qualita-content"
          id="qualita-header"
        >
          <Typography variant="subtitle1">
            {t(`${i18_PATH}Dettaglio`)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            "& .hidden": {
              display: "none !important",
            },
          }}
        >
          {!!showAdminColumns && (
            <>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {canShowHideColumns && (
                  <ShowHideColumnsToolbar
                    showColumns={showAdminColumns}
                    onHide={() =>
                      setShowAdminColumns(EShowColumnState.HIDE_ALL)
                    }
                    onPartialHide={() =>
                      setShowAdminColumns(EShowColumnState.HIDE_PARTIAL)
                    }
                    onShow={() =>
                      setShowAdminColumns(EShowColumnState.SHOW_ALL)
                    }
                  />
                )}
              </Box>
              <DefaultGrid
                dettagli={dettagli}
                selectedItems={selectedItems}
                canViewCosts={canViewCosts}
                canViewCommercials={canViewCommercials}
                canViewStoragePlace={canViewStoragePlace}
                canViewCommercialOptions={canViewCommercialOptions}
                showAdminColumns={showAdminColumns}
                onSelectedItemsChange={(selectedItems) =>
                  setSelectedItems(selectedItems)
                }
                onViewImage={onViewImage}
              />
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default LottoDettagli;
