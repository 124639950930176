import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { useAppSelector } from "../../../store/hooks";

function WaitingUI() {
	const actionsToDo = useAppSelector((state) => state.ui.actionsToDo);
	const isWaiting = Boolean(actionsToDo.length);
	if (isWaiting) return (
		<Box sx={{ position: "fixed", height: "100%", width: "100%", zIndex: 999999, backgroundColor: "rgb(74 74 74 / 40%)", flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
			<CircularProgress />
		</Box>
	);
	else return null;
}

export default WaitingUI;
