import { IBaseError } from "../interfaces/errors";
import { store } from "../store";
import { getAction } from "../store/auth/actions";
import { endActionToDoAction, messageAction } from "../store/ui/actions";

export const errorHandler = (err: unknown, actionToDo: string) => {
	const error = err as IBaseError;
	console.error(error)
	store.dispatch(endActionToDoAction({ actionToDo, success: false }));
	const message = `Error! - ${error.error} - ${error.message}`
	store.dispatch(messageAction({ message, kind: "error" }));
	if (error.error === 401) store.dispatch(getAction(null));
}