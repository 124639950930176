import { AlertColor } from "@mui/material";

export const START_ACTION = "ui/start_action";
export const END_ACTION = "ui/end_action";
export const MESSAGE_ACTION = "ui/message_action";
export const PROGRESS_UPLOAD_START_ACTION = "ui/progress_upload_start_action";
export const PROGRESS_UPLOAD_UPDATE_ACTION = "ui/progress_upload_update_action";
export const PROGRESS_UPLOAD_CLEAN_ACTION = "ui/progress_upload_clean_action";
export const EMPTY = "ui/empty";

export interface IUIState {
	actionsToDo: string[];
	lastActionDone: string | null;
	lastActionError: string | null;
	message: Message;
	uploadProgress: number[]
};

export interface IUIMessage {
	message: string;
	kind: AlertColor;
}

export interface IUIEndAction {
	actionToDo: string;
	success: boolean;
}

export interface IUIStartAction {
	actionToDo: string;
}

export interface IUIStartUploadProgressAction {
	numberOfFiles: number;
}

export interface IUIUpdateProgressUploadAction {
	index: number;
	progress: number;
}

export interface IUICleanUploadProgressAction { }

export const initialState: IUIState = {
	actionsToDo: [],
	lastActionDone: null,
	lastActionError: null,
	message: null,
	uploadProgress: []
};

export type Message = IUIMessage | null;