import { IStatoLavorazione } from "../../../../interfaces/dettaglio/attributes/models";

export const GET = "stato_lavorazione/get";
export const LIST = "stato_lavorazione/list";
export const EMPTY = "stato_lavorazione/empty";

export interface IStatoLavorazioneState {
	results: number;
	list: number[];
	items: Record<number, IStatoLavorazione> | null;
};

export const initialState: IStatoLavorazioneState = {
	results: 0,
	list: [],
	items: null
};