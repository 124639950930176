import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Icon,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { ChangeEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { settings } from "../../../../../../../customize/settings";
import { useImageUpdate } from "../../../../../../../effects/image/image";
import { LottoImageUpdateFormData } from "../../../../../../../interfaces/lotto/forms";
import Transition from "../../../../../../../lib/dialogTransition";
import { IUpdateDialogProps } from "./interface";

const i18_PATH = "prodotto.view.lottoImages.dialog.updateDialog.";

function UpdateDialog({ isOpen, isBlock, image, close }: IUpdateDialogProps) {
  const { t } = useTranslation();
  const [id, setId] = useState<number | null>(null);
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string | null>(null);
  const [order, setOrder] = useState<string>("");
  const [bundle, setBundle] = useState<string>("");
  const [slab, setSlab] = useState<string>("");
  const [data, setData] = useState<LottoImageUpdateFormData>(null);
  const onNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const onBundleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBundle(event.target.value);
  };
  const onSlabChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSlab(event.target.value);
  };
  const onOrderChange = (event: ChangeEvent<HTMLInputElement>) => {
    setOrder(event.target.value);
  };
  const checkError = (): boolean => {
    let errors = 0;
    if (!name) {
      setNameError(t(`${i18_PATH}Campo obbligatorio`));
      errors++;
    } else setNameError(null);
    return errors > 0;
  };
  const onSubmit = () => {
    if (!id) return;
    if (checkError()) return;
    let _bundle: number | undefined;
    let _slab: number | undefined;
    if (!isBlock) {
      _bundle = !!bundle ? parseInt(bundle) : 0;
      _slab = !!slab ? parseInt(slab) : 0;
    }
    const _updateData: LottoImageUpdateFormData = {
      name,
      bundle: _bundle,
      slab: _slab ?? 0,
      isBlock,
      order: !!order ? parseInt(order) : 0,
    };
    setData(_updateData);
  };
  useEffect(() => {
    setId(null);
    setName("");
    setOrder("");
    setBundle("");
    setData(null);
    if (image) {
      setId(image.id);
      setName(image.name);
      setBundle(String(image.bundle));
      setSlab(String(image.slab));
      setOrder(String(image.order));
    }
  }, [image, isOpen]);
  useImageUpdate(id, data);
  return (
    <Dialog
      open={isOpen}
      onClose={close}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="md"
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar variant="dense">
          <Typography variant="h5" sx={{ flex: 1 }} display="block">
            {t(`${i18_PATH}Modifica immagine`)}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <Icon className="fa-light fa-xmark" sx={{ overflow: "visible" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box sx={{ p: 2, overflowY: "auto" }}>
        <TextField
          required={true}
          margin="dense"
          label="Nome immagine"
          type="text"
          error={!!nameError}
          helperText={nameError}
          value={name}
          onChange={onNameChange}
          fullWidth
        />
        {!isBlock && (
          <>
            {!settings.item?.images?.slabs?.hiddenUpdateFields?.bundle && (
              <TextField
                margin="dense"
                label="Bundle"
                type="number"
                value={bundle}
                onChange={onBundleChange}
                fullWidth
              />
            )}
            {!settings.item?.images?.slabs?.hiddenUpdateFields?.slab && (
              <TextField
                margin="dense"
                label="Lastra"
                type="number"
                value={slab}
                onChange={onSlabChange}
                fullWidth
              />
            )}
          </>
        )}
        {((isBlock &&
          !settings.item?.images?.block?.hiddenUpdateFields?.order) ||
          (!isBlock &&
            !settings.item?.images?.slabs?.hiddenUpdateFields?.order)) && (
          <TextField
            margin="dense"
            label="Ordine"
            type="number"
            value={order}
            onChange={onOrderChange}
            fullWidth
          />
        )}
      </Box>
      <DialogActions>
        <Button onClick={close}>{t(`${i18_PATH}Cancella`)}</Button>
        <Button onClick={onSubmit} variant="contained" color="primary">
          {t(`${i18_PATH}Salva`)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateDialog;
