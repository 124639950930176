import { Box, Button, Icon } from '@mui/material';
import { GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import React from 'react';
import { ITableToolbarProps } from './interfaces';

function TableToolbar({ onAdd }: ITableToolbarProps) {
	return (
		<GridToolbarContainer>
			<GridToolbarExport csvOptions={{ fileName: "agenti", delimiter: ';' }} />
			<Box sx={{ display: 'flex', flexGrow: 1, px: 1, justifyContent: 'flex-end' }}>
				<Button variant="outlined" startIcon={<Icon className="fa-light fa-plus" />} color="inherit" sx={{ mx: 1, my: 1, display: 'flex' }} onClick={() => onAdd()}>Nuovo Gruppo Utente</Button>
			</Box>
		</GridToolbarContainer>
	);
}

export default TableToolbar;