import { ICliente } from "../../interfaces/cliente/models";

export const GET = "cliente/get";
export const LIST = "cliente/list";
export const EMPTY = "cliente/empty";

export interface IClienteState {
	results: number;
	list: string[];
	items: Record<string, ICliente> | null;
};

export const initialState: IClienteState = {
	results: 0,
	list: [],
	items: null
};