import { List, ListSubheader } from "@mui/material";
import { useMemo } from "react";
import { settings } from "../../../../../../../customize/settings";
import { EOptionType } from "../../../../../../../interfaces/enums";
import { IFilterProps } from "../../interfaces";
import FileFilter from "./file";
import MagazziniFilter from "./magazzini";
import ZoneFilter from "./zone";

function PosizioniFiltersContainer({ filters, setFilters }: IFilterProps) {
  const showZone: boolean = useMemo(
    () => !!filters.find((f) => f.type === EOptionType.magazzino),
    [filters]
  );

  const showFile: boolean = useMemo(
    () => !!filters.find((f) => f.type === EOptionType.zona),
    [filters]
  );

  return (
    <List
      dense={true}
      sx={{ p: 0 }}
      component="nav"
      subheader={<ListSubheader component="div">Posizioni</ListSubheader>}
    >
      {!settings.list?.sideNav?.hiddenFilters?.posizioniMagazzini && (
        <MagazziniFilter filters={filters} setFilters={setFilters} />
      )}

      {showZone && !settings.list?.sideNav?.hiddenFilters?.posizioniZone && (
        <ZoneFilter filters={filters} setFilters={setFilters} />
      )}
      {showFile && !settings.list?.sideNav?.hiddenFilters?.posizioniFile && (
        <FileFilter filters={filters} setFilters={setFilters} />
      )}
    </List>
  );
}

export default PosizioniFiltersContainer;
