import { createTheme, PaletteOptions } from '@mui/material/styles';

declare module '@mui/material/styles' {
	// allow configuration using `createTheme`
	interface ThemeOptions {
		palette?: PaletteOptions;
	}
}

const themeOptions: any = {
	typography: {
		fontFamily: 'Lato',
		subtitle1: {
			fontFamily: 'Raleway',
			fontSize: '1.2rem',
			textTransform: 'uppercase',
			fontWeight: 300,
			letterSpacing: '.3rem'
		}
	},
	palette: {
		type: 'light',
		primary: {
			main: '#3c3c3c',
		},
		secondary: {
			main: '#f50057',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					fontFamily: 'Raleway',
					fontWeight: 300,
					letterSpacing: '.2rem',
					lineHeight: 1
				}
			}
		}
	}
};

export default createTheme(themeOptions);