import { List, ListSubheader } from "@mui/material";
import { settings } from "../../../../../../../customize/settings";
import { IFilterProps } from "../../interfaces";
import ClientiFilter from "./clienti";

function CommercialeFiltersContainer({ filters, setFilters }: IFilterProps) {
  return (
    <List
      dense={true}
      sx={{ p: 0 }}
      component="nav"
      subheader={<ListSubheader component="div">Commerciale</ListSubheader>}
    >
      {!settings.list?.sideNav?.hiddenFilters?.commercialeClienti && (
        <ClientiFilter filters={filters} setFilters={setFilters} />
      )}
    </List>
  );
}

export default CommercialeFiltersContainer;
