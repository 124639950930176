import * as React from 'react';
import { errorHandler } from '../../../error/handler';
import { getTipoLotto, getTipoLottoList } from '../../../fetch/dettaglio/attributes/tipoLotto';
import { ITipoLotto } from '../../../interfaces/dettaglio/attributes/models';
import { normalizer } from '../../../lib/normalizer';
import { emptyAction, getAction, listAction } from '../../../store/dettaglio/attributes/tipoLotto/actions';
import { ITipoLottoState } from '../../../store/dettaglio/attributes/tipoLotto/types';
import { useAppDispatch } from '../../../store/hooks';
import { endActionToDoAction, startActionToDoAction } from '../../../store/ui/actions';

export const useGetTipoLotto = (id: number) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!id) return;
			const actionToDo = 'get-tipo-lotto';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const tipoLotto = await getTipoLotto(id);
				dispatch(getAction(tipoLotto));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id])
}

export const useTipoLottoList = (query: number) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!query) return;
			const actionToDo = 'list-tipo-lotto';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const { results, items: _items } = await getTipoLottoList();
				_items.sort((itemA, itemB) => (!!itemA?.ordinamento && !!itemB.ordinamento) ? itemA.ordinamento - itemB.ordinamento : 0);
				const { list, items } = normalizer<number, ITipoLotto>(_items, "id");
				const actionPayload: ITipoLottoState = {
					results,
					list,
					items
				}
				dispatch(listAction(actionPayload));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
			return () => {
				dispatch(emptyAction());
			}
		}
		effect();
	}, [dispatch, query])
}
