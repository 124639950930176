import { Box, Icon, Tooltip } from "@mui/material";
import {
  dettaglioIsBlock,
  dettaglioIsSlab,
  dettaglioIsTiles,
} from "../../../lib/functions";
import { ICellTipoLottoParams } from "./interfaces";

export function CellTipoLotto({ dettaglio }: ICellTipoLottoParams) {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {dettaglioIsBlock(dettaglio) && (
        <Tooltip title={`Blocco`}>
          <Icon className="fa-light fa-cube" sx={{ overflow: "visible" }} />
        </Tooltip>
      )}
      {dettaglioIsSlab(dettaglio) && (
        <Tooltip title={`Lastre`}>
          <Icon
            className="fa-light fa-layer-group"
            sx={{ overflow: "visible" }}
          />
        </Tooltip>
      )}
      {dettaglioIsTiles(dettaglio) && (
        <Tooltip title={`Marmette`}>
          <Icon
            className="fa-light fa-bring-forward"
            sx={{ overflow: "visible" }}
          />
        </Tooltip>
      )}
    </Box>
  );
}
