import { Box, Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useUserUpdate } from "../../../effects/user/user";
import { UserUpdateFormData } from "../../../interfaces/user/forms";
import { IUserGroup } from "../../../interfaces/user/models";
import { useAppSelector } from "../../../store/hooks";

function Edit() {
	const navigate = useNavigate();
	const [formData, setFormData] = useState<UserUpdateFormData>(null);

	const [nome, setNome] = useState<string>("");
	const [nomeError, setNomeError] = useState<string | null>(null);

	const [email, setEmail] = useState<string>("");
	const [emailError, setEmailError] = useState<string | null>(null);

	const [userGroup, setUserGroup] = useState<string>("");
	const [userGroupError, setUserGroupError] = useState<string | null>(null);

	const [password, setPassword] = useState<string>("");
	const [password2, setPassword2] = useState<string>("");
	const [passwordError, setPasswordError] = useState<string | null>(null);

	const users = useAppSelector((state) => state.user.items);
	const userGroupItems = useAppSelector((state) => state.userGroup.items);
	const userGroupList = useAppSelector((state) => state.userGroup.list);
	const userGroups: IUserGroup[] = (!!userGroupList && !!userGroupItems) ? userGroupList?.map(userGroupId => userGroupItems[userGroupId]) : []

	const lastActionDone = useAppSelector((state) => state.ui.lastActionDone);
	const { userId } = useParams();
	const id = (!!userId) ? parseInt(userId) : null
	const user = (!!id && !!users) ? users[id] : null;

	useEffect(() => {
		if (lastActionDone === "update-user") navigate('..');
	}, [lastActionDone, navigate]);

	useEffect(() => {
		if (user) {
			setNome(user.nome);
			setEmail(user.email);
			setUserGroup(String(user?.id_userGroup));
		} else {
			navigate('..');
		}
	}, [user, navigate]);
	useUserUpdate(id, formData);
	const onNomeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNome(event.target.value);
	};
	const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};
	const onUserGroupChange = (event: SelectChangeEvent<string>) => {
		setUserGroup(event.target.value);
	};
	const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword(event.target.value);
	};
	const onPassword2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPassword2(event.target.value);
	};
	const checkError = (): boolean => {
		let errors = 0;
		if (!nome) {
			setNomeError('Campo obbligatorio');
			errors++;
		} else setNomeError(null);
		if (!email) {
			setEmailError('Campo obbligatorio');
			errors++;
		} else setEmailError(null);
		if (!userGroup) {
			setUserGroupError('Campo obbligatorio');
			errors++;
		} else setEmailError(null);
		if (!!password && !password2) {
			setPasswordError('Campo obbligatorio');
			errors++;
		} else if (!!password && !!password2 && password !== password2) {
			setPasswordError('Le due password non coincidono');
			errors++;
		} else setPasswordError(null);
		return errors > 0;
	}
	const submit = () => {
		if (!checkError()) {
			const data: UserUpdateFormData = {
				nome,
				email,
				id_userGroup: (userGroup) ? parseInt(userGroup) : undefined,
				password: (password && password === password2) ? password : undefined
			};
			setFormData(data);
		}
	}
	const back = () => {
		navigate('..');
	}
	return (
		<Box sx={{ display: "flex", flexDirection: "column", flex: 1, backgroundColor: "#fff" }}>
			<Box sx={{ flex: 1, position: "relative" }}>
				<Box sx={{ backgroundColor: "#fff", boxSizing: "border-box", position: "absolute", height: "100%", width: "100%", overflowY: "auto" }}>
					<Box sx={{ p: 2 }}>
						<Typography variant="subtitle1">Modifica Utente</Typography>
					</Box>
					<Box
						sx={{ display: "flex", flexDirection: "column", padding: 2 }}
					>
						<TextField
							required={true}
							margin="dense"
							label="Nome"
							type="text"
							error={!!nomeError}
							helperText={nomeError}
							value={nome}
							onChange={onNomeChange}
						/>
						<TextField
							required={true}
							margin="dense"
							label="Email"
							type="text"
							error={!!emailError}
							helperText={emailError}
							value={email}
							onChange={onEmailChange}
						/>
						{!!userGroups?.length &&
							<FormControl
								error={!!userGroupError}
								fullWidth sx={{ mt: 1, mb: .5 }}
							>
								<InputLabel id="gruppo-label">Gruppo</InputLabel>
								<Select
									labelId="gruppo-label"
									id="demo-simple-select"
									value={userGroup}
									label="Gruppo"
									onChange={onUserGroupChange}
								>
									{userGroups.map(ug => (
										<MenuItem
											key={ug.id}
											value={ug.id}
										>
											{ug.nome}
										</MenuItem>
									))}
								</Select>
								{!!userGroupError &&
									<FormHelperText >
										{userGroupError}
									</FormHelperText>
								}
							</FormControl>
						}
						<TextField
							required={false}
							margin="dense"
							label="Password"
							type="password"
							value={password}
							onChange={onPasswordChange}
						/>
						{!!password &&
							<TextField
								required={true}
								margin="dense"
								label="Ripeti password"
								type="password"
								error={!!passwordError}
								helperText={passwordError}
								value={password2}
								onChange={onPassword2Change}
							/>
						}
					</Box>
				</Box>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "flex-end", padding: 2, border: "1px solid rgba(224, 224, 224, 1)" }}>
				<Button onClick={back} >Cancella</Button>
				<Button variant="contained" onClick={submit}>Salva</Button>
			</Box>
		</Box>
	)
}

export default Edit;