import { Box } from "@mui/material";
import {
  DataGrid,
  GridColumnVisibilityModel,
  GridRowParams,
} from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { settings } from "../../../../../customize/settings";
import { IDettaglio } from "../../../../../interfaces/dettaglio/models";
import {
  getColumnVisibilityModel,
  storeColumnVisibilityModel,
} from "../../../../../lib/functions";
import { createDefaultGridColumns } from "./columns";
import { ILottoTableProps } from "./interfaces";
import TableToolbar from "./toolbar";

function TableView({
  rows,
  user,
  onView,
  rowsSelected,
  setRowsSelected,
}: ILottoTableProps) {
  const [canViewQuarry, setCanViewQuarry] = useState<boolean>(false);
  const [canViewStoragePlace, setCanViewStoragePlace] =
    useState<boolean>(false);
  const [canViewCommercials, setCanViewCommercials] = useState<boolean>(false);
  const [canViewCommercialOptions, setCanViewCommercialOptions] =
    useState<boolean>(false);
  const [rowsSameBlock, setRowsSameBlock] = useState<number[]>([]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel | null>(null);
  const columns = React.useMemo(() => {
    if (!user) return [];
    return createDefaultGridColumns({
      canViewQuarry,
      canViewCommercials,
      canViewStoragePlace,
      canViewCommercialOptions,
      rowsSelected,
      setRowsSelected,
      onView,
    });
  }, [
    user,
    canViewQuarry,
    canViewCommercials,
    canViewStoragePlace,
    canViewCommercialOptions,
    rowsSelected,
    setRowsSelected,
    onView,
  ]);
  const onRowClick = (params: GridRowParams<IDettaglio>): void => {
    const id: number = params.id as number;
    let selectedRows: number[] = [...rowsSelected];
    if (rowsSelected.includes(id)) {
      selectedRows = selectedRows.filter((item) => item !== id);
    } else {
      selectedRows.push(id);
    }
    setRowsSelected(selectedRows);
  };
  const getRowClassName = (params: GridRowParams<IDettaglio>): string => {
    const id: number = params.id as number;
    const classNames: string[] = [];
    if (rowsSelected.includes(id)) classNames.push("Mui-selected");
    if (rowsSameBlock.includes(id)) classNames.push("select-pack-list");
    return classNames.join(" ");
  };
  useEffect(() => {
    const model = getColumnVisibilityModel(
      "DettaglioLottoColumnVisibilityModel"
    );
    if (model) setColumnVisibilityModel(model);
    else
      setColumnVisibilityModel(settings.list.grid?.initColumnVisibiliity ?? {});
  }, []);
  useEffect(() => {
    storeColumnVisibilityModel(
      "DettaglioLottoColumnVisibilityModel",
      columnVisibilityModel
    );
  }, [columnVisibilityModel]);
  useEffect(() => {
    const _canViewQuery =
      !!user?.admin || !!user?.user_group?.group_permissions?.canViewQuarry;
    const _canViewStoragePlace =
      !!user?.admin ||
      !!user?.user_group?.group_permissions?.canViewStoragePlace;
    const _canViewCommercials =
      !!user?.admin ||
      !!user?.user_group?.group_permissions?.canViewCommercials;
    const _canViewCommercialOptions =
      !!user?.admin ||
      !!user?.user_group?.group_permissions?.canSetCommercialOptions;
    setCanViewQuarry(_canViewQuery);
    setCanViewStoragePlace(_canViewStoragePlace);
    setCanViewCommercials(_canViewCommercials);
    setCanViewCommercialOptions(_canViewCommercialOptions);
  }, [user]);
  useEffect(() => {
    if (rowsSelected.length === 1) {
      const id = rowsSelected[0];
      const codiceLotto = rows.find((item) => item.id === id)?.lotto?.codice;
      const sameBlockRows = rows
        .filter(
          (item) => !!item?.lotto?.codice && item?.lotto?.codice === codiceLotto
        )
        .map((item) => item.id);
      setRowsSameBlock(sameBlockRows);
    } else {
      setRowsSameBlock([]);
    }
  }, [rowsSelected, rows]);
  return (
    <Box
      sx={{
        flex: 1,
        backgroundColor: "#FFF",
        "& .select-pack-list": {
          backgroundColor: "rgba(13,62,143,0.14)",
        },
      }}
    >
      {!!columnVisibilityModel && (
        <DataGrid
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          components={{ Toolbar: TableToolbar }}
          disableSelectionOnClick
          pageSize={50}
          rows={rows || []}
          rowsPerPageOptions={[50]}
          getRowClassName={getRowClassName}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
          onRowClick={onRowClick}
        />
      )}
    </Box>
  );
}

export default TableView;
