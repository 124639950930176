import * as React from 'react';
import { errorHandler } from '../../error/handler';
import { getCliente, getClienteList } from '../../fetch/cliente';
import { ICliente } from '../../interfaces/cliente/models';
import { normalizer } from '../../lib/normalizer';
import { emptyAction, getAction, listAction } from '../../store/cliente/actions';
import { IClienteState } from '../../store/cliente/types';
import { useAppDispatch } from '../../store/hooks';
import { endActionToDoAction, startActionToDoAction } from '../../store/ui/actions';

export const useClienteGet = (id: number) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			const actionToDo = 'get-user';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const cliente = await getCliente(id);
				dispatch(getAction(cliente));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id])
}

export const useClienteList = (query: any) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			const actionToDo = 'list-user';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const { results, items: _items } = await getClienteList();
				const { list, items } = normalizer<string, ICliente>(_items, 'id');
				const actionPayload: IClienteState = {
					results,
					list,
					items
				}
				dispatch(listAction(actionPayload));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
			return () => {
				dispatch(emptyAction());
			}
		}
		effect();
	}, [dispatch, query])
}