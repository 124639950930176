import { settings } from "../../customize/settings";
import { ICliente } from "../../interfaces/cliente/models";
import { IBaseError } from "../../interfaces/errors";
import { IListResponse } from "../../interfaces/rest";
import { getJWT } from "../../lib/jwt";


const BASE_URL = `${settings.apiUrl}cliente`

export const getCliente = async (id: number): Promise<ICliente> => {
	const url = `${BASE_URL}${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: ICliente | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as ICliente;
}

export const getClienteList = async (): Promise<IListResponse<ICliente>> => {
	const url = `${BASE_URL}/list`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IListResponse<ICliente> | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IListResponse<ICliente>;
}
