import { Box, TextField } from "@mui/material";
import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDettaglioUpdateNotes } from "../../../../../../../effects/dettaglio/dettaglio";
import { DettaglioUpdateNotesFormData } from "../../../../../../../interfaces/dettaglio/forms";
import { IDettaglio } from "../../../../../../../interfaces/dettaglio/models";
import {
  IUpdateDialogFormsProps,
  UpdateDialogFormsHandle,
} from "../interfaces";

const NotesForm: ForwardRefRenderFunction<
  UpdateDialogFormsHandle,
  IUpdateDialogFormsProps
> = ({ lottoId, selectedItems, hidden }, ref) => {
  const [ids, setIds] = useState<number[]>([]);
  const [initValue, setInitValue] = useState<IDettaglio | null>(null);
  const [opzNote, setOpzNote] = useState<string>("");

  const [formData, setFormData] = useState<DettaglioUpdateNotesFormData>(null);

  const onOpzNoteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOpzNote(event.target.value);
  };

  const onSubmit = () => {
    const initOpzNote = initValue?.opzNote || null;
    if (!ids.length || (!!initValue && initOpzNote === opzNote)) {
      return;
    }

    const _formData: DettaglioUpdateNotesFormData = {
      opzNote,
    };
    setFormData(_formData);
  };

  useImperativeHandle(ref, () => ({
    onSubmit,
  }));

  useEffect(() => {
    setFormData(null);
    if (selectedItems.length === 1) {
      setInitValue(selectedItems[0]);
    } else {
      setInitValue(null);
    }
    const _ids = selectedItems.map((item) => item.id);
    setIds(_ids);
  }, [selectedItems]);

  useEffect(() => {
    if (!!initValue) {
      const notes = initValue.opzNote || null;
      if (!!notes) setOpzNote(notes);
    } else {
      setOpzNote("");
    }
  }, [initValue]);

  useDettaglioUpdateNotes(lottoId, ids, formData);

  return (
    <div
      role="tabpanel"
      hidden={hidden}
      id="update-tabpanel-0"
      aria-labelledby="update-tab-0"
    >
      <Box sx={{ display: "flex", gap: 1 }}>
        <TextField
          margin="dense"
          label="Note"
          type="text"
          multiline
          rows={4}
          value={opzNote}
          onChange={onOpzNoteChange}
          fullWidth
        />
      </Box>
    </div>
  );
};

export default forwardRef(NotesForm);
