import { useEffect } from 'react';
import { errorHandler } from '../../error/handler';
import { getProspect, getProspectList, removeProspect, storeProspect, updateProspect } from '../../fetch/prospect/prospect';
import { ProspectStoreFormData, ProspectUpdateFormData } from '../../interfaces/prospect/forms';
import { IProspect } from '../../interfaces/prospect/models';
import { normalizerTransform } from '../../lib/normalizer';
import { prospectTransform } from '../../lib/transform';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { emptyAction, getAction, listAction } from '../../store/prospect/actions';
import { IProspectState } from '../../store/prospect/types';
import { endActionToDoAction, messageAction, startActionToDoAction } from '../../store/ui/actions';

export const useProspectGet = (id: number) => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		const effect = async () => {
			if (!id) return;
			const actionToDo = 'get-prospect';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const prospect = await getProspect(id);
				if (prospect) dispatch(getAction(prospect));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id])
}

export const useProspectList = (query: number) => {
	const dispatch = useAppDispatch();
	const materialeMap = useAppSelector((state) => state.lotto_materiale.items);
	const qualitaMap = useAppSelector((state) => state.lotto_qualita.items);
	const categoriaMap = useAppSelector((state) => state.lotto_categoria.items);
	const fondoMap = useAppSelector((state) => state.lotto_fondo.items);
	const venaturaMap = useAppSelector((state) => state.lotto_venatura.items);
	const usoMap = useAppSelector((state) => state.lotto_uso.items);
	useEffect(() => {
		const effect = async () => {
			if (!(!!query && !!materialeMap && !!qualitaMap && !!categoriaMap && !!fondoMap && !!venaturaMap && !!usoMap)) return;
			const actionToDo = 'list-prospect';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const { results, items: _items } = await getProspectList();
				const { list, items } = normalizerTransform<number, IProspect, IProspect>(_items, (item) => prospectTransform(item, materialeMap, qualitaMap, categoriaMap, fondoMap, venaturaMap, usoMap));
				const actionPayload: IProspectState = {
					results,
					list,
					items
				}
				dispatch(listAction(actionPayload));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
			return () => {
				dispatch(emptyAction());
			}
		}
		effect();
	}, [dispatch, query, materialeMap, qualitaMap, categoriaMap, fondoMap, venaturaMap, usoMap])
}

export const useProspectStore = (data: ProspectStoreFormData) => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		const effect = async () => {
			if (!data) return;
			const actionToDo = 'store-prospect';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				await storeProspect(data);
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(messageAction({ message: "prospect aggiunto", kind: "success" }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, data]);
}

export const useProspectUpdate = (id: number | null, data: ProspectUpdateFormData) => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		const effect = async () => {
			if (!id || !data) return;
			const actionToDo = 'update-prospect';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				await updateProspect(id, data);
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(messageAction({ message: "prospect aggiornato", kind: "success" }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id, data]);
}

export const useProspectRemove = (id: number | null) => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		const effect = async () => {
			if (!id) return;
			const actionToDo = 'remove-prospect';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				await removeProspect(id);
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(messageAction({ message: "prospect rimosso", kind: "success" }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id]);
}
