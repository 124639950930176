import { IZona } from "../../../interfaces/place/models";

export const GET = "zona/get";
export const LIST = "zona/list";
export const EMPTY = "zona/empty";

export interface IZonaState {
	results: number;
	list: number[];
	items: Record<number, IZona> | null;
};

export const initialState: IZonaState = {
	results: 0,
	list: [],
	items: null
};