import {
  Collapse,
  Icon,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useMemo, useState } from "react";
import { EOptionType } from "../../../../../../../interfaces/enums";
import { IVenatura } from "../../../../../../../interfaces/lotto/attributes/models";
import { isFilterAndValueSelected } from "../../../../../../../lib/filters";
import { useAppSelector } from "../../../../../../../store/hooks";
import { IFilterProps } from "../../interfaces";

function VenatureFilter({ filters, setFilters }: IFilterProps) {
  const [open, setOpen] = useState<boolean>(false);

  const venaturaList = useAppSelector((state) => state.lotto_venatura.list);
  const venaturaItems = useAppSelector((state) => state.lotto_venatura.items);

  const list: IVenatura[] = useMemo(
    () =>
      !!venaturaList && !!venaturaItems
        ? venaturaList.map((id) => venaturaItems[id])
        : [],
    [venaturaList, venaturaItems]
  );

  const toggle = () => {
    setOpen(!open);
  };

  const toggleSelected = (item: IVenatura) => {
    if (isFilterAndValueSelected(filters, EOptionType.venatura, item.id)) {
      setFilters(
        filters.filter(
          (opt) =>
            !(opt.type === EOptionType.venatura && opt.numericValue === item.id)
        )
      );
    } else {
      setFilters([
        ...filters,
        {
          type: EOptionType.venatura,
          numericValue: item.id,
          stringValue: item.nome,
        },
      ]);
    }
  };

  return (
    <>
      <ListItemButton onClick={toggle}>
        <ListItemIcon>
          <Icon className="fa-light fa-droplet" sx={{ overflow: "visible" }} />
        </ListItemIcon>
        <ListItemText primary="Venature" />
        {open ? (
          <Icon
            className="fa-light fa-angle-up"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        ) : (
          <Icon
            className="fa-light fa-angle-down"
            fontSize="small"
            sx={{ overflow: "visible" }}
          />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense={true}>
          {list.map((item) => (
            <ListItemButton
              key={item.id}
              sx={{ display: "flex", pl: 5, pr: 1 }}
              onClick={() => toggleSelected(item)}
              selected={isFilterAndValueSelected(
                filters,
                EOptionType.venatura,
                item.id
              )}
            >
              <ListItemText sx={{ flex: 1 }} primary={item.nome} />
              <ListItemIcon sx={{ minWidth: 0 }}>
                {isFilterAndValueSelected(
                  filters,
                  EOptionType.venatura,
                  item.id
                ) && (
                  <Icon
                    className="fa-light fa-check"
                    sx={{ overflow: "visible" }}
                  />
                )}
              </ListItemIcon>
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
}

export default VenatureFilter;
