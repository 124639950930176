import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getMQPrice,
  getTotalCosts,
  getTotalMQ,
  getTotalNumberOfSlabs,
  getTotalSlabsPrice,
  isAllSlabs,
} from "../../../../../../lib/functions";
import { IGridFooterProps } from "./interfaces";

const i18_PATH = "prodotto.view.lottoDettagli.grids.gridFooter.";

function GridFooter({
  selectedItems,
  canViewCommercials,
  canViewCosts,
  showAdminColumns,
}: IGridFooterProps) {
  const { t } = useTranslation();
  const [onlySlabs, setOnlySlabs] = useState<boolean>(false);
  const [slabs, setSlabs] = useState<number>(0);
  const [MQ, setMQ] = useState<string>("0,00");
  const [MQPrice, setMQPrice] = useState<string>("0,00");
  const [totalPrice, setTotalPrice] = useState<string>("0,00");
  const [totalCosts, setTotalCosts] = useState<string>("0,00");

  useEffect(() => {
    setOnlySlabs(isAllSlabs(selectedItems));
    setSlabs(getTotalNumberOfSlabs(selectedItems));
    setMQ(getTotalMQ(selectedItems, 2, 2));
    setMQPrice(getMQPrice(selectedItems, 2, 2));
    setTotalPrice(getTotalSlabsPrice(selectedItems, 2, 2));
    setTotalCosts(getTotalCosts(selectedItems, 2, 2));
  }, [selectedItems]);
  return (
    <Box sx={{ height: 52, px: 2 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ display: "flex", flex: 1 }}>
          {/*<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
                        <Typography variant="overline" color="text.secondary" sx={{ mr: 1 }}>Righe:</Typography>
                        <Typography variant="body1" component="span" color="text.primary">{rowsNumber}</Typography>
                        </Box> */}
          {onlySlabs && (
            <>
              <Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
                <Typography
                  variant="overline"
                  color="text.secondary"
                  sx={{ mr: 1 }}
                >
                  {t(`${i18_PATH}Pezzi`)}:
                </Typography>
                <Typography
                  variant="body1"
                  component="span"
                  color="text.primary"
                >
                  {slabs}
                </Typography>
              </Box>
              <Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
                <Typography
                  variant="overline"
                  color="text.secondary"
                  sx={{ mr: 1 }}
                >
                  {t("MQ")}:
                </Typography>
                <Typography
                  variant="body1"
                  component="span"
                  color="text.primary"
                >
                  {MQ}
                </Typography>
              </Box>
              {canViewCommercials && (
                <>
                  <Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
                    <Typography
                      variant="overline"
                      color="text.secondary"
                      sx={{ mr: 1 }}
                    >
                      {t(`${i18_PATH}Prezzo al MQ`)}:
                    </Typography>
                    <Typography
                      variant="body1"
                      component="span"
                      color="text.primary"
                    >
                      {MQPrice} €
                    </Typography>
                  </Box>
                  <Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
                    <Typography
                      variant="overline"
                      color="text.secondary"
                      sx={{ mr: 1 }}
                    >
                      {t(`${i18_PATH}Totale`)}:
                    </Typography>
                    <Typography
                      variant="body1"
                      component="span"
                      color="text.primary"
                    >
                      {totalPrice} €
                    </Typography>
                  </Box>
                </>
              )}
              {canViewCosts && showAdminColumns && (
                <Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
                  <Typography
                    variant="overline"
                    color="text.secondary"
                    sx={{ mr: 1 }}
                  >
                    {t(`${i18_PATH}Costo totale`)}:
                  </Typography>
                  <Typography
                    variant="body1"
                    component="span"
                    color="text.primary"
                  >
                    {totalCosts} €
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default GridFooter;
