
import { GridColumns, GridRowClassNameParams, GridValidRowModel } from "@mui/x-data-grid";
import { IDettaglio } from "../../../../../../interfaces/dettaglio/models";
import { IDettaglioExtendedNode, IDettaglioTreeNode } from "../interfaces";

export const getChildren = (node: IDettaglioExtendedNode, nodes: IDettaglio[]): IDettaglioExtendedNode[] => {
	return nodes.filter(_node => _node.idDettaglioPadre === node.id).map(_node => ({
		..._node,
		children: getChildren(_node, nodes)
	}));
}

export const transform = (nodes: IDettaglio[]): IDettaglioExtendedNode[] => {
	const root = nodes.filter(node => !node.idDettaglioPadre)
	return root.map(node => ({
		...node,
		children: getChildren(node, nodes)
	}));
}

export const flattener = (nodes: IDettaglioExtendedNode[], depth: number = 0, flatNodes: IDettaglioTreeNode[] = []): IDettaglioTreeNode[] => {
	nodes.forEach(node => {
		const { children, ..._node } = node;
		const flatNode: IDettaglioTreeNode = {
			..._node,
			leaf: !node.children || !node.children.length,
			depth
		}
		flatNodes.push(flatNode);
		if (!!node.children) flattener(node.children, depth + 1, flatNodes)
	})
	return flatNodes;
}

export const hasAncestorsClosed = (nodeId: number, nodes: IDettaglioTreeNode[], openParents: number[]): boolean => {
	let parent = nodes.find(node => node.id === nodeId)?.idDettaglioPadre;
	while (!!parent) {
		if (!openParents.includes(parent)) return true;
		const parentId = parent;
		parent = nodes.find(node => node.id === parentId)?.idDettaglioPadre;
	}
	return false;
}

export const boundleRows = (nodes: IDettaglio[]): IDettaglio[] => {
	const groupObj: Record<number, IDettaglio[]> = nodes.reduce((r, a) => {
		r[a.bundle] = r[a.bundle] || [];
		r[a.bundle].push(a);
		return r;
	}, Object.create(null));
	const res: IDettaglio[] = [];
	Object.values(groupObj).forEach((dettagli, index) => {
		dettagli[0] = {
			...dettagli[0],
			//isBundle: true,
		}
		const d = { ...dettagli[0], isBundle: false, id: 12341234124 + index }
		res.push(...dettagli, d);
	})
	return res;
}

export const hideColumns = <T extends GridValidRowModel>(columns: GridColumns<T>, columnsToHide: string[]): GridColumns<T> => {
	return columns.filter(col => !columnsToHide.includes(col.field))
}

export const getRowBundleClassName = (params: GridRowClassNameParams<IDettaglio>): string => {
	const bundle = parseInt(String(params.row.bundle))
	if (bundle % 2 === 0) return "bundle-row--even";
	return "bundle-row--odd";
}