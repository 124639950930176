import { ICava } from "../../../interfaces/place/models";

export const GET = "cava/get";
export const LIST = "cava/list";
export const EMPTY = "cava/empty";

export interface ICavaState {
	results: number;
	list: number[];
	items: Record<number, ICava> | null;
};

export const initialState: ICavaState = {
	results: 0,
	list: [],
	items: null
};