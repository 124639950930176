import { Box, TextField } from "@mui/material";
import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDettaglioUpdateMeasures } from "../../../../../../../effects/dettaglio/dettaglio";
import { DettaglioUpdateMeasuresFormData } from "../../../../../../../interfaces/dettaglio/forms";
import { IDettaglio } from "../../../../../../../interfaces/dettaglio/models";
import {
  IUpdateDialogFormsProps,
  UpdateDialogFormsHandle,
} from "../interfaces";

const MeasuresForm: ForwardRefRenderFunction<
  UpdateDialogFormsHandle,
  IUpdateDialogFormsProps
> = ({ lottoId, selectedItems, hidden }, ref) => {
  const [ids, setIds] = useState<number[]>([]);
  const [initValue, setInitValue] = useState<IDettaglio | null>(null);
  const [misX, setMisX] = useState<string>("");
  const [misY, setMisY] = useState<string>("");
  const [misZ, setMisZ] = useState<string>("");
  const [formData, setFormData] =
    useState<DettaglioUpdateMeasuresFormData>(null);

  const onMisXChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMisX(event.target.value);
  };

  const onMisYChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMisY(event.target.value);
  };

  const onMisZChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMisZ(event.target.value);
  };

  const onSubmit = () => {
    if (
      !ids.length ||
      (String(parseFloat(String(initValue?.misX)).toFixed(2)) === misX &&
        String(parseFloat(String(initValue?.misY)).toFixed(2)) === misY &&
        String(parseFloat(String(initValue?.misZ)).toFixed(2)) === misZ) ||
      (!!ids.length && !misX && !misY && !misZ)
    ) {
      return;
    }
    let _formData: DettaglioUpdateMeasuresFormData = {};
    _formData.misX = !!misX ? Number(parseFloat(misX).toFixed(2)) : undefined;
    _formData.misY = !!misY ? Number(parseFloat(misY).toFixed(2)) : undefined;
    _formData.misZ = !!misZ ? Number(parseFloat(misZ).toFixed(2)) : undefined;
    setFormData(_formData);
  };

  useImperativeHandle(ref, () => ({
    onSubmit,
  }));

  useEffect(() => {
    setFormData(null);
    if (selectedItems.length === 1) {
      setInitValue(selectedItems[0]);
    } else {
      setInitValue(null);
    }
    const _ids = selectedItems.map((item) => item.id);
    setIds(_ids);
  }, [selectedItems]);

  useEffect(() => {
    if (!!initValue) {
      setMisX(String(parseFloat(String(initValue.misX)).toFixed(2)));
      setMisY(String(parseFloat(String(initValue.misY)).toFixed(2)));
      setMisZ(String(parseFloat(String(initValue.misZ)).toFixed(2)));
    } else {
      setMisX("");
      setMisY("");
      setMisZ("");
    }
  }, [initValue]);

  useDettaglioUpdateMeasures(lottoId, ids, formData);

  return (
    <div
      role="tabpanel"
      hidden={hidden}
      id="update-tabpanel-0"
      aria-labelledby="update-tab-0"
    >
      <Box sx={{ display: "flex", gap: 1 }}>
        <TextField
          margin="dense"
          label="Mis X"
          type="number"
          name="misX"
          value={misX}
          onChange={onMisXChange}
          sx={{ flex: 1 }}
          inputProps={{ maxLength: 12 }}
        />
        <TextField
          margin="dense"
          label="Mis Y"
          type="number"
          name="misY"
          value={misY}
          onChange={onMisYChange}
          sx={{ flex: 1 }}
          inputProps={{ maxLength: 12 }}
        />
        <TextField
          margin="dense"
          label="Mis Z"
          type="number"
          name="misZ"
          value={misZ}
          onChange={onMisZChange}
          sx={{ flex: 1 }}
          inputProps={{ maxLength: 12 }}
        />
      </Box>
    </div>
  );
};

export default forwardRef(MeasuresForm);
