import { settings } from "../../../customize/settings";
import { IBaseError } from "../../../interfaces/errors";
import { IUso } from "../../../interfaces/lotto/attributes/models";
import { IListResponse } from "../../../interfaces/rest";
import { getJWT } from "../../../lib/jwt";

const BASE_URL = `${settings.apiUrl}uso`;

export const getUso = async (id: number): Promise<IUso> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IUso | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IUso;
}

export const getUsoList = async (): Promise<IListResponse<IUso>> => {
	const url = `${BASE_URL}/list`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IListResponse<IUso> | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IListResponse<IUso>;
}
