import { createAction } from '@reduxjs/toolkit';
import { IUserGroup } from '../../../interfaces/user/models';
import { EMPTY, GET, IUserGroupState, LIST } from './types';

export const listAction = createAction<IUserGroupState>(LIST);

export const getAction = createAction<IUserGroup>(GET);

export const emptyAction = createAction(EMPTY);

