import { Box, TextField } from "@mui/material";
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from "react";
import { useDettaglioUpdateCommercials } from "../../../../../../../effects/dettaglio/dettaglio";
import { DettaglioUpdateCommercialsFormData } from "../../../../../../../interfaces/dettaglio/forms";
import { IDettaglio } from "../../../../../../../interfaces/dettaglio/models";
import { IUpdateDialogFormsProps, UpdateDialogFormsHandle } from "../interfaces";

const CommercialsForm: ForwardRefRenderFunction<UpdateDialogFormsHandle, IUpdateDialogFormsProps> = (({ lottoId, selectedItems, hidden }, ref) => {
	const [ids, setIds] = useState<number[]>([]);
	const [um, setUm] = useState<string>("")
	const [initValue, setInitValue] = useState<IDettaglio | null>(null);
	const [lastreDa, setLastreDa] = useState<string>("");
	const [lastreA, setLastreA] = useState<string>("");
	const [prezzo, setPrezzo] = useState<string>("");
	const [formData, setFormData] = useState<DettaglioUpdateCommercialsFormData>(null);

	const onLastreDaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLastreDa(event.target.value);
	};

	const onLastreAChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLastreA(event.target.value);
	};

	const onPrezzoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPrezzo(event.target.value);
	};

	const onSubmit = () => {

		if (
			!ids.length ||
			(
				String(initValue?.lastreDa) === lastreDa &&
				String(initValue?.lastreA) === lastreA &&
				String(parseFloat(String(initValue?.prezzo)).toFixed(2)) === prezzo
			) ||
			(!!ids.length && !lastreDa && !lastreA && !prezzo)
		) {
			return;
		}
		let _formData: DettaglioUpdateCommercialsFormData = {}
		_formData.lastreDa = !!lastreDa ? parseInt(lastreDa) : undefined;
		_formData.lastreA = !!lastreA ? parseInt(lastreA) : undefined;
		_formData.prezzo = !!prezzo ? Number(parseFloat(prezzo).toFixed(2)) : undefined;
		setFormData(_formData);
	}

	useImperativeHandle(ref, () => ({
		onSubmit
	}));

	useEffect(() => {
		setFormData(null);
		if (selectedItems.length === 1) {
			setInitValue(selectedItems[0]);
		} else {
			setInitValue(null);
		}
		const _ids = selectedItems.map(item => item.id);
		const ums = selectedItems.map(item => item.um);
		const umsSet = new Set(ums);
		setUm(Array.from(umsSet).join('/'));
		setIds(_ids);
	}, [selectedItems])

	useEffect(() => {
		if (!!initValue) {
			setLastreDa(String(initValue.lastreDa));
			setLastreA(String(initValue.lastreA));
			setPrezzo(String(parseFloat(String(initValue.prezzo)).toFixed(2)));
		} else {
			setLastreDa("");
			setLastreA("");
			setPrezzo("");
		}
	}, [initValue]);

	useDettaglioUpdateCommercials(lottoId, ids, formData);

	return (
		<div
			role="tabpanel"
			hidden={hidden}
			id="update-tabpanel-0"
			aria-labelledby="update-tab-0"
		>
			<Box
				sx={{ display: "flex", gap: 1 }}
			>
				{initValue?.tipo_lotto.nome === "Lastre" &&
					<>
						<TextField
							margin="dense"
							label="Da lastra"
							type="number"
							name="lastreDa"
							value={lastreDa}
							onChange={onLastreDaChange}
							sx={{ flex: 1 }}
							inputProps={{ maxLength: 12 }}
						/>
						<TextField
							margin="dense"
							label="A Lastra"
							name="lastreA"
							type="number"
							value={lastreA}
							onChange={onLastreAChange}
							sx={{ flex: 1 }}
							inputProps={{ maxLength: 12 }}
						/>
					</>
				}
				<TextField
					margin="dense"
					label={`Prezzo a ${um}`}
					name="prezzo"
					type="number"
					value={prezzo}
					onChange={onPrezzoChange}
					sx={{ flex: 1 }}
					inputProps={{ maxLength: 12 }}
				/>
			</Box>
		</div>
	)
});

export default forwardRef(CommercialsForm);