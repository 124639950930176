import { List, ListSubheader } from "@mui/material";
import { settings } from "../../../../../../../customize/settings";
import { IFilterProps } from "../../interfaces";
import CategorieFilter from "./categorie";
import FondiFilter from "./fondi";
import QualitaFilter from "./qualita";
import UsiFilter from "./uso";
import VenatureFilter from "./venature";

function QualitaFiltersContainer({ filters, setFilters }: IFilterProps) {
  return (
    <List
      dense={true}
      sx={{ p: 0 }}
      component="nav"
      subheader={<ListSubheader component="div">Qualità</ListSubheader>}
    >
      {!settings.list?.sideNav?.hiddenFilters?.qualitaQualita && (
        <QualitaFilter filters={filters} setFilters={setFilters} />
      )}
      {!settings.list?.sideNav?.hiddenFilters?.qualitaCategorie && (
        <CategorieFilter filters={filters} setFilters={setFilters} />
      )}
      {!settings.list?.sideNav?.hiddenFilters?.qualitaFondi && (
        <FondiFilter filters={filters} setFilters={setFilters} />
      )}
      {!settings.list?.sideNav?.hiddenFilters?.qualitaVenature && (
        <VenatureFilter filters={filters} setFilters={setFilters} />
      )}
      {!settings.list?.sideNav?.hiddenFilters?.qualitaUsi && (
        <UsiFilter filters={filters} setFilters={setFilters} />
      )}
    </List>
  );
}

export default QualitaFiltersContainer;
