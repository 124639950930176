import React from 'react';
import { Route, Routes } from 'react-router-dom';
import List from './list';
import Store from './store';

function Router() {
	return (
		<Routes>
			<Route path="*" element={<List />} />
			<Route path=":prospectId" element={<Store />} />
			<Route path="new" element={<Store />} />

		</Routes>
	);
}

export default Router;