import { IFila } from "../../../interfaces/place/models";

export const GET = "fila/get";
export const LIST = "fila/list";
export const EMPTY = "fila/empty";

export interface IFilaState {
	results: number;
	list: number[];
	items: Record<number, IFila> | null;
};

export const initialState: IFilaState = {
	results: 0,
	list: [],
	items: null
};