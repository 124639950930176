import { createAction } from '@reduxjs/toolkit';
import { IDisponibilita } from '../../../../interfaces/dettaglio/attributes/models';
import { EMPTY, GET, IDisponibilitaState, LIST } from './types';

export const listAction = createAction<IDisponibilitaState>(LIST);

export const getAction = createAction<IDisponibilita>(GET);

export const emptyAction = createAction(EMPTY);

