import { Alert, AlertColor, Snackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from "../../../store/hooks";

function NotificationUI() {
	const [open, setOpen] = useState<boolean>(false);
	const [severity, setSeverity] = useState<AlertColor | undefined>();
	const [messageText, setMessageText] = useState<string>("");
	const message = useAppSelector((state) => state.ui.message);
	useEffect(() => {
		if (message) {
			setSeverity(message.kind);
			setMessageText(message.message);
			setOpen(true);
		}
	}, [message]);
	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') return;
		setOpen(false);
	};
	return (
		<Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
			<Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
				{messageText}
			</Alert>
		</Snackbar>
	);

}

export default NotificationUI;
