import { IProspect } from "../../interfaces/prospect/models";

export const GET = "prospect/get";
export const LIST = "prospect/list";
export const EMPTY = "prospect/empty";

export interface IProspectState {
	results: number;
	list: number[];
	items: Record<number, IProspect> | null;
};

export const initialState: IProspectState = {
	results: 0,
	list: [],
	items: null
};