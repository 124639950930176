import { List, ListSubheader } from "@mui/material";
import { settings } from "../../../../../../../customize/settings";
import CaveFilter from "./cave";
import { IMaterialeFiltersContainerProps } from "./interfaces";
import MaterialiFilter from "./materiali";
import TipiLottoFilter from "./tipiLotto";

function MaterialeFiltersContainer({
  filters,
  setFilters,
  canViewQuarry,
}: IMaterialeFiltersContainerProps) {
  return (
    <List
      dense={true}
      sx={{ p: 0 }}
      component="nav"
      subheader={<ListSubheader component="div">Materiale</ListSubheader>}
    >
      {!settings.list?.sideNav?.hiddenFilters?.materialeMateriali && (
        <MaterialiFilter filters={filters} setFilters={setFilters} />
      )}
      {!settings.list?.sideNav?.hiddenFilters?.materialeTipoLotto && (
        <TipiLottoFilter filters={filters} setFilters={setFilters} />
      )}
      {canViewQuarry &&
        !settings.list?.sideNav?.hiddenFilters?.materialeCave && (
          <CaveFilter filters={filters} setFilters={setFilters} />
        )}
    </List>
  );
}

export default MaterialeFiltersContainer;
