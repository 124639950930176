import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from '@mui/x-data-grid';

function TableToolbar() {
	return (
		<GridToolbarContainer>
			<GridToolbarFilterButton />
			<GridToolbarColumnsButton touchRippleRef={undefined} />
			<GridToolbarExport csvOptions={{ fileName: "products", delimiter: ';' }} touchRippleRef={undefined} />
		</GridToolbarContainer>
	);
}

export default TableToolbar;