import { Card, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

const getCodiceLotto = (): string => {
	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const codiceLotto = urlParams.get('cod');
	return codiceLotto || '';
}

function ExpiredTokenError() {
	const [codiceLotto, setCodiceLotto] = useState<string>('');
	useEffect(() => {
		setCodiceLotto(getCodiceLotto());
	}, [])
	return (
		<Container sx={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
			<Card sx={{ p: 4, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
				<Typography variant="h5" color="text.secondary" sx={{ mr: 1 }}>Non sei autorizzato ad accedere alla pagina</Typography>
				<Typography variant="overline" color="error" sx={{ mr: 1 }}>token errato o scaduto</Typography>
				<Typography variant="overline" color="text.primary" sx={{ mr: 1 }}>Codice Prodotto: {codiceLotto}</Typography>
			</Card>
		</Container>
	)
}

export default ExpiredTokenError;