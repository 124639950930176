import { IFondo } from "../../../../interfaces/lotto/attributes/models";

export const GET = "lotto_fondo/get";
export const LIST = "lotto_fondo/list";
export const EMPTY = "lotto_fondo/empty";

export interface IFondoState {
	results: number;
	list: number[];
	items: Record<number, IFondo> | null;
};

export const initialState: IFondoState = {
	results: 0,
	list: [],
	items: null
};