import { Box, Button, ButtonGroup, Icon, Tooltip } from "@mui/material";
import React from "react";

interface ISelectViewToolbarProps {
	display: "grid" | "table",
	onSetDisplay: (display: "grid" | "table") => void;
}
function SelectViewToolbar({ display, onSetDisplay }: ISelectViewToolbarProps) {
	return (
		<Box sx={{ position: "absolute", right: 8, top: -32, backgroundColor: "#ffffff" }}>
			<ButtonGroup disableElevation>
				<Tooltip title="Griglia">
					<Button
						variant={display === "grid" ? "contained" : "outlined"}
						endIcon={<Icon className="fa-light fa-grid" sx={{ overflow: "visible", marginRight: "4px", marginLeft: "-8px" }} />}
						sx={{ endIcon: { margin: 0 } }}
						onClick={() => onSetDisplay("grid")}
					></Button>
				</Tooltip>
				<Tooltip title="Tabella">
					<Button
						variant={display === "table" ? "contained" : "outlined"}
						endIcon={<Icon className="fa-light fa-table" sx={{ overflow: "visible", marginRight: "4px", marginLeft: "-8px" }} />}
						onClick={() => onSetDisplay("table")}
					></Button>
				</Tooltip>
			</ButtonGroup>
		</Box>
	)
}

export default SelectViewToolbar;