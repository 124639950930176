import { Chip, Divider, Grid, Icon } from "@mui/material";
import Box from "@mui/material/Box";
import { useMemo } from "react";
import { settings } from "../../../../../customize/settings";
import { IFilterOption } from "../../../../../interfaces/dettaglio/models";
import { EOptionType } from "../../../../../interfaces/enums";
import { useAppSelector } from "../../../../../store/hooks";
import CommercialeFiltersContainer from "./filters/commerciale";
import LavorazioniFiltersContainer from "./filters/lavorazioni";
import MaterialeFiltersContainer from "./filters/materiale";
import QualitaFiltersContainer from "./filters/qualita";
import { ISidebarProps } from "./interfaces";

function Sidebar({ filters, setFilters }: ISidebarProps) {
  const user = useAppSelector((state) => state.auth.user);

  const canViewQuarry: boolean = useMemo(
    () => !!user?.admin || !!user?.user_group?.group_permissions?.canViewQuarry,
    [user]
  );
  const canViewCommercialOptions: boolean = useMemo(
    () =>
      !!user?.admin ||
      !!user?.user_group?.group_permissions?.canViewCommercialOptions,
    [user]
  );
  const showFilters: boolean = useMemo(
    () => filters.some((opt) => opt.type !== EOptionType.fullText),
    [filters]
  );

  const removeFilterOption = (option: IFilterOption) => {
    setFilters(
      filters.filter(
        (opt) =>
          !(
            opt.type === option.type &&
            opt.numericValue === option.numericValue &&
            opt.stringValue === option.stringValue
          )
      )
    );
  };

  const removeMagazzinoOptions = (_: IFilterOption) => {
    setFilters(
      filters.filter(
        (opt) =>
          opt.type !== EOptionType.magazzino &&
          opt.type !== EOptionType.zona &&
          opt.type !== EOptionType.fila
      )
    );
  };

  const removeZonaOptions = (_: IFilterOption) => {
    setFilters(
      filters.filter(
        (opt) => opt.type !== EOptionType.zona && opt.type !== EOptionType.fila
      )
    );
  };

  const removeDisponibilitàOptions = (_: IFilterOption) => {
    setFilters(
      filters.filter(
        (opt) =>
          opt.type !== EOptionType.disponibilita &&
          opt.type !== EOptionType.cliente
      )
    );
  };

  return (
    <Box sx={{ flex: 1 }}>
      {showFilters && (
        <Grid sx={{ p: 1 }} container>
          {filters.map((option, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sx={{
                p: 0.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {option.type === EOptionType.disponibilita && (
                <Chip
                  icon={
                    <Icon
                      className="fa-light fa-briefcase"
                      sx={{ overflow: "visible" }}
                    />
                  }
                  label={option.stringValue}
                  onDelete={() => removeDisponibilitàOptions(option)}
                />
              )}
              {option.type === EOptionType.cliente && (
                <Chip
                  icon={
                    <Icon
                      className="fa-light fa-users"
                      sx={{ overflow: "visible" }}
                    />
                  }
                  label={option.stringValue}
                  onDelete={() => removeFilterOption(option)}
                />
              )}
              {option.type === EOptionType.prezzo && (
                <Chip
                  icon={
                    <Icon
                      className="fa-light fa-euro-sign"
                      sx={{ overflow: "visible" }}
                    />
                  }
                  label={option.stringValue}
                  onDelete={() => removeFilterOption(option)}
                />
              )}
              {option.type === EOptionType.statoLavorazione && (
                <Chip
                  icon={
                    <Icon
                      className="fa-light fa-screwdriver-wrench"
                      sx={{ overflow: "visible" }}
                    />
                  }
                  label={option.stringValue}
                  onDelete={() => removeFilterOption(option)}
                />
              )}
              {option.type === EOptionType.categoria && (
                <Chip
                  icon={
                    <Icon
                      className="fa-light fa-circle-star"
                      sx={{ overflow: "visible" }}
                    />
                  }
                  label={option.stringValue}
                  onDelete={() => removeFilterOption(option)}
                />
              )}
              {option.type === EOptionType.cava && (
                <Chip
                  icon={
                    <Icon
                      className="fa-light fa-location-dot"
                      sx={{ overflow: "visible" }}
                    />
                  }
                  label={option.stringValue}
                  onDelete={() => removeFilterOption(option)}
                />
              )}
              {option.type === EOptionType.fondo && (
                <Chip
                  icon={
                    <Icon
                      className="fa-light fa-palette"
                      sx={{ overflow: "visible" }}
                    />
                  }
                  label={option.stringValue}
                  onDelete={() => removeFilterOption(option)}
                />
              )}
              {option.type === EOptionType.magazzino && (
                <Chip
                  icon={
                    <Icon
                      className="fa-light fa-warehouse-full"
                      sx={{ overflow: "visible" }}
                    />
                  }
                  label={option.stringValue}
                  onDelete={() => removeMagazzinoOptions(option)}
                />
              )}
              {option.type === EOptionType.zona && (
                <Chip
                  icon={
                    <Icon
                      className="fa-light fa-map-pin"
                      sx={{ overflow: "visible" }}
                    />
                  }
                  label={option.stringValue}
                  onDelete={() => removeZonaOptions(option)}
                />
              )}
              {option.type === EOptionType.fila && (
                <Chip
                  icon={
                    <Icon
                      className="fa-light fa-lines-leaning"
                      sx={{ overflow: "visible" }}
                    />
                  }
                  label={option.stringValue}
                  onDelete={() => removeFilterOption(option)}
                />
              )}
              {option.type === EOptionType.materiale && (
                <Chip
                  icon={
                    <Icon
                      className="fa-light fa-object-group"
                      sx={{ overflow: "visible" }}
                    />
                  }
                  label={option.stringValue}
                  onDelete={() => removeFilterOption(option)}
                />
              )}
              {option.type === EOptionType.qualita && (
                <Chip
                  icon={
                    <Icon
                      className="fa-light fa-circle-bookmark"
                      sx={{ overflow: "visible" }}
                    />
                  }
                  label={option.stringValue}
                  onDelete={() => removeFilterOption(option)}
                />
              )}
              {option.type === EOptionType.tipoLotto && (
                <Chip
                  icon={
                    <Icon
                      className="fa-light fa-shapes"
                      sx={{ overflow: "visible" }}
                    />
                  }
                  label={option.stringValue}
                  onDelete={() => removeFilterOption(option)}
                />
              )}
              {option.type === EOptionType.uso && (
                <Chip
                  icon={
                    <Icon
                      className="fa-light fa-ruler"
                      sx={{ overflow: "visible" }}
                    />
                  }
                  label={option.stringValue}
                  onDelete={() => removeFilterOption(option)}
                />
              )}
              {option.type === EOptionType.venatura && (
                <Chip
                  icon={
                    <Icon
                      className="fa-light fa-droplet"
                      sx={{ overflow: "visible" }}
                    />
                  }
                  label={option.stringValue}
                  onDelete={() => removeFilterOption(option)}
                />
              )}
              {option.type === EOptionType.daSegare && (
                <Chip
                  icon={
                    <Icon
                      className="fa-light fa-info"
                      sx={{ overflow: "visible" }}
                    />
                  }
                  label="Blocchi da segare"
                  onDelete={() => removeFilterOption(option)}
                />
              )}
            </Grid>
          ))}
        </Grid>
      )}
      {canViewCommercialOptions &&
        !settings.list?.sideNav?.hiddenFilters?.commerciale && (
          <>
            <Divider />

            <CommercialeFiltersContainer
              filters={filters}
              setFilters={setFilters}
            />
          </>
        )}
      {!settings.list?.sideNav?.hiddenFilters?.materiale && (
        <>
          <Divider />

          <MaterialeFiltersContainer
            filters={filters}
            setFilters={setFilters}
            canViewQuarry={canViewQuarry}
          />
        </>
      )}
      {!settings.list?.sideNav?.hiddenFilters?.qualita && (
        <>
          <Divider />

          <QualitaFiltersContainer filters={filters} setFilters={setFilters} />
        </>
      )}
      {!settings.list?.sideNav?.hiddenFilters?.statoLavorazioni && (
        <>
          <Divider />

          <LavorazioniFiltersContainer
            filters={filters}
            setFilters={setFilters}
          />
        </>
      )}
    </Box>
  );
}

export default Sidebar;
