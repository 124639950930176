import { AppBar, Box, Button, Dialog, DialogActions, FormControl, Icon, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Toolbar, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLottoUpdate } from '../../../../../../effects/lotto/lotto';
import { ICategoria, IFondo, IMateriale, IQualita, IUso, IVenatura } from '../../../../../../interfaces/lotto/attributes/models';
import { LottoUpdateFormData } from '../../../../../../interfaces/lotto/forms';
import Transition from '../../../../../../lib/dialogTransition';
import { useAppSelector } from '../../../../../../store/hooks';
import { IUpdateDialogProps } from './interfaces';

function UpdateDialog({ lotto, isOpen, close }: IUpdateDialogProps) {
	const [id, setId] = useState<number | null>(null);
	const [idMateriale, setIdMateriale] = useState<string>("");
	const [idQualita, setIdQualita] = useState<string>("");
	const [idCategoria, setIdCategoria] = useState<string>("");
	const [idFondo, setIdFondo] = useState<string>("");
	const [idVenatura, setIdVenatura] = useState<string>("");
	const [idUso, setIdUso] = useState<string>("");
	const [updateData, setUpdateData] = useState<LottoUpdateFormData>(null);
	const [materialeOptions, setMaterialeOptions] = useState<IMateriale[]>([]);
	const [qualitaOptions, setQualitaOptions] = useState<IQualita[]>([]);
	const [categoriaOptions, setCategoriaOptions] = useState<ICategoria[]>([]);
	const [fondoOptions, setFondoOptions] = useState<IFondo[]>([]);
	const [venaturaOptions, setVenaturaOptions] = useState<IVenatura[]>([]);
	const [usoOptions, setUsoOptions] = useState<IUso[]>([]);

	const materialeList = useAppSelector(state => state.lotto_materiale.list);
	const materialeMap = useAppSelector(state => state.lotto_materiale.items);

	const qualitaList = useAppSelector(state => state.lotto_qualita.list);
	const qualitaMap = useAppSelector(state => state.lotto_qualita.items);

	const categoriaList = useAppSelector(state => state.lotto_categoria.list);
	const categoriaMap = useAppSelector(state => state.lotto_categoria.items);

	const fondoList = useAppSelector(state => state.lotto_fondo.list);
	const fondoMap = useAppSelector(state => state.lotto_fondo.items);

	const venaturaList = useAppSelector(state => state.lotto_venatura.list);
	const venaturaMap = useAppSelector(state => state.lotto_venatura.items);

	const usoList = useAppSelector(state => state.lotto_uso.list);
	const usoMap = useAppSelector(state => state.lotto_uso.items);

	const onIdMaterialeChange = (event: SelectChangeEvent<string>) => {
		setIdMateriale(event.target.value);
	};
	const onIdQualitaChange = (event: SelectChangeEvent<string>) => {
		setIdQualita(event.target.value);
	};
	const onIdCategoriaChange = (event: SelectChangeEvent<string>) => {
		setIdCategoria(event.target.value);
	};
	const onIdFondoChange = (event: SelectChangeEvent<string>) => {
		setIdFondo(event.target.value);
	};
	const onIdVenaturaChange = (event: SelectChangeEvent<string>) => {
		setIdVenatura(event.target.value);
	};
	const onIdUsoChange = (event: SelectChangeEvent<string>) => {
		setIdUso(event.target.value);
	};

	const onSubmit = () => {
		if (!id) return;
		const _updateData: LottoUpdateFormData = {
			idMateriale: (!!idMateriale) ? parseInt(idMateriale) : null,
			idQualita: (!!idQualita) ? parseInt(idQualita) : null,
			idCategoria: (!!idCategoria) ? parseInt(idCategoria) : null,
			idFondo: (!!idFondo) ? parseInt(idFondo) : null,
			idVenatura: (!!idVenatura) ? parseInt(idVenatura) : null,
			idUso: (!!idUso) ? parseInt(idUso) : null
		}
		setUpdateData(_updateData);
	}

	useEffect(() => {
		if (!!materialeList && !!materialeMap) {
			const _materialeOptions: IMateriale[] = materialeList.map(item => materialeMap[item]);
			setMaterialeOptions(_materialeOptions);
		}
	}, [materialeList, materialeMap]);
	useEffect(() => {
		if (!!qualitaList && !!qualitaMap) {
			const _qualitaOptions: IQualita[] = qualitaList.map(item => qualitaMap[item]);
			setQualitaOptions(_qualitaOptions);
		}
	}, [qualitaList, qualitaMap]);
	useEffect(() => {
		if (!!categoriaList && !!categoriaMap) {
			const _categoriaOptions: ICategoria[] = categoriaList.map(item => categoriaMap[item]);
			setCategoriaOptions(_categoriaOptions);
		}
	}, [categoriaList, categoriaMap]);
	useEffect(() => {
		if (!!fondoList && !!fondoMap) {
			const _fondoOptions: IFondo[] = fondoList.map(item => fondoMap[item]);
			setFondoOptions(_fondoOptions);
		}
	}, [fondoList, fondoMap]);
	useEffect(() => {
		if (!!venaturaList && !!venaturaMap) {
			const _venaturaOptions: IVenatura[] = venaturaList.map(item => venaturaMap[item]);
			setVenaturaOptions(_venaturaOptions);
		}
	}, [venaturaList, venaturaMap]);
	useEffect(() => {
		if (!!usoList && !!usoMap) {
			const _usoOptions: IUso[] = usoList.map(item => usoMap[item]);
			setUsoOptions(_usoOptions);
		}
	}, [usoList, usoMap]);
	useEffect(() => {
		setId(null);
		setIdMateriale("");
		setIdQualita("");
		setIdCategoria("");
		setIdFondo("");
		setIdVenatura("");
		setIdUso("");
		setUpdateData(null);
		if (!!lotto) {
			setId(lotto.id);
			if (lotto.idMateriale) setIdMateriale(String(lotto.idMateriale));
			if (lotto.idQualita) setIdQualita(String(lotto.idQualita));
			if (lotto.idCategoria) setIdCategoria(String(lotto.idCategoria));
			if (lotto.idFondo) setIdFondo(String(lotto.idFondo));
			if (lotto.idVenatura) setIdVenatura(String(lotto.idVenatura));
			if (lotto.idUso) setIdUso(String(lotto.idUso));
		}
	}, [lotto, isOpen]);
	useLottoUpdate(id, updateData);
	return (
		<Dialog
			open={isOpen}
			onClose={close}
			TransitionComponent={Transition}
			fullWidth
			maxWidth="md"
		>
			<AppBar sx={{ position: "relative" }}>
				<Toolbar variant="dense">
					<Typography variant="h5" sx={{ flex: 1 }} display="block" >Modifica informazioni del lotto</Typography>
					<IconButton
						edge="end"
						color="inherit"
						onClick={close}
						aria-label="close"
					>
						<Icon className="fa-light fa-xmark" sx={{ overflow: "visible" }} />
					</IconButton>
				</Toolbar>

			</AppBar>
			<Box sx={{ p: 2, overflowY: 'auto' }}>
				<FormControl fullWidth sx={{ mt: 1, mb: 0.5 }}>
					<InputLabel id="materiale_label">Materiale</InputLabel>
					<Select
						labelId="materiale_label"
						id="materiale_select"
						readOnly={true}
						value={idMateriale}
						label="Materiale"
						onChange={onIdMaterialeChange}
					>
						<MenuItem value={""}>&nbsp;</MenuItem>
						{materialeOptions.map((item) => (<MenuItem key={String(item.id)} value={String(item.id)}>{item.nome}</MenuItem>))}
					</Select>
				</FormControl>
				<FormControl fullWidth sx={{ mt: 1, mb: 0.5 }}>
					<InputLabel id="qualita_label">Qualità</InputLabel>
					<Select
						labelId="qualita_label"
						id="qualita_select"
						value={idQualita}
						label="Qualità"
						onChange={onIdQualitaChange}
					>
						<MenuItem value={""}>&nbsp;</MenuItem>
						{qualitaOptions.map((item) => (<MenuItem key={String(item.id)} value={String(item.id)}>{item.nome}</MenuItem>))}
					</Select>
				</FormControl>
				<FormControl fullWidth sx={{ mt: 1, mb: 0.5 }}>
					<InputLabel id="categoria_label">Categoria</InputLabel>
					<Select
						labelId="categoria_label"
						id="categoria_select"
						value={idCategoria}
						label="Categoria"
						onChange={onIdCategoriaChange}
					>
						<MenuItem value={""}>&nbsp;</MenuItem>
						{categoriaOptions.map((item) => (<MenuItem key={String(item.id)} value={String(item.id)}>{item.nome}</MenuItem>))}
					</Select>
				</FormControl>
				<FormControl fullWidth sx={{ mt: 1, mb: 0.5 }}>
					<InputLabel id="fondo_label">Fondo</InputLabel>
					<Select
						labelId="fondo_label"
						id="fondo_select"
						value={idFondo}
						label="Fondo"
						onChange={onIdFondoChange}
					>
						<MenuItem value={""}>&nbsp;</MenuItem>
						{fondoOptions.map((item) => (<MenuItem key={String(item.id)} value={String(item.id)}>{item.nome}</MenuItem>))}
					</Select>
				</FormControl>
				<FormControl fullWidth sx={{ mt: 1, mb: 0.5 }}>
					<InputLabel id="venatura_label">Venatura</InputLabel>
					<Select
						labelId="venatura_label"
						id="venatura_select"
						value={idVenatura}
						label="Venatura"
						onChange={onIdVenaturaChange}
					>
						<MenuItem value={""}>&nbsp;</MenuItem>
						{venaturaOptions.map((item) => (<MenuItem key={String(item.id)} value={String(item.id)}>{item.nome}</MenuItem>))}
					</Select>
				</FormControl>
				<FormControl fullWidth sx={{ mt: 1, mb: 0.5 }}>
					<InputLabel id="uso_label">Uso</InputLabel>
					<Select
						labelId="uso_label"
						id="uso_select"
						value={idUso}
						label="Uso"
						onChange={onIdUsoChange}
					>
						<MenuItem value={""}>&nbsp;</MenuItem>
						{usoOptions.map((item) => (<MenuItem key={String(item.id)} value={String(item.id)}>{item.nome}</MenuItem>))}
					</Select>
				</FormControl>
			</Box>
			<DialogActions>
				<Button onClick={close}>Cancella</Button>
				<Button onClick={onSubmit} variant="contained" color="primary">Salva</Button>
			</DialogActions>
		</Dialog>
	);
}

export default UpdateDialog;