import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDettaglioUpdateStorage } from "../../../../../../../effects/dettaglio/dettaglio";
import { DettaglioUpdateStorageFormData } from "../../../../../../../interfaces/dettaglio/forms";
import { IDettaglio } from "../../../../../../../interfaces/dettaglio/models";
import { IFila, IZona } from "../../../../../../../interfaces/place/models";
import { useAppSelector } from "../../../../../../../store/hooks";
import {
  IUpdateDialogFormsProps,
  UpdateDialogFormsHandle,
} from "../interfaces";

const notToModifiedItem: IZona | IFila = {
  id: -2,
  nome: "-- Non modificare --",
};

const emptyItem: IZona | IFila = {
  id: -1,
  nome: "-- Nessuno --",
};

const StorageForm: ForwardRefRenderFunction<
  UpdateDialogFormsHandle,
  IUpdateDialogFormsProps
> = ({ lottoId, selectedItems, hidden }, ref) => {
  const [ids, setIds] = useState<number[]>([]);
  const [initValue, setInitValue] = useState<IDettaglio | null>(null);
  const [selectedZona, setSelectedZona] = useState<IZona>(emptyItem);
  const [selectedFila, setSelectedFila] = useState<IFila>(emptyItem);
  const [formData, setFormData] =
    useState<DettaglioUpdateStorageFormData>(null);
  const [zonaOptions, setZonaOptions] = useState<IZona[]>([emptyItem]);
  const [filaOptions, setFilaOptions] = useState<IFila[]>([emptyItem]);

  const zonaList = useAppSelector((state) => state.place_zona.list);
  const zonaItems = useAppSelector((state) => state.place_zona.items);

  const filaList = useAppSelector((state) => state.place_fila.list);
  const filaItems = useAppSelector((state) => state.place_fila.items);

  const onZonaChange = (event: SelectChangeEvent) => {
    const zonaId = parseInt(String(event.target.value));
    const zona = zonaOptions.find((item) => item.id === zonaId) ?? emptyItem;
    setSelectedZona(zona);
  };

  const onFilaChange = (event: SelectChangeEvent) => {
    const filaId = parseInt(String(event.target.value));
    const fila = filaOptions.find((item) => item.id === filaId) ?? emptyItem;
    setSelectedFila(fila);
  };

  const onSubmit = () => {
    const initZona = initValue?.zona || null;
    const initFila = initValue?.fila || null;
    if (
      !ids.length ||
      (initZona?.id === selectedZona?.id && initFila?.id === selectedFila?.id)
    ) {
      return;
    }
    let _formData: DettaglioUpdateStorageFormData = {};

    if (selectedZona?.id === emptyItem.id) _formData.zona = null;
    else if (selectedZona?.id === notToModifiedItem.id)
      _formData.zona = undefined;
    else _formData.zona = selectedZona;

    if (selectedFila?.id === emptyItem.id) _formData.fila = null;
    else if (selectedFila?.id === notToModifiedItem.id)
      _formData.fila = undefined;
    else _formData.fila = selectedFila;

    setFormData(_formData);
  };

  useImperativeHandle(ref, () => ({
    onSubmit,
  }));

  useEffect(() => {
    setFormData(null);
    if (selectedItems.length === 1) {
      setInitValue(selectedItems[0]);
    } else {
      setInitValue(null);
    }
    const _ids = selectedItems.map((item) => item.id);
    setIds(_ids);
  }, [selectedItems]);

  useEffect(() => {
    if (!!initValue && ids.length === 1) {
      const zonaId = initValue.idZona || null;
      const zona = zonaOptions.find((item) => item.id === zonaId) ?? emptyItem;
      const filaId = initValue.idFila || null;
      const fila = filaOptions.find((item) => item.id === filaId) ?? emptyItem;
      setSelectedZona(zona);
      setSelectedFila(fila);
    } else if (ids.length > 1) {
      setSelectedZona(notToModifiedItem);
      setSelectedFila(notToModifiedItem);
    }
  }, [initValue, ids, zonaOptions, filaOptions]);

  useEffect(() => {
    const _zonaOptions: IZona[] =
      ids.length > 1 ? [notToModifiedItem, emptyItem] : [emptyItem];
    if (!!zonaList && !!zonaItems) {
      _zonaOptions.push(...zonaList.map((id) => zonaItems[id]));
      setZonaOptions(_zonaOptions);
    }
  }, [zonaList, zonaItems, ids]);

  useEffect(() => {
    const _filaOptions: IZona[] =
      ids.length > 1 ? [notToModifiedItem, emptyItem] : [emptyItem];
    if (!!filaList && !!filaItems) {
      _filaOptions.push(...filaList.map((id) => filaItems[id]));
      setFilaOptions(_filaOptions);
    }
  }, [filaList, filaItems, ids]);

  useDettaglioUpdateStorage(lottoId, ids, formData);

  return (
    <div
      role="tabpanel"
      hidden={hidden}
      id="update-tabpanel-0"
      aria-labelledby="update-tab-0"
    >
      <Box sx={{ display: "flex", gap: 1 }}>
        <FormControl sx={{ mt: 1, mb: 0.5, mr: 1, flex: 1 }}>
          <InputLabel id="zona-label">Zona</InputLabel>
          <Select
            labelId="zona-label"
            value={String(selectedZona?.id)}
            label="Disponibilità"
            onChange={onZonaChange}
          >
            {zonaOptions.map((opt) => (
              <MenuItem key={String(opt.id)} value={String(opt.id)}>
                {opt.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ mt: 1, mb: 0.5, mr: 1, flex: 1 }}>
          <InputLabel id="fila-label">Fila</InputLabel>
          <Select
            labelId="fila-label"
            value={String(selectedFila?.id)}
            label="Fila"
            onChange={onFilaChange}
          >
            {filaOptions.map((opt) => (
              <MenuItem key={String(opt.id)} value={String(opt.id)}>
                {opt.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default forwardRef(StorageForm);
