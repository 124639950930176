import { settings } from "../../customize/settings";
import {
  IDettaglio,
  IDettaglioSplit,
  IDettaglioUpdateCommercialOptions,
  IDettaglioUpdateCommercials,
  IDettaglioUpdateMeasures,
  IDettaglioUpdateNotes,
  IDettaglioUpdateStatoLavorazione,
  IDettaglioUpdateStorage,
} from "../../interfaces/dettaglio/models";
import { IBaseError } from "../../interfaces/errors";
import { IListResponse, IUpdateResponse } from "../../interfaces/rest";
import { getJWT } from "../../lib/jwt";

const BASE_URL = `${settings.apiUrl}dettaglio`;

export const getDettaglio = async (id: number): Promise<IDettaglio> => {
  const url = `${BASE_URL}/${id}`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const json: IDettaglio | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IDettaglio;
};

export const getDettaglioList = async (): Promise<
  IListResponse<IDettaglio>
> => {
  const url = `${BASE_URL}/listSalable`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const json: IListResponse<IDettaglio> | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IListResponse<IDettaglio>;
};

export const getDettaglioSoldList = async (): Promise<
  IListResponse<IDettaglio>
> => {
  const url = `${BASE_URL}/listSold`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const json: IListResponse<IDettaglio> | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IListResponse<IDettaglio>;
};

export const splitDettaglio = async (
  id: number,
  data: IDettaglioSplit
): Promise<IUpdateResponse> => {
  const url = `${BASE_URL}/split/${id}`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const body = JSON.stringify(data);
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body,
  });
  const json: IUpdateResponse | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IUpdateResponse;
};

export const updateStorageDettaglio = async (
  id: number,
  data: IDettaglioUpdateStorage
): Promise<IUpdateResponse> => {
  const url = `${BASE_URL}/updateStorage/${id}`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const body = JSON.stringify({
    idZona: data.zona?.id || undefined,
    idFila: data.fila?.id || undefined,
  });
  const response = await fetch(url, {
    method: "PUT",
    headers: headers,
    body,
  });
  const json: IUpdateResponse | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IUpdateResponse;
};

export const updateCommercialsDettaglio = async (
  id: number,
  data: IDettaglioUpdateCommercials
): Promise<IUpdateResponse> => {
  const url = `${BASE_URL}/updateCommercials/${id}`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const body = JSON.stringify(data);
  const response = await fetch(url, {
    method: "PUT",
    headers: headers,
    body,
  });
  const json: IUpdateResponse | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IUpdateResponse;
};

export const updateStatoLavorazioneDettaglio = async (
  id: number,
  data: IDettaglioUpdateStatoLavorazione
): Promise<IUpdateResponse> => {
  const url = `${BASE_URL}/updateStatoLavorazione/${id}`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const body: {
    idStatoLavorazione?: number | null;
  } = {
    idStatoLavorazione: undefined,
  };
  if (data !== undefined) {
    body.idStatoLavorazione = data.statoLavorazione?.id || null;
  }
  const stringifyBody = JSON.stringify(body);
  const response = await fetch(url, {
    method: "PUT",
    headers: headers,
    body: stringifyBody,
  });
  const json: IUpdateResponse | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IUpdateResponse;
};

export const updateCommercialOptionsDettaglio = async (
  id: number,
  data: IDettaglioUpdateCommercialOptions
): Promise<IUpdateResponse> => {
  const url = `${BASE_URL}/updateCommercialOptions/${id}`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const body = JSON.stringify(data);
  const response = await fetch(url, {
    method: "PUT",
    headers: headers,
    body,
  });
  const json: IUpdateResponse | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IUpdateResponse;
};

export const updateNotesDettaglio = async (
  id: number,
  data: IDettaglioUpdateNotes
): Promise<IUpdateResponse> => {
  const url = `${BASE_URL}/updateNotes/${id}`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const body = JSON.stringify(data);
  const response = await fetch(url, {
    method: "PUT",
    headers: headers,
    body,
  });
  const json: IUpdateResponse | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IUpdateResponse;
};

export const updateMeasuresDettaglio = async (
  id: number,
  data: IDettaglioUpdateMeasures
): Promise<IUpdateResponse> => {
  const url = `${BASE_URL}/updateMeasures/${id}`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const body = JSON.stringify(data);
  const response = await fetch(url, {
    method: "PUT",
    headers: headers,
    body,
  });
  const json: IUpdateResponse | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IUpdateResponse;
};
