import { Box } from '@mui/material';
import GridThumbnail from './gridThumbnail';
import { IGridCellProps } from './interfaces';

export const GridCell = ({ columnIndex, rowIndex, style, data, onView }: IGridCellProps) => {
	const item = data[rowIndex][columnIndex];
	if (!item) return null;
	return (
		<Box
			style={style}
			sx={{ p: 1, boxSizing: "border-box" }}
		>
			<GridThumbnail onView={() => onView(item.idLotto)} item={item} />
		</Box>
	);
}