import { AppBar, Box, Button, Dialog, DialogActions, Toolbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useImageRemove } from '../../../../../../../effects/image/image';
import Transition from '../../../../../../../lib/dialogTransition';
import { IDeleteDialogProps } from './interfaces';

const i18_PATH = 'prodotto.view.lottoImages.dialog.deleteDialog.'

function DeleteDialog({ isOpen, image, close }: IDeleteDialogProps) {
	const { t } = useTranslation();
	const [id, setId] = useState<number | null>(null);
	const onSubmit = () => {
		if (!!image) setId(image.id);
	}
	useEffect(() => {
		setId(null);
	}, [image])
	useImageRemove(id);
	return (
		<Dialog
			open={isOpen}
			onClose={close}
			TransitionComponent={Transition}
			fullWidth
			maxWidth="md"
		>
			<AppBar sx={{ position: 'relative' }}>
				<Toolbar variant="dense">
					<Typography variant="h5" sx={{ flex: 1 }} display="block" >{t(`${i18_PATH}Elimina immagine`)}</Typography>
				</Toolbar>
			</AppBar>
			<Box sx={{ p: 2, overflowY: 'auto' }}>
				<Typography variant="h5" sx={{ flex: 1 }} display="block" >{t(`${i18_PATH}Sei sicuro di elimiare questa immagine?`)}</Typography>
			</Box>
			<DialogActions>
				<Button onClick={close}>{t(`${i18_PATH}Cancella`)}</Button>
				<Button onClick={onSubmit} variant="contained" color="primary">{t(`${i18_PATH}Elimina`)}</Button>
			</DialogActions>
		</Dialog >
	);
}

export default DeleteDialog;