import { ICategoria } from "../../../../interfaces/lotto/attributes/models";

export const GET = "lotto_categoria/get";
export const LIST = "lotto_categoria/list";
export const EMPTY = "lotto_categoria/empty";

export interface ICategoriaState {
	results: number;
	list: number[];
	items: Record<number, ICategoria> | null;
};

export const initialState: ICategoriaState = {
	results: 0,
	list: [],
	items: null
};