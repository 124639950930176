import { Box, Button } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { IUser } from '../../../interfaces/user/models';

interface ICustomLinkProp {
	text: string;
	to: string;
	isCurrent: boolean;
}

function CustomLink({ to, text, isCurrent }: ICustomLinkProp) {

	return (
		<Button
			color="primary"
			sx={{ pt: 2, mx: 1, display: 'flex', textDecoration: isCurrent ? 'underline' : 'none' }}
			component={NavLink}
			variant="text"
			to={to}
		>
			{text}
		</Button>
	);
}

interface ICustomBreadcrumbProps {
	user: IUser | null;
}

export default function CustomBreadcrumbs({ user }: ICustomBreadcrumbProps) {
	const { pathname } = useLocation();
	const pathnameArray = pathname.split('/').filter(item => !!item);
	return (
		<>
			{(!!user && !user.unknown) &&
				<Box role="presentation" sx={{ backgroundColor: "#fff" }}>
					<Breadcrumbs aria-label="breadcrumb" >
						{pathname !== "/" && <CustomLink to="/" text="home" isCurrent={false} />}
						{
							pathnameArray.map((element, index, array) => {
								const to = array.slice(0, index + 1).join('/');
								const text = element;
								const isCurrent = array.length - 1 === index;
								return (
									<CustomLink key={to} to={to} text={text} isCurrent={isCurrent} />
								)
							})
						}

					</Breadcrumbs>
				</Box>

			}
		</>
	);
}