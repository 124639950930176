import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDettaglioList } from "../../../effects/dettaglio/dettaglio";
import { useLottoList } from "../../../effects/lotto/lotto";
import { IDettaglio, IFilterOption } from "../../../interfaces/dettaglio/models";
import { PostMessageSendRows } from "../../../interfaces/postMessage/types";
import { createFilter } from "../../../lib/filters";
import { useAppSelector } from "../../../store/hooks";
import Infobar from "./components/footer/infobar";
import GridView from "./components/gridView/gridView";
import Sidebar from "./components/sidebar/sidebar";
import TableView from "./components/tableView/tableView";
import SearchToolbar from "./components/toolbar/searchToolbar";
import SelectViewToolbar from "./components/toolbar/selectViewToolbar";

const sendLottoIdsMessage = (productWindow: Window | null, rows: IDettaglio[]) => {
	if (productWindow === null || productWindow?.closed) return;
	const allLottoIds: number[] = rows.map(row => row.idLotto);
	const lottoIdsSet = new Set(allLottoIds);
	const lottoIds = Array.from(lottoIdsSet);
	const message: PostMessageSendRows = {
		type: "GEMEG_DETTAGLI_LIST",
		lottoIds
	}
	productWindow.postMessage(message);
}

function DettaglioList() {
	const [canViewCommercials, setCanViewCommercials] = useState<boolean>(false);
	const [productWindow, setProductWindow] = useState<Window | null>(null)
	const [productWindowReady, setProductWindowReady] = useState<boolean>(false);
	const [filters, setFilters] = useState<IFilterOption[]>([]);
	const [display, setDisplay] = useState<"table" | "grid">("table");
	const [rows, setRows] = useState<IDettaglio[]>([]);
	const [rowsSelected, setRowsSelected] = useState<number[]>([]);
	const [query] = useState<number>(1);

	const messageListener = (event: MessageEvent) => {
		const { data } = event;
		if (data.type === 'GEMEG_VIEW_READY') {
			setProductWindowReady(true);
		}
	}
	const view = (idLotto: number) => {
		/**@TODO return to simple url (removing #) when server htaccess was set */
		const url = `/#/prodotti/${idLotto}`;
		if (productWindow === null || productWindow?.closed) {
			setProductWindowReady(false);
			const _productWindow = window.open(url);
			setProductWindow(_productWindow);
		} else {
			productWindow.location.href = url;
			productWindow.focus();
		}
	}
	const list = useAppSelector((state) => state.dettaglio.list);
	const items = useAppSelector((state) => state.dettaglio.items);
	const user = useAppSelector((state) => state.auth.user);
	useDettaglioList(query);
	useLottoList(query);
	useEffect(() => {
		const dettaglioLotti: IDettaglio[] = (!!list && !!items) ? list.map(item => items[item]) : [];
		if (!!filters && !!filters.length) {
			const filter = createFilter(filters);
			const _rows = dettaglioLotti.filter((item) => filter(item));
			setRows(_rows);
		} else {
			setRows(dettaglioLotti);
		}
	}, [list, items, filters]);
	useEffect(() => {
		if (productWindowReady && productWindow !== null) {
			sendLottoIdsMessage(productWindow, rows);
		}
	}, [rows, productWindow, productWindowReady]);
	useEffect(() => {
		window.addEventListener('message', messageListener, false);
		return () => {
			window.removeEventListener('message', messageListener);
		}
	}, []);
	useEffect(() => {
		/** price_allowed is for anonymous user */
		const _canViewCommercials = !!user?.admin || !!user?.user_group?.group_permissions?.canViewCommercials || !!user?.price_allowed;
		setCanViewCommercials(_canViewCommercials);
	}, [user])
	useEffect(() => {
		setRowsSelected([]);
	}, [rows])
	return (
		<Grid container spacing={0} sx={{ flex: 1 }}>
			<Grid item xs={2} sx={{ display: "flex", height: "100%", position: "relative", flexDirection: "column" }}>
				<Box sx={{ backgroundColor: "#fff", borderRight: "1px solid grey", position: "absolute", height: "100%", width: "100%", overflowY: "scroll" }}>
					<Sidebar
						user={user}
						filters={filters}
						setFilters={setFilters}
					></Sidebar>
				</Box>
			</Grid>
			<Grid item xs={10} sx={{ display: "flex", flexDirection: "column", position: "relative", backgroundColor: "#fff" }}>
				<SearchToolbar
					filters={filters}
					setFilters={setFilters}
				/>
				<SelectViewToolbar
					display={display}
					onSetDisplay={setDisplay}
				/>
				{
					display === "table" &&

					<TableView
						rows={rows}
						rowsSelected={rowsSelected}
						setRowsSelected={setRowsSelected}
						user={user}
						onView={view}
					></TableView>
				}
				{
					display === "grid" &&

					<GridView
						rows={rows}
						onView={view}
					></GridView>
				}
				{
					display === "table" &&
					<Infobar rows={rows} rowsSelected={rowsSelected} canViewCommercials={canViewCommercials} />
				}
			</Grid>
		</Grid >
	)
}

export default DettaglioList;