import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserList, useUserRemove } from "../../../effects/user/user";
import { IUser } from "../../../interfaces/user/models";
import { useAppSelector } from "../../../store/hooks";
import DeleteDialog from "./components/dialogs/deleteDialog";
import Table from "./components/table/table";

function List() {
	const [query, setQuery] = useState<number>(0);
	const [userToDelete, setUserToDelete] = useState<IUser | null>(null);
	const [userIdToDelete, setUserIdToDelete] = useState<number | null>(null);
	useUserList(query);
	useUserRemove(userIdToDelete);
	const navigate = useNavigate();
	const list = useAppSelector((state) => state.user.list);
	const items = useAppSelector((state) => state.user.items);
	const rows: IUser[] = (!!list && !!items) ? list.map(item => items[item]) : [];
	const lastActionDone = useAppSelector((state) => state.ui.lastActionDone);
	useEffect(() => {
		if (lastActionDone === "remove-user") setQuery(query + 1);
	}, [lastActionDone, query]);
	useEffect(() => {
		setQuery(1);
	}, [])
	const edit = (id: number) => navigate(`${id}`);
	const remove = (id: number) => setUserToDelete(items?.[id] || null);
	const add = () => navigate(`new`);
	const confirmDialog = () => {
		setUserIdToDelete(userToDelete?.id || null);
		setUserToDelete(null);
	};
	const cancelDialog = () => setUserToDelete(null);
	return (
		<Box sx={{ display: "flex", flexDirection: "column", flex: 1, backgroundColor: "#fff" }}>
			<Table
				rows={rows}
				onEdit={edit}
				onRemove={remove}
				onAdd={add}
			></Table>
			<DeleteDialog
				item={userToDelete}
				onCancel={cancelDialog}
				onConfirm={confirmDialog}
			/>
		</Box>
	)
}

export default List;