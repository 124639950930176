import { IAuthenticateError } from "../../interfaces/errors";
import { IUser } from "../../interfaces/user/models";


export const GET = "auth/get";
export const GET_UNKNOWN = "auth/get_unknown"
export const ERROR = "auth/error";
export const EMPTY = "auth/empty";

export interface IAuthState {
	user: IUser | null;
	error: IAuthenticateError | null;
	isLogged: boolean | null;
	unknown: boolean | null;
};

export const initialState: IAuthState = {
	user: null,
	error: null,
	isLogged: null,
	unknown: null
};