import * as React from 'react';
import { errorHandler } from '../../error/handler';
import { getCava, getCavaList } from '../../fetch/place/cava';
import { ICava } from '../../interfaces/place/models';
import { normalizer } from '../../lib/normalizer';
import { useAppDispatch } from '../../store/hooks';
import { emptyAction, getAction, listAction } from '../../store/place/cava/actions';
import { ICavaState } from '../../store/place/cava/types';
import { endActionToDoAction, startActionToDoAction } from '../../store/ui/actions';

export const useCavaGet = (id: number) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!id) return;
			const actionToDo = 'get-cava';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const cava = await getCava(id);
				dispatch(getAction(cava));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id])
}

export const useCavaList = (query: number) => {
	const dispatch = useAppDispatch();
	React.useEffect(() => {
		const effect = async () => {
			if (!query) return;
			const actionToDo = 'list-cava';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const { results, items: _items } = await getCavaList();
				_items.sort((itemA, itemB) => (!!itemA?.ordinamento && !!itemB.ordinamento) ? itemA.ordinamento - itemB.ordinamento : 0);
				const { list, items } = normalizer<number, ICava>(_items, "id");
				const actionPayload: ICavaState = {
					results,
					list,
					items
				}
				dispatch(listAction(actionPayload));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				if ((err as any)?.error === 403) {
					const actionPayload: ICavaState = {
						results: 0,
						list: [],
						items: {}
					}
					dispatch(listAction(actionPayload));
					dispatch(endActionToDoAction({ actionToDo, success: true }));
				} else errorHandler(err, actionToDo);
			}
			return () => {
				dispatch(emptyAction());
			}
		}
		effect();
	}, [dispatch, query])
}