import { createAction } from '@reduxjs/toolkit';
import { IGroupPermissions } from '../../../interfaces/user/models';
import { EMPTY, GET, IGroupPermissionsState, LIST } from './types';

export const listAction = createAction<IGroupPermissionsState>(LIST);

export const getAction = createAction<IGroupPermissions>(GET);

export const emptyAction = createAction(EMPTY);

