import { createReducer } from "@reduxjs/toolkit";
import produce from "immer";
import { emptyAction, getAction, listAction, listExtAction } from "./actions";
import { initialState } from "./types";

export default createReducer(initialState, (builder) => {
  builder.addCase(listAction, (state, action) =>
    produce(state, (draft) => {
      const { results, list, items } = action.payload;
      draft.results = results;
      draft.list = list;
      draft.items = items;
    })
  );
  builder.addCase(listExtAction, (state, action) =>
    produce(state, (draft) => {
      const { resultsExt, listExt, itemsExt } = action.payload;
      draft.resultsExt = resultsExt;
      draft.listExt = listExt;
      draft.itemsExt = itemsExt;
    })
  );
  builder.addCase(getAction, (state, action) =>
    produce(state, (draft) => {
      const qualita = action.payload;
      if (!draft.itemsExt) draft.itemsExt = {};
      draft.itemsExt[qualita.id] = qualita;
    })
  );
  builder.addCase(emptyAction, () => initialState);
});
