import {
  IMateriale,
  IMaterialeExt,
} from "../../../../interfaces/lotto/attributes/models";

export const GET = "lotto_materiale/get";
export const LIST = "lotto_materiale/list";
export const LIST_EXT = "lotto_materiale/listExt";
export const EMPTY = "lotto_materiale/empty";

export interface IMaterialeState {
  results: number;
  resultsExt: number;
  list: number[];
  listExt: number[];
  items: Record<number, IMateriale> | null;
  itemsExt: Record<number, IMaterialeExt> | null;
}

export interface IMaterialeListPayload {
  results: number;
  list: number[];
  items: Record<number, IMateriale> | null;
}

export interface IMaterialeExtListPayload {
  resultsExt: number;
  listExt: number[];
  itemsExt: Record<number, IMaterialeExt> | null;
}

export const initialState: IMaterialeState = {
  results: 0,
  resultsExt: 0,
  list: [],
  listExt: [],
  items: null,
  itemsExt: null,
};
