import { Box, Card, CardActionArea, Icon, Tooltip } from "@mui/material";
import { MouseEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IImageUploadProps } from "./dialogs/upload/interfaces";
import UploadDialog from "./dialogs/upload/uploadDialog";

const i18_PATH = "qualita.update.";

function ImageUpload({ idQualita }: IImageUploadProps) {
  const { t } = useTranslation();
  const imageContainer = useRef<HTMLElement>();
  const [isUploadOpen, setIsUploadOpen] = useState<boolean>(false);
  const [imageWidth, setImageWidth] = useState<number>(0);
  const getSize = () => {
    const width = imageContainer?.current?.clientWidth;
    setImageWidth(width || 0);
  };
  const openUploadDialog = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsUploadOpen(true);
  };
  const closeUpload = () => {
    setIsUploadOpen(false);
  };
  useEffect(() => {
    window.addEventListener("resize", getSize);
    getSize();
    return () => window.removeEventListener("resize", getSize);
  }, []);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          border: "1px solid #e3e3e3",
          borderRadius: 4,
        }}
        ref={imageContainer}
      >
        <Card sx={{ flex: 1 }}>
          <CardActionArea onClick={openUploadDialog}>
            <Tooltip title={t(`${i18_PATH}NoImmagine`)}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: imageWidth * 0.5625,
                }}
              >
                <Icon
                  className="fa-light fa-image-slash"
                  fontSize="large"
                  color="action"
                  sx={{ overflow: "visible" }}
                />
              </Box>
            </Tooltip>
          </CardActionArea>
        </Card>
      </Box>
      <UploadDialog
        idQualita={idQualita}
        isOpen={isUploadOpen}
        close={closeUpload}
      />
    </>
  );
}

export default ImageUpload;
