import { Box, Icon, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { IDettaglio } from "../../../../../../../interfaces/dettaglio/models";
import { getMQPrice, getTotalMQ, getTotalNumberOfSlabs } from "../../../../../../../lib/functions";

interface IPackingListBottomBarProps {
	rows: IDettaglio[];
	canViewCommercials: boolean;
}

function PackingListBottomBar({ rows, canViewCommercials }: IPackingListBottomBarProps) {
	const [slabs, setSlabs] = useState<number>(0);
	const [MQ, setMQ] = useState<string>('0,00');
	const [MQPrice, setMQPrice] = useState<string>('0,00');


	useEffect(() => {
		setSlabs(getTotalNumberOfSlabs(rows));
		setMQ(getTotalMQ(rows,2,2));
		setMQPrice(getMQPrice(rows,2,2));
	}, [rows])

	return (
		<Box sx={{ position: "absolute", pl: 2, left: 16, bottom: 16, height: 53, display: "flex", alignItems: "center" }}>
			{slabs > 0 &&
				<Box sx={{ display: "flex", alignItems: "center", marginRight: 2 }}>

					<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
						<Tooltip title="Lastre">
							<Icon className="fa-light fa-layer-group" fontSize="small" color="action" sx={{ overflow: "visible", mr: 1 }} />
						</Tooltip>
						<Typography variant="body1" component="span" color="text.primaryc ">{slabs}</Typography>
					</Box>
					<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
						<Typography variant="overline" color="text.secondary" sx={{ mr: 1, lineHeight: "unset" }}>MQ:</Typography>
						<Typography variant="body1" component="span" color="text.primary">{MQ}</Typography>
					</Box>
					{canViewCommercials &&
						<>
							<Box sx={{ p: 1, display: "flex", alignItems: "baseline" }}>
								<Typography variant="overline" color="text.secondary" sx={{ mr: 1, lineHeight: "unset" }}>Prezzo/MQ:</Typography>
								<Typography variant="body1" component="span" color="text.primary">{MQPrice} €</Typography>
							</Box>
						</>
					}
				</Box>
			}
		</Box>
	)
}

export default PackingListBottomBar;