import { useEffect } from 'react';
import { errorHandler } from '../../error/handler';
import { getUser, getUserList, removeUser, storeUser, updateUser } from '../../fetch/user';
import { getUserGroupList } from '../../fetch/user/group';
import { UserStoreFormData, UserUpdateFormData } from '../../interfaces/user/forms';
import { IUser, IUserGroup } from '../../interfaces/user/models';
import { normalizer, normalizerTransform } from '../../lib/normalizer';
import { userTransform } from '../../lib/transform';
import { useAppDispatch } from '../../store/hooks';
import { endActionToDoAction, messageAction, startActionToDoAction } from '../../store/ui/actions';
import { emptyAction, getAction, listAction } from '../../store/user/user/actions';
import { IUserState } from '../../store/user/user/types';

export const useUserGet = (id: number | null) => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		const effect = async () => {
			if (!id) return;
			const actionToDo = 'get-user';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const user = await getUser(id);
				dispatch(getAction(user));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, id])
}

export const useUserList = (query: number) => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		const effect = async () => {
			if (!query) return;
			const actionToDo = 'list-user';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				const { results, items: _items } = await getUserList();
				const { items: _userGroup } = await getUserGroupList();
				const { items: userGroup } = normalizer<number, IUserGroup>(_userGroup, "id");
				const { list, items } = normalizerTransform<number, IUser, IUser>(_items, (item) => userTransform(item, userGroup));
				const actionPayload: IUserState = {
					results,
					list,
					items
				}
				dispatch(listAction(actionPayload));
				dispatch(endActionToDoAction({ actionToDo, success: true }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
			return () => {
				dispatch(emptyAction());
			}
		}
		effect();
	}, [dispatch, query])
}

export const useUserStore = (data: UserStoreFormData) => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		const effect = async () => {
			if (!data) return;
			const actionToDo = 'store-user';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				await storeUser(data);
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(messageAction({ message: "Utente aggiunto", kind: "success" }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, data]);
}

export const useUserUpdate = (userId: number | null, data: UserUpdateFormData) => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		const effect = async () => {
			if (!userId || !data) return;
			const actionToDo = 'update-user';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				await updateUser(userId, data);
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(messageAction({ message: "Utente aggiornato", kind: "success" }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, userId, data]);
}

export const useUserRemove = (userId: number | null) => {
	const dispatch = useAppDispatch();
	useEffect(() => {
		const effect = async () => {
			if (!userId) return;
			const actionToDo = 'remove-user';
			try {
				dispatch(startActionToDoAction({ actionToDo }));
				await removeUser(userId);
				dispatch(endActionToDoAction({ actionToDo, success: true }));
				dispatch(messageAction({ message: "Utente rimosso", kind: "success" }));
			} catch (err) {
				errorHandler(err, actionToDo);
			}
		}
		effect();
	}, [dispatch, userId]);
}
