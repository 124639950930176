import { Icon, Tooltip } from "@mui/material";
import { GridActionsCellItem, GridAlignment, GridColumns, GridRowParams, GridValueGetterParams } from "@mui/x-data-grid";
import { IUser } from "../../../../../interfaces/user/models";
import { ICreateColumnsPros } from "./interfaces";

export const createColumns = ({ onEdit, onRemove }: ICreateColumnsPros): GridColumns<IUser> => [
	{
		field: 'actions',
		type: 'actions',
		align: 'left' as GridAlignment,
		hideable: false,
		minWidth: 100,
		getActions: (params: GridRowParams) => [
			<GridActionsCellItem
				icon={<Tooltip title="Modifica"><Icon className="fa-light fa-pen" sx={{ overflow: "visible" }} /></Tooltip>}
				label="Edit"
				onClick={() => onEdit(params.id as number)}
			/>,
			<GridActionsCellItem
				icon={<Tooltip title="Elimina"><Icon className="fa-light fa-trash-can" sx={{ overflow: "visible" }} /></Tooltip>}
				label="Delete"
				onClick={() => onRemove(params.id as number)}
			/>

		],
	},
	{
		field: 'nome',
		headerName: 'Nome',
		width: 240
	},
	{
		field: 'email',
		headerName: 'Email',
		width: 320
	},
	{
		field: 'gruppo',
		headerName: 'gruppo',
		valueGetter: (params: GridValueGetterParams<IUser>) => params?.row?.user_group?.nome,
		width: 240
	},
]