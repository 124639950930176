import { IUso } from "../../../../interfaces/lotto/attributes/models";

export const GET = "lotto_uso/get";
export const LIST = "lotto_uso/list";
export const EMPTY = "lotto_uso/empty";

export interface IUsoState {
	results: number;
	list: number[];
	items: Record<number, IUso> | null;
};

export const initialState: IUsoState = {
	results: 0,
	list: [],
	items: null
};