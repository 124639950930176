import { ITranslations } from "../../intl/translations";

export const ITTranslations: ITranslations = {
  Lastre: "Lastre",
  Blocco: "Blocco",
  MQ: "MQ",
  Grezzo: "Grezzo",
  Levigato: "Levigato",
  Lucidato: "Lucidato",
  Resinato: "Resinato",
  Spazzolato: "Spazzolato",
  Opzionato: "Opzionato",
  Impegnato: "Impegnato",
  "Da Prezzare": "Da Prezzare",
  "Stand by": "Stand by",
  Libero: "Libero",
  "In Lavorazione": "In Lavorazione",
  main: {
    Prodotti: "Prodotti",
    Venduto: "Venduto",
    Materiali: "Materiali",
    Qualità: "Qualità",
    Prospects: "Prospects",
    Clienti: "Clienti",
  },
  navBar: {
    Prodotti: "Prodotti",
    Venduto: "Venduto",
    Materiali: "Materiali",
    Qualità: "Qualità",
    Prospects: "Prospects",
    Clienti: "Clienti",
    Utenti: "Utenti",
    "Gruppi Utenti": "Gruppi Utenti",
    Esci: "Esci",
  },
  materiale: {
    list: {
      columns: {
        Nome: "Nome",
        Descrizione: "Descrizione",
      },
    },
    update: {
      Nome: "Nome",
      Descrizione: "Descrizione",
      NoImmagine: "Nessuna immagine",
      Cancella: "Cancella",
      Salva: "Salva",
      Upload: "Upload",
      UploadInfo:
        "Trascina qua le tue immagini o clicca per selezionarle dal tuo archivio",
    },
  },
  qualita: {
    list: {
      columns: {
        Nome: "Nome",
        Materiale: "Materiale",
        Colore: "Colore",
        Utilizzo: "Utilizzo",
        Finiture: "Finiture",
        Densita: "Densita",
        ResistenzaCompressione: "ResistenzaCompressione",
        ResistenzaFlessione: "ResistenzaFlessione",
        CoefImbibizione: "CoefImbibizione",
        Descrizione: "Descrizione",
      },
    },
    update: {
      Nome: "Nome",
      Materiale: "Materiale",
      Colore: "Colore",
      Utilizzo: "Utilizzo",
      Finiture: "Finiture",
      Densita: "Densita",
      ResistenzaCompressione: "ResistenzaCompressione",
      ResistenzaFlessione: "ResistenzaFlessione",
      CoefImbibizione: "CoefImbibizione",
      Descrizione: "Descrizione",
      NoImmagine: "Nessuna immagine",
      Cancella: "Cancella",
      Salva: "Salva",
      Upload: "Upload",
      UploadInfo:
        "Trascina qua le tue immagini o clicca per selezionarle dal tuo archivio",
    },
  },
  prodotto: {
    view: {
      lottoMain: {
        Informazioni: "Informazioni",
        "Modifica informazioni": "Modifica informazioni",
        Codice: "Codice",
        Cava: "Cava",
        Materiale: "Materiale",
        Categoria: "Categoria",
        Venatura: "Venatura",
        Qualità: "Qualità",
        Fondo: "Fondo",
        Uso: "Uso",
        "Blocco da segare": "Blocco da segare",
        "Quantità iniziale": "Quantità iniziale",
        "Quantità disponibile": "Quantità disponibile",
        "Pezzi iniziali": "Pezzi iniziali",
        "Pezzi disponibili": "Pezzi disponibili",
        "Nessuna Immagine": "Nessuna Immagine",
      },
      lottoImages: {
        "Immagini lastre": "Immagini lastre",
        "Immagini blocco": "Immagini blocco",
        "Modifica immagini": "Modifica immagini",
        "Carica nuove immagini": "Carica nuove immagini",
        "Esci dalla modifica immagini": "Esci dalla modifica immagini",
        thumbnail: {
          "Imposta come immagine di copertina":
            "Imposta come immagine di copertina",
          "Immagine di copertina": "Immagine di copertina",
          Modifica: "Modifica",
          Elimina: "Elimina",
        },
        dialog: {
          coverDialog: {
            "Imposta questa immagine come copertina":
              "Imposta questa immagine come copertina",
            "Questa immagine sarà impostata come immagine principale del lotto, continuare?":
              "Questa immagine sarà impostata come immagine principale del lotto, continuare?",
            Cancella: "Cancella",
            Salva: "Salva",
          },
          deleteDialog: {
            "Elimina immagine": "Elimina immagine",
            "Sei sicuro di elimiare questa immagine?":
              "Sei sicuro di elimiare questa immagine?",
            Cancella: "Cancella",
            Elimina: "Elimina",
          },
          updateDialog: {
            "Campo obbligatorio": "Campo obbligatorio",
            "Modifica immagine": "Modifica immagine",
            Cancella: "Cancella",
            Salva: "Salva",
          },
          uploadDialog: {
            "Trascina qua le tue immagini o clicca per selezionarle dal tuo archivio":
              "Trascina qua le tue immagini o clicca per selezionarle dal tuo archivio",
            Cancella: "Cancella",
            Upload: "Upload",
          },
        },
      },
      lottoDettagli: {
        Dettaglio: "Dettaglio",
        showHideColumnsToolbar: {
          "Mostra tutte le colonne": "Mostra tutte le colonne",
          "Nascondi tutte le colonne": "Nascondi tutte le colonne",
          "Nascondi alcune colonne": "Nascondi alcune colonne",
        },
        dettagliSelectViewToolbar: {
          "In magazzino": "In magazzino",
          "Storia del lotto": "Storia del lotto",
        },
        tableCells: {
          "Non venduto": "Non venduto",
          Venduto: "Venduto",
        },
        grids: {
          defaultGridColumns: {
            Tipo: "Tipo",
            Magazzino: "Magazzino",
            Zona: "Zona",
            Fila: "Fila",
            Bundle: "Bundle",
            Da: "Da",
            A: "A",
            Pezzi: "Pezzi",
            Lun: "Lun",
            Alt: "Alt",
            Spes: "Spes",
            Quantità: "Quantità",
            Costo: "Costo",
            Prezzo: "Prezzo",
            Lavorazione: "Lavorazione",
            Disponibilita: "Disponibilita",
            Cliente: "Cliente",
            Agente: "Agente",
            Note: "Note",
          },
          historyGridColumns: {
            Tipo: "Tipo",
            Magazzino: "Magazzino",
            Zona: "Zona",
            Fila: "Fila",
            Bundle: "Bundle",
            Da: "Da",
            A: "A",
            Pezzi: "Pezzi",
            Lun: "Lun",
            Alt: "Alt",
            Spes: "Spes",
            Quantità: "Quantità",
            Costo: "Costo",
            Prezzo: "Prezzo",
            Lavorazione: "Lavorazione",
            Venduto: "Venduto",
          },
          gridFooter: {
            Pezzi: "Pezzi",
            "Prezzo al MQ": "Prezzo al MQ",
            Totale: "Totale",
            "Costo totale": "Costo totale",
            Condividi: "Condividi",
            Opziona: "Opziona",
            "Packing List": "Packing List",
            Dividi: "Dividi",
            Modifica: "Modifica",
          },
          packingListGridColumns: {
            Lotto: "Lotto",
            Bundle: "Bundle",
            Da: "Da",
            A: "A",
            Pezzi: "Pezzi",
            Lun: "Lun",
            Alt: "Alt",
            Spes: "Spes",
            Quantità: "Quantità",
            Costo: "Costo",
            Prezzo: "Prezzo",
            Lavorazione: "Lavorazione",
          },
        },
      },
    },
  },
};
