import { settings } from "../../customize/settings";
import { IBaseError } from "../../interfaces/errors";
import {
  ILotto,
  ILottoShare,
  ILottoUpdate,
} from "../../interfaces/lotto/models";
import {
  IListResponse,
  ITokenResponse,
  IUpdateResponse,
} from "../../interfaces/rest";
import { getJWT } from "../../lib/jwt";

const BASE_URL = `${settings.apiUrl}lotto`;

export const getLotto = async (id: number): Promise<ILotto> => {
  const url = `${BASE_URL}/${id}`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const json: ILotto | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as ILotto;
};

export const getLottoList = async (): Promise<IListResponse<ILotto>> => {
  const url = `${BASE_URL}/list`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const response = await fetch(url, {
    method: "GET",
    headers: headers,
  });
  const json: IListResponse<ILotto> | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IListResponse<ILotto>;
};

export const updateLotto = async (
  id: number,
  data: ILottoUpdate
): Promise<IUpdateResponse> => {
  const url = `${BASE_URL}/${id}`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const body = JSON.stringify(data);
  const response = await fetch(url, {
    method: "PUT",
    headers: headers,
    body,
  });
  const json: IUpdateResponse | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as IUpdateResponse;
};

export const shareLotto = async ({
  idLotto,
  idDettaglioLottoList,
  showZeroImages,
  showPrice,
  prices,
  expiresIn,
}: ILottoShare): Promise<ITokenResponse> => {
  const url = `${BASE_URL}/share/${idLotto}`;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  const jwt = getJWT();
  if (jwt) headers.append("Authorization", "Bearer " + jwt);
  const body = JSON.stringify({
    idDettaglioLottoList,
    showZeroImages,
    showPrice,
    prices,
    expiresIn,
  });
  const response = await fetch(url, {
    method: "POST",
    headers: headers,
    body,
  });
  const json: ITokenResponse | IBaseError = await response.json();
  if (response.status !== 200) throw json;
  return json as ITokenResponse;
};
