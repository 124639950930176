import { createAction } from '@reduxjs/toolkit';
import { IVenatura } from '../../../../interfaces/lotto/attributes/models';
import { EMPTY, GET, IVenaturaState, LIST } from './types';

export const listAction = createAction<IVenaturaState>(LIST);

export const getAction = createAction<IVenatura>(GET);

export const emptyAction = createAction(EMPTY);

