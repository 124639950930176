import { settings } from "../../customize/settings";
import { IBaseError } from "../../interfaces/errors";
import { IFila } from "../../interfaces/place/models";
import { IListResponse } from "../../interfaces/rest";
import { getJWT } from "../../lib/jwt";

const BASE_URL = `${settings.apiUrl}fila`

export const getFila = async (id: number): Promise<IFila> => {
	const url = `${BASE_URL}/${id}`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IFila | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IFila;
}

export const getFilaList = async (): Promise<IListResponse<IFila>> => {
	const url = `${BASE_URL}/list`;
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');
	const jwt = getJWT();
	if (jwt) headers.append('Authorization', 'Bearer ' + jwt);
	const response = await fetch(url, {
		method: "GET",
		headers: headers
	});
	const json: IListResponse<IFila> | IBaseError = await response.json();
	if (response.status !== 200) throw json;
	return json as IListResponse<IFila>
}