import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Icon,
  Paper,
} from "@mui/material";
import i18next from "i18next";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { settings } from "../../../customize/settings";
import { useLottoGet } from "../../../effects/lotto/lotto";
import { IDettaglio } from "../../../interfaces/dettaglio/models";
import { ILotto, ILottoImage } from "../../../interfaces/lotto/models";
import { PostMessageViewWindowReady } from "../../../interfaces/postMessage/types";
import { imagesFilter } from "../../../lib/functions";
import { useAppSelector } from "../../../store/hooks";
import LottoDettagli from "./components/lottoDettagli/lottoDettagli";
import CarouselDialog from "./components/lottoImages/dialogs/carousel/carouselDialog";
import ZoomDialog from "./components/lottoImages/dialogs/zoom/zoomDialog";
import LottoImages from "./components/lottoImages/lottoImages";
import LottoMain from "./components/lottoMain/lottoMain";

const sendReadyMessage = (parentWindow: Window | null) => {
  if (parentWindow === null || parentWindow?.closed) return;

  const message: PostMessageViewWindowReady = {
    type: "GEMEG_VIEW_READY",
  };
  parentWindow.postMessage(message);
};

function LottoView() {
  const { lottoId } = useParams();
  const id = !!lottoId ? parseInt(lottoId) : null;

  const [query, setQuery] = useState<number>(1);
  const [parentWindow, setParentWindow] = useState<Window | null>(null);
  const [lottoIds, setLottoIds] = useState<number[]>([]);
  const [lotto, setLotto] = useState<ILotto | null>();
  const [selectedItems, setSelectedItems] = useState<IDettaglio[]>([]);
  const [isNotAnonymousUser, setIsNotAnonymousUser] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedImage, setSelectedImage] = useState<ILottoImage | null>(null);
  const [isCarouselOpen, setIsCarouselOpen] = useState<boolean>(false);
  const [isZoomOpen, setIsZoomOpen] = useState<boolean>(false);
  const [blockImages, setBlockImages] = useState<ILottoImage[]>([]);
  const [slabsImages, setSlabsImages] = useState<ILottoImage[]>([]);
  const [imagesToView, setImagesToView] = useState<ILottoImage[]>([]);
  const [isSlabsImagesEditMode, setIsSlabsImagesEditMode] =
    useState<boolean>(false);
  const [isBlockImagesEditMode, setIsBlockImagesEditMode] =
    useState<boolean>(false);

  const imagesContainer = useRef<HTMLElement>();
  const imagesBlockContainer = useRef<HTMLElement>();

  const lastActionDone = useAppSelector((state) => state.ui.lastActionDone);
  const lottoItems = useAppSelector((state) => state.lotto.items);
  const user = useAppSelector((state) => state.auth.user);

  const openCarousel = (image: ILottoImage, isBlock?: boolean) => {
    //const rows = dettagliFilterNotSold(lotto?.dettagli ?? []);
    //const isAllSelected = rows?.length === selectedItems.length;

    const _images = isBlock ? blockImages : slabsImages;

    setImagesToView(_images);

    const index = _images.findIndex((item) => item.id === image.id);
    setSelectedIndex(index);
    setIsCarouselOpen(true);
  };
  const closeCarousel = () => {
    setSelectedIndex(0);
    setIsCarouselOpen(false);
  };
  const openZoom = (image: ILottoImage) => {
    setSelectedImage(image);
    setIsZoomOpen(true);
  };
  const closeZoom = () => {
    setSelectedImage(null);
    setIsZoomOpen(false);
  };

  const messageListener = (event: MessageEvent) => {
    const { data } = event;
    if (data.type === "GEMEG_DETTAGLI_LIST") {
      setLottoIds(data.lottoIds || []);
    }
  };
  const scrollToImages = () =>
    imagesContainer?.current?.scrollIntoView({ behavior: "smooth" });
  const next = () => {
    if (!id) return;
    const index = lottoIds.indexOf(id);
    if (index < lottoIds.length - 1) {
      const nextLottoId = lottoIds[index + 1];
      window.location.href = `/#/prodotti/${nextLottoId}`;
    }
  };
  const prev = () => {
    if (!id) return;
    const index = lottoIds.indexOf(id);
    if (index > 0) {
      const nextLottoId = lottoIds[index - 1];
      window.location.href = `/#/prodotti/${nextLottoId}`;
    }
  };
  useLottoGet(id, query);
  useEffect(() => {
    if (
      !!lastActionDone &&
      [
        "split-dettaglio",
        "update-commercial-options-dettaglio",
        "update-lotto",
        "update-image",
        "set-cover-image",
        "remove-immagine",
        "upload-immagine",
        "upload-immagini",
      ].includes(lastActionDone)
    ) {
      setQuery(query + 1);
    }
  }, [lastActionDone, query]);
  useEffect(() => {
    if (parentWindow !== null) sendReadyMessage(parentWindow);
  }, [parentWindow]);
  useEffect(() => {
    const _lotto = !!id && !!lottoItems ? lottoItems[id] : null;
    setLotto(_lotto);
  }, [lottoItems, id]);
  useEffect(() => {
    if (!!user?.unknown) i18next.changeLanguage("en");
    const _isNotAnonymousUser = !!user && !user.unknown;
    setIsNotAnonymousUser(_isNotAnonymousUser);
  }, [user]);
  useEffect(() => {
    const _images = lotto?.images ? [...lotto.images] : [];
    const dettagli = lotto?.dettagli ?? [];
    const _blockImages = _images.filter((image) => image.isBlock);
    const _slabsImages = _images.filter((image) => !image.isBlock);
    _slabsImages.sort(
      (a, b) => a.order - b.order || a.slab - b.slab || a.bundle - b.bundle
    );
    setBlockImages(_blockImages);
    setSlabsImages(
      !isSlabsImagesEditMode &&
        !!settings.item?.images?.slabs?.hideIfNotExistRef
        ? imagesFilter(dettagli, _slabsImages)
        : _slabsImages
    );
  }, [lotto, isSlabsImagesEditMode]);
  useEffect(() => {
    const parent = window.opener;
    setParentWindow(parent);
    window.addEventListener("message", messageListener);
  }, []);

  return (
    <>
      <Box sx={{ pb: !!lottoIds.length ? 8 : 2, backgroundColor: "#fff" }}>
        {!!lotto && (
          <LottoMain lotto={lotto} user={user} view={scrollToImages} />
        )}
        {!!lotto?.dettagli && (
          <LottoDettagli
            lotto={lotto}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            viewImage={openCarousel}
            user={user}
          />
        )}
        {!!lotto && !!isNotAnonymousUser && (
          <Box ref={imagesBlockContainer}>
            <LottoImages
              lotto={lotto}
              isBlock={true}
              images={blockImages}
              viewImage={openCarousel}
              setIsEditMode={setIsBlockImagesEditMode}
              isEditMode={isBlockImagesEditMode}
              user={user}
            />
          </Box>
        )}
        {!!lotto && (
          <Box ref={imagesContainer}>
            <LottoImages
              lotto={lotto}
              images={slabsImages}
              viewImage={openCarousel}
              setIsEditMode={setIsSlabsImagesEditMode}
              isEditMode={isSlabsImagesEditMode}
              user={user}
            />
          </Box>
        )}
        {!!lottoIds.length && (
          <Paper
            sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
            elevation={3}
          >
            <BottomNavigation>
              <BottomNavigationAction
                onClick={prev}
                icon={
                  <Icon
                    className="fa-light fa-left"
                    sx={{ overflow: "visible" }}
                  />
                }
              />
              <BottomNavigationAction
                onClick={next}
                icon={
                  <Icon
                    className="fa-light fa-right"
                    sx={{ overflow: "visible" }}
                  />
                }
              />
            </BottomNavigation>
          </Paper>
        )}
      </Box>
      {!!lotto && (
        <>
          <CarouselDialog
            isOpen={isCarouselOpen}
            startIndex={selectedIndex}
            images={imagesToView}
            lotto={lotto}
            close={closeCarousel}
            zoom={openZoom}
          />
          <ZoomDialog
            isOpen={isZoomOpen}
            image={selectedImage}
            close={closeZoom}
          />
        </>
      )}
    </>
  );
}

export default LottoView;
