import { IDisponibilita } from "../../../../interfaces/dettaglio/attributes/models";

export const GET = "disponibilita/get";
export const LIST = "disponibilita/list";
export const EMPTY = "disponibilita/empty";

export interface IDisponibilitaState {
	results: number;
	list: number[];
	items: Record<number, IDisponibilita> | null;
};

export const initialState: IDisponibilitaState = {
	results: 0,
	list: [],
	items: null
};