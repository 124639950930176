import { Box } from "@mui/material";
import React from "react";
import { useClienteList } from "../../../effects/cliente";
import { ICliente } from "../../../interfaces/cliente/models";
import { useAppSelector } from "../../../store/hooks";
import Table from "./components/table/table";

function List() {
	useClienteList(1);
	const list = useAppSelector((state) => state.cliente.list);
	const items = useAppSelector((state) => state.cliente.items);
	const rows: ICliente[] = (!!list && !!items) ? list.map(item => items[item]) : [];
	return (
		<Box sx={{ display: "flex", flexDirection: "column", flex: 1, backgroundColor: "#fff" }}>
			<Table
				rows={rows}
			></Table>
		</Box>
	)
}

export default List;