import {
	Box,
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField,
	Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useProspectStore, useProspectUpdate } from "../../../effects/prospect/prospect";
import { ICategoria, IFondo, IMateriale, IQualita, IUso, IVenatura } from "../../../interfaces/lotto/attributes/models";
import { ProspectStoreFormData, ProspectUpdateFormData } from "../../../interfaces/prospect/forms";
import { IProspect, IProspectStore, IProspectUpdate } from "../../../interfaces/prospect/models";
import { useAppSelector } from "../../../store/hooks";

function Store() {
	const navigate = useNavigate();

	const { prospectId } = useParams();

	const [updateFormData, setUpdateFormData] = useState<ProspectUpdateFormData>(null);
	const [storeFormData, setStoreFormData] = useState<ProspectStoreFormData>(null);

	const prospects = useAppSelector((state) => state.prospect.items);

	const materialeList = useAppSelector((state) => state.lotto_materiale.list);
	const materialeMap = useAppSelector((state) => state.lotto_materiale.items);

	const qualitaList = useAppSelector((state) => state.lotto_qualita.list);
	const qualitaMap = useAppSelector((state) => state.lotto_qualita.items);

	const categoriaList = useAppSelector((state) => state.lotto_categoria.list);
	const categoriaMap = useAppSelector((state) => state.lotto_categoria.items);

	const fondoList = useAppSelector((state) => state.lotto_fondo.list);
	const fondoMap = useAppSelector((state) => state.lotto_fondo.items);

	const venaturaList = useAppSelector((state) => state.lotto_venatura.list);
	const venaturaMap = useAppSelector((state) => state.lotto_venatura.items);

	const usoList = useAppSelector((state) => state.lotto_uso.list);
	const usoMap = useAppSelector((state) => state.lotto_uso.items);

	const lastActionDone = useAppSelector((state) => state.ui.lastActionDone);

	const [actualProspect, setActualProspect] = useState<IProspect | null>(null);
	const [cliente, setCliente] = useState<string>("");
	const [clienteError, setClienteError] = useState<string | null>(null);
	const [societa, setSocieta] = useState<string>("");
	const [societa1, setSocieta1] = useState<string>("");
	const [stato, setStato] = useState<string>("");
	const [paese, setPaese] = useState<string>("");
	const [citta, setCitta] = useState<string>("");
	const [telefono, setTelefono] = useState<string>("");
	const [website, setWebsite] = useState<string>("");
	const [contatto1, setContatto1] = useState<string>("");
	const [telefono1, setTelefono1] = useState<string>("");
	const [email1, setEmail1] = useState<string>("");
	const [contatto2, setContatto2] = useState<string>("");
	const [telefono2, setTelefono2] = useState<string>("");
	const [email2, setEmail2] = useState<string>("");
	const [contatto3, setContatto3] = useState<string>("");
	const [telefono3, setTelefono3] = useState<string>("");
	const [email3, setEmail3] = useState<string>("");
	const [agente, setAgente] = useState<string>("");
	const [responsabilegemeg, setResponsabilegemeg] = useState<string>("");
	const [idMateriale, setIdMateriale] = useState<string>("");
	const [idQualita, setIdQualita] = useState<string>("");
	const [idCategoria, setIdCategoria] = useState<string>("");
	const [idFondo, setIdFondo] = useState<string>("");
	const [idVenatura, setIdVenatura] = useState<string>("");
	const [idUso, setIdUso] = useState<string>("");
	const [notaprofilazione, setNotaprofilazione] = useState<string>("");

	const [materialeOptions, setMaterialeOptions] = useState<IMateriale[]>([]);
	const [qualitaOptions, setQualitaOptions] = useState<IQualita[]>([]);
	const [categoriaOptions, setCategoriaOptions] = useState<ICategoria[]>([]);
	const [fondoOptions, setFondoOptions] = useState<IFondo[]>([]);
	const [venaturaOptions, setVenaturaOptions] = useState<IVenatura[]>([]);
	const [usoOptions, setUsoOptions] = useState<IUso[]>([]);

	const onClienteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCliente(event.target.value);
	};
	const onSocietaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSocieta(event.target.value);
	};
	const onSocieta1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSocieta1(event.target.value);
	};
	const onStatoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setStato(event.target.value);
	};
	const onPaeseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPaese(event.target.value);
	};
	const onCittaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCitta(event.target.value);
	};
	const onTelefonoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTelefono(event.target.value);
	};
	const onWebsiteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setWebsite(event.target.value);
	};
	const onContatto1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
		setContatto1(event.target.value);
	};
	const onTelefono1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTelefono1(event.target.value);
	};
	const onEmail1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail1(event.target.value);
	};
	const onContatto2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
		setContatto2(event.target.value);
	};
	const onTelefono2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTelefono2(event.target.value);
	};
	const onEmail2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail2(event.target.value);
	};
	const onContatto3Change = (event: React.ChangeEvent<HTMLInputElement>) => {
		setContatto3(event.target.value);
	};
	const onTelefono3Change = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTelefono3(event.target.value);
	};
	const onEmail3Change = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail3(event.target.value);
	};
	const onAgenteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAgente(event.target.value);
	};
	const onResponsabilegemegChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setResponsabilegemeg(event.target.value);
	};
	const onMaterialeChange = (event: SelectChangeEvent<string>) => {
		setIdMateriale(event.target.value);
	};
	const onQualitaChange = (event: SelectChangeEvent<string>) => {
		setIdQualita(event.target.value);
	};
	const onCategoriaChange = (event: SelectChangeEvent<string>) => {
		setIdCategoria(event.target.value);
	};
	const onFondoChange = (event: SelectChangeEvent<string>) => {
		setIdFondo(event.target.value);
	};
	const onVenaturaChange = (event: SelectChangeEvent<string>) => {
		setIdVenatura(event.target.value);
	};
	const onUsoChange = (event: SelectChangeEvent<string>) => {
		setIdUso(event.target.value);
	};
	const onNotaprofilazioneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNotaprofilazione(event.target.value);
	};

	const checkError = (): boolean => {
		let errors = 0;
		if (!cliente) {
			setClienteError('Campo obbligatorio');
			errors++;
		} else {
			setClienteError(null);
		}
		return !!errors;
	}
	const update = () => {
		const data: IProspectUpdate = {
			cliente,
			societa,
			societa1,
			stato,
			paese,
			citta,
			telefono,
			website,
			contatto1,
			telefono1,
			email1,
			contatto2,
			telefono2,
			email2,
			contatto3,
			telefono3,
			email3,
			agente,
			responsabilegemeg,
			idMateriale: parseInt(idMateriale) || undefined,
			idQualita: parseInt(idQualita) || undefined,
			idCategoria: parseInt(idCategoria) || undefined,
			idFondo: parseInt(idFondo) || undefined,
			idVenatura: parseInt(idVenatura) || undefined,
			idUso: parseInt(idUso) || undefined,
			notaprofilazione
		};
		setUpdateFormData(data);
	}

	const store = () => {
		const data: IProspectStore = {
			cliente,
			societa,
			societa1,
			stato,
			paese,
			citta,
			telefono,
			website,
			contatto1,
			telefono1,
			email1,
			contatto2,
			telefono2,
			email2,
			contatto3,
			telefono3,
			email3,
			agente,
			responsabilegemeg,
			idMateriale: parseInt(idMateriale) || undefined,
			idQualita: parseInt(idQualita) || undefined,
			idCategoria: parseInt(idCategoria) || undefined,
			idFondo: parseInt(idFondo) || undefined,
			idVenatura: parseInt(idVenatura) || undefined,
			idUso: parseInt(idUso) || undefined,
			notaprofilazione
		};
		setStoreFormData(data);
	}

	const submit = () => {
		if (!checkError()) {
			if (!!prospectId) update();
			else store()
		}
	}

	const back = () => {
		navigate('..');
	}
	useEffect(() => {
		if (!!lastActionDone && ['store-prospect', 'update-prospect'].includes(lastActionDone)) navigate('..');
	}, [lastActionDone, navigate]);
	useEffect(() => {
		const options: IMateriale[] = (!!materialeList && !!materialeMap) ? materialeList.map(item => materialeMap[item]) : []
		setMaterialeOptions(options);
	}, [materialeList, materialeMap]);
	useEffect(() => {
		const options: IQualita[] = (!!qualitaList && !!qualitaMap) ? qualitaList.map(item => qualitaMap[item]) : []
		setQualitaOptions(options);
	}, [qualitaList, qualitaMap]);
	useEffect(() => {
		const options: ICategoria[] = (!!categoriaList && !!categoriaMap) ? categoriaList.map(item => categoriaMap[item]) : []
		setCategoriaOptions(options);
	}, [categoriaList, categoriaMap]);
	useEffect(() => {
		const options: IFondo[] = (!!fondoList && !!fondoMap) ? fondoList.map(item => fondoMap[item]) : []
		setFondoOptions(options);
	}, [fondoList, fondoMap]);
	useEffect(() => {
		const options: IVenatura[] = (!!venaturaList && !!venaturaMap) ? venaturaList.map(item => venaturaMap[item]) : []
		setVenaturaOptions(options);
	}, [venaturaList, venaturaMap]);
	useEffect(() => {
		const options: IUso[] = (!!usoList && !!usoMap) ? usoList.map(item => usoMap[item]) : []
		setUsoOptions(options);
	}, [usoList, usoMap]);
	useEffect(() => {
		if (!!prospectId && !!prospects) setActualProspect(prospects[parseInt(prospectId)] || null)
	}, [prospectId, prospects])
	useEffect(() => {
		if (!!actualProspect) {
			if (!!actualProspect.cliente) setCliente(actualProspect.cliente);
			if (!!actualProspect.societa) setSocieta(actualProspect.societa);
			if (!!actualProspect.societa1) setSocieta1(actualProspect.societa1);
			if (!!actualProspect.stato) setStato(actualProspect.stato);
			if (!!actualProspect.paese) setPaese(actualProspect.paese);
			if (!!actualProspect.citta) setCitta(actualProspect.citta);
			if (!!actualProspect.telefono) setTelefono(actualProspect.telefono);
			if (!!actualProspect.website) setWebsite(actualProspect.website);
			if (!!actualProspect.contatto1) setContatto1(actualProspect.contatto1);
			if (!!actualProspect.telefono1) setTelefono1(actualProspect.telefono1);
			if (!!actualProspect.email1) setEmail1(actualProspect.email1);
			if (!!actualProspect.contatto2) setContatto2(actualProspect.contatto2);
			if (!!actualProspect.telefono2) setTelefono2(actualProspect.telefono2);
			if (!!actualProspect.email2) setEmail2(actualProspect.email2);
			if (!!actualProspect.contatto3) setContatto3(actualProspect.contatto3);
			if (!!actualProspect.telefono3) setTelefono3(actualProspect.telefono3);
			if (!!actualProspect.email3) setEmail1(actualProspect.email3);
			if (!!actualProspect.agente) setAgente(actualProspect.agente);
			if (!!actualProspect.responsabilegemeg) setResponsabilegemeg(actualProspect.responsabilegemeg);
			if (!!actualProspect.idMateriale) setIdMateriale(String(actualProspect.idMateriale));
			if (!!actualProspect.idQualita) setIdQualita(String(actualProspect.idQualita));
			if (!!actualProspect.idCategoria) setIdCategoria(String(actualProspect.idCategoria));
			if (!!actualProspect.idFondo) setIdFondo(String(actualProspect.idFondo));
			if (!!actualProspect.idVenatura) setIdVenatura(String(actualProspect.idVenatura));
			if (!!actualProspect.idUso) setIdUso(String(actualProspect.idUso));
			if (!!actualProspect.notaprofilazione) setNotaprofilazione(actualProspect.notaprofilazione);
		}
	}, [actualProspect])
	useProspectUpdate(actualProspect?.id || null, updateFormData);
	useProspectStore(storeFormData);
	return (
		<Box sx={{ display: "flex", flexDirection: "column", flex: 1, backgroundColor: "#fff" }}>
			<Box sx={{ flex: 1, position: "relative" }}>
				<Box sx={{ backgroundColor: "#fff", boxSizing: "border-box", display: 'flex', position: "absolute", height: "100%", width: "100%", overflowY: "auto" }}>
					<Grid container sx={{ flex: 1, p: 0 }}>
						<Grid item xs={6} sx={{ display: "flex", flexDirection: "column", boxSizing: "border-box", position: "relative", px: 1, pb: 1 }}>
							<Box sx={{ px: 1 }} ><Typography variant="overline">Anagrafica</Typography></Box>
							<Box sx={{ flex: 1, position: "relative" }}>
								<Box sx={{ boxSizing: "border-box", position: "absolute", height: "100%", width: "100%", overflowY: "auto", border: '1px solid #cdcdcd', borderRadius: 1 }}>
									<Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
										<TextField
											required={true}
											margin="dense"
											label="Cliente"
											type="text"
											error={!!clienteError}
											helperText={clienteError}
											value={cliente}
											onChange={onClienteChange}
										/>
										<TextField
											margin="dense"
											label="Società"
											type="text"
											value={societa}
											onChange={onSocietaChange}
										/>
										<TextField
											margin="dense"
											label="Società 1"
											type="text"
											value={societa1}
											onChange={onSocieta1Change}
										/>
										<TextField
											margin="dense"
											label="Stato"
											type="text"
											value={stato}
											onChange={onStatoChange}
										/>
										<TextField
											margin="dense"
											label="Paese"
											type="text"
											value={paese}
											onChange={onPaeseChange}
										/>
										<TextField
											margin="dense"
											label="Città"
											type="text"
											value={citta}
											onChange={onCittaChange}
										/>
										<TextField
											margin="dense"
											label="Telefono"
											type="text"
											value={telefono}
											onChange={onTelefonoChange}
										/>
										<TextField
											margin="dense"
											label="Website"
											type="text"
											value={website}
											onChange={onWebsiteChange}
										/>
										<TextField
											margin="dense"
											label="Contatto 1"
											type="text"
											value={contatto1}
											onChange={onContatto1Change}
										/>
										<TextField
											margin="dense"
											label="Telefono 1"
											type="text"
											value={telefono1}
											onChange={onTelefono1Change}
										/>
										<TextField
											margin="dense"
											label="Email 1"
											type="text"
											value={email1}
											onChange={onEmail1Change}
										/>
										<TextField
											margin="dense"
											label="Contatto 2"
											type="text"
											value={contatto2}
											onChange={onContatto2Change}
										/>
										<TextField
											margin="dense"
											label="Telefono 2"
											type="text"
											value={telefono2}
											onChange={onTelefono2Change}
										/>
										<TextField
											margin="dense"
											label="Email 2"
											type="text"
											value={email2}
											onChange={onEmail2Change}
										/>
										<TextField
											margin="dense"
											label="Contatto 3"
											type="text"
											value={contatto3}
											onChange={onContatto3Change}
										/>
										<TextField
											margin="dense"
											label="Telefono 3"
											type="text"
											value={telefono3}
											onChange={onTelefono3Change}
										/>
										<TextField
											margin="dense"
											label="Email 3"
											type="text"
											value={email3}
											onChange={onEmail3Change}
										/>
										<TextField
											margin="dense"
											label="Agente"
											type="text"
											value={agente}
											onChange={onAgenteChange}
										/>
										<TextField
											margin="dense"
											label="Responsabile Gemeg"
											type="text"
											value={responsabilegemeg}
											onChange={onResponsabilegemegChange}
										/>
									</Box>
								</Box>
							</Box>
						</Grid>
						<Grid item xs={6} sx={{ display: "flex", flexDirection: "column", boxSizing: "border-box", position: "relative", px: 1, pb: 1 }}>
							<Box sx={{ px: 1 }} ><Typography variant="overline">profilo commerciale</Typography></Box>
							<Box sx={{ flex: 1, position: "relative" }}>
								<Box sx={{ boxSizing: "border-box", position: "absolute", height: "100%", width: "100%", overflowY: "auto", border: '1px solid #cdcdcd', borderRadius: 1 }}>
									<Box sx={{ display: 'flex', flexDirection: 'column', p: 1 }}>
										<FormControl fullWidth sx={{ mt: 1, mb: 0.5 }}>
											<InputLabel id="materiale_label">Materiale</InputLabel>
											<Select
												labelId="materiale_label"
												id="materiale_select"
												value={idMateriale}
												label="Materiale"
												onChange={onMaterialeChange}
											>
												{materialeOptions.map((item) => (<MenuItem key={String(item.id)} value={item.id}>{item.nome}</MenuItem>))}
											</Select>
										</FormControl>
										<FormControl fullWidth sx={{ mt: 1, mb: 0.5 }}>
											<InputLabel id="qualita_label">Qualità</InputLabel>
											<Select
												labelId="qualita_label"
												id="qualita_select"
												value={idQualita}
												label="Qualità"
												onChange={onQualitaChange}
											>
												{qualitaOptions.map((item) => (<MenuItem key={String(item.id)} value={item.id}>{item.nome}</MenuItem>))}
											</Select>
										</FormControl>
										<FormControl fullWidth sx={{ mt: 1, mb: 0.5 }}>
											<InputLabel id="categoria_label">Categoria</InputLabel>
											<Select
												labelId="categoria_label"
												id="categoria_select"
												value={idCategoria}
												label="Categoria"
												onChange={onCategoriaChange}
											>
												{categoriaOptions.map((item) => (<MenuItem key={String(item.id)} value={item.id}>{item.nome}</MenuItem>))}
											</Select>
										</FormControl>
										<FormControl fullWidth sx={{ mt: 1, mb: 0.5 }}>
											<InputLabel id="fondo_label">Fondo</InputLabel>
											<Select
												labelId="fondo_label"
												id="fondo_select"
												value={idFondo}
												label="Fondo"
												onChange={onFondoChange}
											>
												{fondoOptions.map((item) => (<MenuItem key={String(item.id)} value={item.id}>{item.nome}</MenuItem>))}
											</Select>
										</FormControl>
										<FormControl fullWidth sx={{ mt: 1, mb: 0.5 }}>
											<InputLabel id="venatura_label">Venatura</InputLabel>
											<Select
												labelId="venatura_label"
												id="venatura_select"
												value={idVenatura}
												label="Venatura"
												onChange={onVenaturaChange}
											>
												{venaturaOptions.map((item) => (<MenuItem key={String(item.id)} value={item.id}>{item.nome}</MenuItem>))}
											</Select>
										</FormControl>
										<FormControl fullWidth sx={{ mt: 1, mb: 0.5 }}>
											<InputLabel id="uso_label">Uso</InputLabel>
											<Select
												labelId="uso_label"
												id="uso_select"
												value={idUso}
												label="Uso"
												onChange={onUsoChange}
											>
												{usoOptions.map((item) => (<MenuItem key={String(item.id)} value={item.id}>{item.nome}</MenuItem>))}
											</Select>
										</FormControl>
										<TextField
											margin="dense"
											label="Nota di profilazione"
											type="text"
											multiline
											rows={4}
											value={notaprofilazione}
											onChange={onNotaprofilazioneChange}
											fullWidth
										/>
									</Box>
								</Box>
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "flex-end", padding: 2, border: "1px solid rgba(224, 224, 224, 1)" }}>
				<Button onClick={back} >Cancella</Button>
				<Button variant="contained" onClick={submit}>Salva</Button>
			</Box>
		</Box >
	)
}

export default Store;
