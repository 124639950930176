import { createAction } from '@reduxjs/toolkit';
import { IFondo } from '../../../../interfaces/lotto/attributes/models';
import { EMPTY, GET, IFondoState, LIST } from './types';

export const listAction = createAction<IFondoState>(LIST);

export const getAction = createAction<IFondo>(GET);

export const emptyAction = createAction(EMPTY);

