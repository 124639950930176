import { createAction } from '@reduxjs/toolkit';
import { IFila } from '../../../interfaces/place/models';
import { EMPTY, GET, IFilaState, LIST } from './types';

export const listAction = createAction<IFilaState>(LIST);

export const getAction = createAction<IFila>(GET);

export const emptyAction = createAction(EMPTY);

